import React, { Component } from "react";
import { apiAccountListAdjustment } from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class AdjustmentPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      alias: "",
      mql5Link: "",
      capital: 0,
      rateOption: [],
      rateSelected: null,
      loginInfo: "",
      total_match: 0,
      total_not_match: 0,
    };

    this.tableColumns = [
      {
        Header: "Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },

      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },

      {
        Header: "Total Deposit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "total_deposit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(
                parseFloat(e.original.total_deposit).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
      {
        Header: "Total Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "total_profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(
                parseFloat(e.original.total_profit).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
      {
        Header: "Total Withdrawal",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "total_withdrawal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(
                parseFloat(e.original.total_withdrawal).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
      {
        Header: "Balance",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "balance",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(parseFloat(e.original.balance).toFixed(2)).toLocaleString(
                "en",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
          </>
        ),
      },
      {
        Header: "Adjustment",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "adjustment",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(
                parseFloat(e.original.adjustment).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
      {
        Header: "Difference",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "difference",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              $
              {Number(
                parseFloat(e.original.difference).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
      {
        Header: "Remark",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <span
            style={{
              color: value === "Match" ? "green" : "red",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {value}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    this.doLoadData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadData = () => {
    this.props.doLoading();

    apiAccountListAdjustment()
      .then((res) => {
        this.props.doLoading();

        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = data.records;
            // let totalInitialDeposit = 0;
            for (var i = 0; i < temp.length; i++) {
              const balance = Number(
                parseFloat(temp[i].balance).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              });

              const intAdjustment = Number(
                parseFloat(temp[i].adjustment).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              });

              // totalInitialDeposit += temp[i].capital;

              temp[i].status =
                balance === intAdjustment ? "Match" : "Not Match";
            }

            const totalMatch = temp.filter((item) => item.status === "Match");
            const totalNotMatch = temp.filter(
              (item) => item.status === "Not Match"
            );
            // console.log("totalMatch: ", totalMatch.length);
            // console.log("totalNotMatch: ", totalNotMatch.length);
            // console.log("Total Initial Deposit:", totalInitialDeposit);

            this.setState({
              tableData: temp,
              total_match: totalMatch.length,
              total_not_match: totalNotMatch.length,
            });
          } else {
            this.setState({ tableData: [] });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    this.doLoadData();
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Adjustment
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div
                      className="contentDate"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ marginRight: 16 }}>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            display: "inline",
                            color: "green",
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Match {this.state.total_match}
                        </Typography>
                      </div>
                      <div style={{ marginRight: 16 }}>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            display: "inline",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          Not Match {this.state.total_not_match}
                        </Typography>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                            width: "100%",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
      </Box>
    );
  }
}
export default AdjustmentPage;
