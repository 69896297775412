import React, { Component } from "react";
import {
  apiJoinGroupDelete,
  apiJoinGroupList,
  apiManageApiAccount,
  apiJoinGroupInsert,
  apiGroupAccountList,
  apiUpdateAccountBanned,
  apiUpdateAccountSession,
  apiUpdateAccountFlood,
  apiUpdateAccountActive,
  apiTelegramAccountGroupList,
  apiTelegramAccountGroupInsert,
  apiAccountTelegram,
} from "../../Service/api";
import {
  ResolveUsername,
  DisconnectedWS,
  JoinChannel,
  LeaveChannel,
} from "../../TelegramApi/ClinetApiTelegram";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { shuffle } from "../../../global";
import { Input } from "reactstrap";
import moment from "moment";
import {
  AddBox,
  Close,
  GroupAdd,
  Cancel,
  Check,
  Edit,
  Delete,
  Refresh,
} from "@mui/icons-material";
// import LogoutIcon from "@mui/icons-material/Logout";
// import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class TelegramJoinGroupPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertDelete: false,
      itemDeleted: {},
      accountShow: [],
      selectedAccountId: null,
      group_title: "",
      group_name: "",
      group_id: 0,
      submitLoading: false,
      DataGroupAccountTelegram: [],
      selectGroupAccountTelegramId: null,
      telegram_group_id: 0,
      telegram_link_name: "",
      telegram_group_name: "",
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      available: [],
      checked_transfer: [],
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold" },
        accessor: "id",
        style: { textAlign: "center" },
        width: 100,
      },
      {
        Header: "Telegram Group",
        headerStyle: { fontWeight: "bold" },
        accessor: "telegram_group_name",
        style: { textAlign: "center" },
      },
      {
        Header: "Link/Username",
        headerStyle: { fontWeight: "bold" },
        accessor: "telegram_group_link",
        style: { textAlign: "center" },
      },
      {
        Header: "Total Linked Telegram Accounts",
        headerStyle: { fontWeight: "bold" },
        accessor: "total",
        style: { textAlign: "center" },
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold" },
        accessor: "",
        style: { textAlign: "center" },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Edit />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadTelegramJoin();
  }

  doLoadTelegramJoin = () => {
    this.props.doLoading();
    apiTelegramAccountGroupList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = this.state.tableData;
            temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].total = temp[i].info_linked.length;
            }
            this.setState({ tableData: temp });
          } else {
            this.setState({ tableData: [] });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getAccountTelegram = (dataGroup) => {
    console.log(dataGroup);
    apiAccountTelegram()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            console.log(data.records);

            var results = data.records.filter(
              ({ manage_id: id1 }) =>
                !dataGroup.info_linked.some(({ manage_id: id2 }) => id2 === id1)
            );

            console.log(results);

            this.setState({
              left: results,
              available: results,
            });
          }
        }
      })
      .catch((err) => {
        console.log();
      });
  };

  getDataGroup = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].info_linked.length;
            temp[i].status = temp[i].is_available === 0 ? "Hide" : "Show";
            temp[i].value = temp[i].group_account_id;
            temp[i].label = temp[i].group_account_name;
          }

          if (this.state.selectGroupAccountTelegramId !== null) {
            let updateData = temp.filter(
              (obj) =>
                obj.value === this.state.selectGroupAccountTelegramId.value
            );

            console.log("Mount Data", updateData[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (updateData[0].info_linked.length > 0) {
              let dataFilterFlood = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = updateData[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(updateData[0]);
              for (var idxx = 0; idxx < arrGroupAccount.length; idxx++) {
                arrGroupAccount[idxx].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: arrGroupAccount,
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: updateData[0],
                DataGroupAccountTelegram: updateData,
              });
              this.getAllJoinGroup(updateData[0].group_account_id);
            }
          } else {
            console.log("Mount Data", temp[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var dates = new Date();
            var DateTimeNows = moment(dates).format(format1);

            if (temp[0].info_linked.length > 0) {
              let dataFilterFlood = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNows
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNows
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(temp[0]);
              for (var idxs = 0; idxs < arrGroupAccount.length; idxs++) {
                arrGroupAccount[idxs].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: temp[0],
                DataGroupAccountTelegram: temp,
              });
              this.getAllJoinGroup(temp[0].group_account_id);
            }

            this.getAllJoinGroup(temp[0].group_account_id);
            this.setState({
              DataGroupAccountTelegram: temp,
              selectGroupAccountTelegramId: temp[0],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }

          this.setState({
            selectedAccountId: temp[0],
          });

          this.setState({ accountShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doInsertJoinGroup = (params) => {
    apiJoinGroupInsert(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doDeleteGroup = (item) => {
    this.props.doLoading();
    apiJoinGroupDelete(item.telegram_group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Group deleted successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doUpdateBanned = (manage_id) => {
    apiUpdateAccountBanned(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateSession = (manage_id) => {
    apiUpdateAccountSession(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateFlood = (manage_id, flood_time) => {
    let params = {
      manage_id: manage_id,
      flood_time: flood_time,
    };
    apiUpdateAccountFlood(params)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllJoinGroup = (group_account_id) => {
    this.props.doLoading();
    apiJoinGroupList(group_account_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = [];
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
          }
          this.setState({ tableData: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getResolveUsername = (getApi, dataAccount, username) => {
    console.log(getApi);
    let channel = username;
    this.setState({
      submitLoading: true,
    });
    this.props.doLoading();
    ResolveUsername(getApi, channel)
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        // this.props.doLoading();
        this.doDisconnectedTelegram(getApi);
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          this.setState({
            submitLoading: false,
          });
          this.setState({
            group_title: res.chats[0].title,
          });
          if (dataAccount.length > 0) {
            const promises = dataAccount.map(
              (obj_item, i) =>
                new Promise((resolve) =>
                  setTimeout(() => {
                    console.log(obj_item);
                    this.doJoinChannel(
                      obj_item,
                      res.chats[0].title,
                      res.chats[0].username
                    );
                    resolve();
                  }, 3000 * i)
                )
            );
            Promise.all(promises).then(
              () =>
                this.setState({
                  group_name: "",
                  submitLoading: false,
                  openSuccess: true,
                  openSuccessText: "Account successfully joined channel.",
                }),
              this.props.doLoading()
            );
          }
        } else {
          this.props.doLoading();
          this.setState({
            submitLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });
        this.handleCloseAddNew();
        this.doDisconnectedTelegram(getApi);
        console.log(err);
        console.log(err.toString());
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(getApi.manage_id);
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + getApi.alias === ""
                ? getApi.phonenumber
                : getApi.alias + "must be updated session",
          });
        } else {
          if (err.code === 401) {
            this.doUpdateBanned(getApi.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 406) {
            this.doUpdateSession(getApi.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + getApi.alias === ""
                  ? getApi.phonenumber
                  : getApi.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(getApi.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + getApi.alias !== ""
                  ? getApi.alias
                  : getApi.phonenumber,
            });
          } else {
            if (err.errorMessage === "CONNECTION_DEVICE_MODEL_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Device model empty.",
              });
            } else if (err.errorMessage === "CONNECTION_LAYER_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Layer invalid.",
              });
            } else if (err.errorMessage === "USERNAME_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided username is not valid.",
              });
            } else if (err.errorMessage === "USERNAME_NOT_OCCUPIED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided username is not occupied.",
              });
            }
          }
        }
      });
  };

  doDisconnectedTelegram = (getApi) => {
    DisconnectedWS(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLeaveGroup = (item) => {
    console.log(item);

    let stringLink = item.telegram_group_link;
    let substring = "https://t.me/";
    let link = stringLink.includes(substring);
    console.log(stringLink.includes(substring));
    var result_username = "";
    if (link === true) {
      result_username = stringLink.substr(13);
    } else {
      result_username = stringLink;
    }

    console.log(result_username);

    let dataAccount = item;
    if (dataAccount.info_linked.length > 0) {
      // console.log(dataAccount.info_linked);
      dataAccount.info_linked.forEach((obj) => {
        return this.doLeaveChannel(obj, result_username, item);
      });
    }
  };

  doLeaveChannel = (obj, username, item) => {
    this.setState({
      submitLoading: true,
    });
    LeaveChannel(obj, username)
      .then((res) => {
        this.doDisconnectedTelegram(obj);
        console.log(res);
        this.setState({
          submitLoading: false,
        });
        console.log(res);
        this.doDeleteGroup(item);
      })
      .catch((err) => {
        this.doDisconnectedTelegram(obj);
        this.setState({
          submitLoading: false,
        });
        console.log(err.code);
        console.log(err.errorMessage);
        console.log(err.toString());
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(obj.manage_id);
          console.log("keluar dari rotasi");
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + obj.alias === ""
                ? obj.phonenumber
                : obj.alias + "must be updated session",
          });
        } else {
          if (err.code === 403) {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "channel/supergroup not available.",
            });
          } else if (err.code === 400) {
            if (err.errorMessage === "CHANNEL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided channel is invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "MSG_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Invalid message ID provided.",
              });
            } else if (err.errorMessage === "USER_CREATOR") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "You can't leave this channel, because you're its creator.",
              });
            } else if (err.errorMessage === "USER_NOT_PARTICIPANT") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You're not a member of this supergroup/channel.",
              });
            }
          } else if (err.code === 401) {
            this.doUpdateBanned(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 406) {
            this.doUpdateSession(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + obj.alias === ""
                  ? obj.phonenumber
                  : obj.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(obj.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + obj.alias !== ""
                  ? obj.alias
                  : obj.phonenumber,
            });
          } else {
            if (err.code !== undefined) {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody: err.errorMessage,
              });
            }
          }
        }
      });
  };

  doJoinChannel = (obj, title, username) => {
    this.props.doLoading();
    let channel = username;
    JoinChannel(obj, channel)
      .then((res) => {
        this.props.doLoading();
        this.doDisconnectedTelegram(obj);
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          this.handleCloseAddNew();
          let params = {
            telegram_join_channel_id: 0,
            telegram_group_id: this.state.telegram_group_id,
            telegram_group_name: this.state.telegram_group_name,
            telegram_link_name: this.state.telegram_link_name,
            manage_id: obj.manage_id,
          };
          this.doInsertJoinGroup(params);
        } else {
          this.setState({
            openFailed: true,
            openFailedText: "Account failed to join channel.",
            submitLoading: false,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        this.handleCloseAddNew();
        this.doDisconnectedTelegram(obj);
        console.log(err.toString());
        console.log(err.code);
        console.log(err.errorMessage);
        this.setState({
          openFailed: true,
          openFailedText: "Account failed to join channel.",
          submitLoading: false,
        });
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(obj.manage_id);
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + obj.alias === ""
                ? obj.phonenumber
                : obj.alias + "must be updated session",
          });
          console.log("keluar dari rotasi");
        } else {
          if (err.code === 400) {
            if (err.errorMessage === "CHANNELS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You have joined too many channels/supergroups.",
              });
            } else if (err.errorMessage === "CHANNEL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided channel is invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "INVITE_HASH_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite hash is empty.",
              });
            } else if (err.errorMessage === "INVITE_HASH_EXPIRED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite link has expired.",
              });
            } else if (err.errorMessage === "INVITE_HASH_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite hash is invalid.",
              });
            } else if (err.errorMessage === "MSG_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Invalid message ID provided.",
              });
            } else if (err.errorMessage === "PEER_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided peer id is invalid.",
              });
            } else if (err.errorMessage === "USERS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The maximum number of users has been exceeded (to create a chat, for example).",
              });
            } else if (err.errorMessage === "USER_ALREADY_PARTICIPANT") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The user is already in the group.",
              });
            } else if (err.errorMessage === "USER_CHANNELS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "One of the users you tried to add is already in too many channels/supergroups.",
              });
            }
          } else if (err.code === 401) {
            this.doUpdateBanned(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(obj.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + obj.alias !== ""
                  ? obj.alias
                  : obj.phonenumber,
            });
          } else if (err.code === 406) {
            this.doUpdateSession(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + obj.alias === ""
                  ? obj.phonenumber
                  : obj.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: err.errorMessage,
            });
          }
        }
      });
  };

  doSubmitNewGroup = () => {
    let params = {
      telegram_group_id: this.state.telegram_group_id,
      telegram_group_name: this.state.telegram_group_name,
      telegram_link_name: this.state.telegram_link_name,
    };
    this.setState({
      submitLoading: true,
    });
    apiTelegramAccountGroupInsert(params)
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            telegram_group_name: "",
            telegram_link_name: "",
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });
        console.log(err);
      });
  };
  doSubmitEditGroup = () => {
    const { right } = this.state;
    if (right.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Account?",
        titleBody: "Select telegram account",
      });
    } else {
      let stringLink = this.state.telegram_link_name;
      let substring = "https://t.me/";
      let link = stringLink.includes(substring);
      console.log(stringLink.includes(substring));
      var result_username = "";
      if (link === true) {
        result_username = stringLink.substr(13);
      } else {
        result_username = stringLink;
      }

      console.log(result_username);

      let dataAccount = right;
      if (dataAccount.length > 0) {
        let getApi = shuffle(dataAccount);
        console.log(getApi);
        if (getApi.length > 0) {
          this.getResolveUsername(getApi[0], dataAccount, result_username);
        }
      }
    }
  };

  doSubmit = () => {
    this.setState({
      submitLoading: true,
    });
    let dataAccount = this.state.selectGroupAccountTelegramId;
    if (dataAccount.info_linked.length > 0) {
      console.log(dataAccount.info_linked);
      let getApi = shuffle(dataAccount.info_linked);
      console.log(getApi);
      if (getApi.length > 0) {
        this.getResolveUsername(getApi[0], dataAccount.info_linked);
      }
    } else {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group account telegram",
        titleBody: "Telegram account not available",
      });
      this.setState({
        submitLoading: false,
      });
    }
  };

  checkData = () => {
    const { telegram_group_name, telegram_link_name } = this.state;
    if (telegram_group_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group telegram name?",
        titleBody: "Please enter group telegram name",
      });
    } else if (telegram_link_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty link/username?",
        titleBody: "Please enter link/username account group telegram",
      });
    } else {
      if (this.state.setOpenAddNew === true) {
        this.doSubmitNewGroup();
      } else {
        this.doSubmitEditGroup();
      }
    }
  };

  handleChangeAccount = (selectGroupAccountTelegramId) => {
    // console.log(selectGroupAccountTelegramId);

    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    if (selectGroupAccountTelegramId.info_linked.length > 0) {
      let dataFilterFlood = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood >= DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

      let dataFloodToActive = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood < DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

      if (dataFloodToActive.length > 0) {
        dataFloodToActive.forEach((obj) => {
          console.log(obj);
          return this.doUpdateAccountActive(obj.manage_id);
        });
      }

      let dataUnFlood = selectGroupAccountTelegramId.info_linked.filter(
        (val) => !dataFilterFlood.includes(val)
      );
      console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

      let arrGroupAccount = [];
      arrGroupAccount.push(selectGroupAccountTelegramId);
      for (var i = 0; i < arrGroupAccount.length; i++) {
        arrGroupAccount[i].info_linked = dataUnFlood;
      }

      console.log("REAL ACCOUNT", arrGroupAccount[0]);

      this.setState({ selectGroupAccountTelegramId: arrGroupAccount[0] });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    } else {
      console.log("normal");
      this.setState({ selectGroupAccountTelegramId });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    }
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
    });
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Group?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove group {item.telegram_group_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={<Check />}
            onClick={() => this.doLeaveGroup(item)}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.getAccountTelegram(row);
    this.setState({
      setOpenEdit: true,
      telegram_group_id: row.telegram_group_id,
      telegram_group_name: row.telegram_group_name,
      telegram_link_name: row.telegram_group_link,
      right: row.info_linked,
    });
  };

  pressReset = () => {
    // console.log("cek data");
    // this.props.doLoading();
    this.setState({ openSuccess: false, openSuccessText: "", tableData: [] });
    this.doLoadTelegramJoin();
    // this.getAllJoinGroup();
    // this.getAllJoinGroup(this.state.selectGroupAccountTelegramId.value);
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      left: this.state.available,
      telegram_group_name: "",
      telegram_link_name: "",
    });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Join Account to Telegram Group"
                : this.state.setOpenEdit === true
                ? "Edit Join Account to Telegram Group"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Group Telegram
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="telegram_group_name"
                  id="telegram_group_name"
                  placeholder="Group Telegram Name "
                  value={this.state.telegram_group_name}
                  onChange={(event) =>
                    this.setState({ telegram_group_name: event.target.value })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Invite Link/Username Group
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invite_link"
                  id="invite_link"
                  placeholder="Exp: https://t.me/xxx"
                  value={this.state.telegram_link_name}
                  onChange={(event) =>
                    this.setState({ telegram_link_name: event.target.value })
                  }
                />
              </Grid>
              {this.state.setOpenEdit === true ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        color: "#000",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      Telegram Account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderTransferList()}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<GroupAdd />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                {this.state.setOpenEdit === true ? "Join" : "Save"}
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderTransferList = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{this.customList("Available", this.state.left)}</Grid>

        <Grid item>
          <div className="telegram-manage-api-desktop">
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </div>
          <div className="telegram-manage-api-mobile" style={{ marginTop: 15 }}>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &#8679;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &#8681;
              </Button>
            </Grid>
          </div>
        </Grid>

        <Grid item>{this.customList("Account List", this.state.right)}</Grid>
      </Grid>
    );
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    console.log("right:", Right);
    console.log("left:", Left);
    console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    console.log("right:", Right);
    console.log("left:", Left);
    console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== "" ? value.alias : value.phonenumber
          } -label`;

          return (
            <ListItem
              key={value.manage_id}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== "" ? value.alias : value.phonenumber
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Join Group Telegram
                </Typography>
                <br></br>
                <div className="contentDate">
                  <div style={{ marginRight: 16 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#008b02",
                      }}
                      startIcon={<AddBox />}
                      onClick={() => this.pressAddNew()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Add New
                      </Typography>
                    </Button>
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <br></br>
        {this.renderSuccess()}
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default TelegramJoinGroupPage;
