import React, { Component } from "react";
import ButtonUI from "@mui/material/Button";
import { ArrowBackIos, Cancel, Save, Close } from "@mui/icons-material";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  apiAccountDetail,
  apiRateOption,
  apiAccountUpdateData,
  apiVpsOption,
} from "../../Service/api";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_number: props.match.params.account_number,
      code_id: 0,
      server_name: "",
      leverage: "",
      balance: "",
      equity: "",
      margin_used: "",
      margin_percent: "",
      jml_open_from_buy: "",
      jml_open_from_sell: "",
      type_currency: null,
      platform: null,
      alias: "",
      rate_id: null,
      mql5_link: "",
      lots: "",
      last_timestamp: "",
      date_insert: "",
      capital: "",
      rateOption: [],
      typeOption: [
        { value: "Cents", label: "Cents" },
        { value: "Standart", label: "Standart" },
      ],
      platformOption: [
        { value: 4, label: "MT4" },
        { value: 5, label: "MT5" },
      ],
      openSuccess: false,
      openSuccessText: "",
      optionVps: [],
      vpsSelected: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    };
  }

  componentDidMount = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.props.doLoading();
    this.doLoadOptionVps();
    setTimeout(() => this.doLoadData(this.state.account_number), 1000);
  };

  componentWillUnmount() {}

  doLoadOption = (rate_id) => {
    apiRateOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const rateSelected = data.records.filter(
              (obj) => obj.value === rate_id
            );
            // console.log("rateSelected: ", rateSelected);
            this.setState({
              rateOption: data.records,
              rate_id: rateSelected.length > 0 ? rateSelected[0] : null,
            });
          } else {
            this.setState({
              rateOption: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadOptionVps = () => {
    apiVpsOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionVps: data.records,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadData = (account_number) => {
    apiAccountDetail(account_number)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "OK") {
          const currency = data.records.type_currency;
          const platform = data.records.platform;

          const currencySelected = this.state.typeOption.filter(
            (obj) => obj.value === currency
          );

          const platformSelected = this.state.platformOption.filter(
            (obj) => obj.value === platform
          );

          const a1 = this.state.optionVps;
          const a2 = data.records.vps;

          // console.log("a1: ", a1);
          // console.log("a2: ", a2);

          const resultVps = a1.filter(({ value }) =>
            a2.some((x) => x.vps_id === value)
          );

          // console.log(resultVps);

          this.setState({
            code_id: data.records.code_id,
            server_name: data.records.server_name,
            leverage: data.records.leverage,
            balance: data.records.balance,
            equity: data.records.equity,
            margin_used: data.records.margin_used,
            margin_percent: data.records.margin_percent,
            jml_open_from_buy: data.records.jml_open_from_buy,
            jml_open_from_sell: data.records.jml_open_from_sell,
            alias: data.records.alias,
            mql5_link: data.records.mql5_link,
            lots: data.records.lots,
            last_timestamp: data.records.last_timestamp,
            date_insert: data.records.date_insert,
            capital: data.records.capital,
            type_currency:
              currencySelected.length > 0 ? currencySelected[0] : null,
            platform: platformSelected.length > 0 ? platformSelected[0] : null,
            vpsSelected: resultVps,
          });
          this.doLoadOption(data.records.rate_id);
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  handleChangeRate = (rate) => {
    this.setState({
      rate_id: rate,
    });
  };

  handleChangeCurrency = (currency) => {
    this.setState({
      type_currency: currency,
    });
  };

  handleChangePlatform = (platform) => {
    this.setState({
      platform: platform,
    });
  };

  handleChangeVps = (vps) => {
    this.setState({
      vpsSelected: vps,
    });
  };

  handleAliasChange = (value) => {
    this.setState({ alias: value });
  };

  handleLotsChange = (value) => {
    const validValue = value.replace(/[^0-9.]/g, "");
    const parts = validValue.split(".");
    if (parts.length <= 2) {
      this.setState({ lots: validValue });
    }
  };

  checkData = () => {
    if (this.state.vpsSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty VPS?",
        titleBody: "Please set vps",
      });
    } else {
      let params = {
        code_id: this.state.code_id,
        account_number: this.state.account_number,
        alias: this.state.alias,
        rate_id: this.state.rate_id !== null ? this.state.rate_id.value : "",
        type_currency:
          this.state.type_currency !== null
            ? this.state.type_currency.value
            : "",
        platform: this.state.platform !== null ? this.state.platform.value : 4,
        lots: this.state.lots,
        vps: this.state.vpsSelected !== null ? this.state.vpsSelected : "",
      };
      // console.log("params: ", params);
      this.props.doLoading();
      apiAccountUpdateData(params)
        .then((res) => {
          this.props.doLoading();
          const data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data successfully updated",
            });
          }
        })
        .catch((err) => {
          this.props.doLoading();

          console.log(err);
        });
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/accounts");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(
        () => this.setState({ openSuccess: false, openSuccessText: "" }),
        2000
      );
      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <ButtonUI
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#000",
            }}
            startIcon={<ArrowBackIos />}
            onClick={() => this.props.history.push("/panel/accounts")}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 12,
                color: "#fff",
                textTransform: "capitalize",
                marginLeft: -10,
              }}
            >
              Back
            </Typography>
          </ButtonUI>{" "}
          <Typography
            component="span"
            variant="h2"
            style={{
              fontSize: 16,
              color: "#000",
              fontWeight: "bold",
              textTransform: "capitalize",
              float: "right",
            }}
          >
            Account Detail
          </Typography>
          <span className="dash">&nbsp;&nbsp;</span>
        </div>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Date Insert
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="date_insert"
                  id="date_insert"
                  value={this.state.date_insert}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Last Timestamp
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="last_timestamp"
                  id="last_timestamp"
                  value={this.state.last_timestamp}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Alias
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="alias"
                  id="alias"
                  value={this.state.alias}
                  onChange={(event) =>
                    this.handleAliasChange(event.target.value)
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Account Number
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="account_number"
                  id="account_number"
                  value={this.state.account_number}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Server Name
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="server_name"
                  id="server_name"
                  value={this.state.server_name}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Leverage
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="leverage"
                  id="leverage"
                  value={`1:${this.state.leverage}`}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Capital
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="capital"
                  id="capital"
                  value={`${
                    this.state.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(this.state.capital).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${this.state.type_currency === "Cents" ? "¢" : ""}`}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Balance
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="balance"
                  id="balance"
                  value={`${
                    this.state.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(this.state.balance).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${this.state.type_currency === "Cents" ? "¢" : ""}`}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Equity
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="equity"
                  id="equity"
                  value={`${
                    this.state.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(this.state.equity).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${this.state.type_currency === "Cents" ? "¢" : ""}`}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Margin Usage
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="margin_used"
                  id="margin_used"
                  value={Number(
                    parseFloat(this.state.margin_used).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Margin %
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="margin_percent"
                  id="margin_percent"
                  value={
                    Number(
                      parseFloat(this.state.margin_percent).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    }) + "%"
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Sum From Buy
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="jml_open_from_buy"
                  id="jml_open_from_buy"
                  value={this.state.jml_open_from_buy}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Sum From Sell
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="jml_open_from_sell"
                  id="jml_open_from_sell"
                  value={this.state.jml_open_from_sell}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Lots Size
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="lots"
                  id="lots"
                  value={this.state.lots}
                  onChange={(event) =>
                    this.handleLotsChange(event.target.value)
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Rate
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a rate"
                  value={this.state.rate_id}
                  onChange={this.handleChangeRate}
                  options={this.state.rateOption}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Type
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a type"
                  value={this.state.type_currency}
                  onChange={this.handleChangeCurrency}
                  options={this.state.typeOption}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Platform
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a platform"
                  value={this.state.platform}
                  onChange={this.handleChangePlatform}
                  options={this.state.platformOption}
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  VPS
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  value={this.state.vpsSelected}
                  onChange={this.handleChangeVps}
                  options={this.state.optionVps}
                />
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <ButtonUI
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/accounts")}
              // onClick={() => this.props.history.goBack()}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Cancel
              </Typography>
            </ButtonUI>{" "}
            &nbsp;&nbsp;
            <ButtonUI
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.checkData()}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Save
              </Typography>
            </ButtonUI>
          </div>
          <br></br>
          {this.renderSuccess()}
        </div>
        {this.renderValidationAlert()}
      </div>
    );
  }
}
export default AccountDetail;
