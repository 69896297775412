import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";

export async function DisconnectedWS(obj) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    const result = await client.disconnect();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function ConnectTelegram(obj) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
      // proxy: {
      //   ip: obj.proxy_ip,
      //   port: parseInt(obj.proxy_port),
      // },
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    const result = await client.connect();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function ResolveUsername(obj, channel) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    const result = await client.invoke(
      new Api.contacts.ResolveUsername({
        username: channel,
      })
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function JoinChannel(obj, channel) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    const result = await client.invoke(
      new Api.channels.JoinChannel({
        channel: channel,
      })
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function LeaveChannel(obj, item) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    const result = await client.invoke(
      new Api.channels.LeaveChannel({
        channel: item,
      })
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function GetParticipants(obj, newChannel, offset, limit, hash) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();

    let params = {
      channel: newChannel,
      filter: new Api.ChannelParticipantsRecent({}),
      offset: offset,
      limit: limit,
      hash: hash,
    };

    const result = await client.invoke(
      new Api.channels.GetParticipants(params)
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function InviteToChannel(obj, channel, users) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();

    let params = {
      channel: channel,
      users: users,
    };

    const result = await client.invoke(
      new Api.channels.InviteToChannel(params)
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function SendMessageMember(username, obj, newMessage, random) {
  try {
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();

    let params = {
      peer: username,
      message: newMessage,
      randomId: random,
      silent: false,
    };

    const result = await client.invoke(new Api.messages.SendMessage(params));
    return result;
  } catch (error) {
    throw error;
  }
}
