import React, { Component } from "react";
import {
  apiInvoiceAgentList,
  apiAgentList,
  apiInvoiceAgentEdit,
  apiInvoiceAgentCreated,
  apiInvoiceAgentGenerate,
  apiInvoiceAgentDelete,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import {
  Refresh,
  Check,
  Info,
  Delete,
  Close,
  Save,
  Cancel,
  AddBox,
  Receipt,
} from "@mui/icons-material";
import InvoicePDF from "../../Components/GeneratePdf/GeneratePdf";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import Select from "react-select";
import moment from "moment";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class InvoicePage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      submitLoading: false,
      agentData: [],
      invoiceData: [],
      agentOption: [],
      agentSelected: null,
      monthOptions: [],
      startMonthSelected: null,
      endMonthSelected: null,
      yearOptions: [],
      startYearSelected: null,
      endYearSelected: null,
      dataDetail: "",
      typeRateOption: [
        { value: "Floating", label: "Floating" },
        { value: "Fixed", label: "Fixed" },
      ],
      typeRateSelected: null,
      statusOption: [
        { value: "UNPAID", label: "UNPAID" },
        { value: "PAID", label: "PAID" },
      ],
      statusSelected: null,
      nominalRate: 0,
      proofPaymentPic: [],
      invoiceId: 0,
      setOpenDetail: false,
      dataDetailInvoice: "",
      loginInfo: "",
      openAlertDelete: false,
      itemDeleted: {},
      showLoadingDelete: false,
      severity: "success",
    };

    this.tableColumns = [
      {
        Header: "Invoice",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "invoice_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 250,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.invoice_number}
          </Typography>
        ),
      },

      {
        Header: "Period",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "periodeText",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.periodeText}
          </Typography>
        ),
      },
      {
        Header: "Agent",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "agent_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.agent_name}
          </Typography>
        ),
      },
      {
        Header: "Total Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "subtotal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color: "black",
                display: "inline-block",
                // fontWeight: "800",
              }}
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(e.original.sub_total_all_profit)}
            </Typography>
          </>
        ),
      },
      {
        Header: "Fee",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "fee",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color: "green",
                display: "inline-block",
              }}
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(e.original.fee_final_usd)}
            </Typography>
          </>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Info />}
              onClick={() => this.doRowDetail(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
            &nbsp;
            {this.state.loginInfo.logintype === 1 ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#ff0000",
                }}
                startIcon={<Delete />}
                onClick={() => this.doRowDeleteInfo(e.original)}
              >
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Remove
                </Typography>
              </Button>
            ) : null}
          </div>
        ),
      },
    ];
  }

  generateMonthOptions = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months.map((month, index) => ({ value: index + 1, label: month }));
  };

  generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear;

    const years = [];
    for (let year = currentYear; year <= endYear; year++) {
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    let params = {
      agentId: loginInfo.logintype === 1 ? "" : loginInfo.agent.id,
      startMonthPeriod: "",
      startYearPeriod: "",
      endMonthPeriod: "",
      endYearPeriod: "",
    };

    this.doLoadDataAgent();
    this.doLoadDataInvoice(params);

    this.setState({
      monthOptions: this.generateMonthOptions(),
      monthSelected: null,
      yearOptions: this.generateYearOptions(),
      yearSelected: null,
    });
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  parseAndFormatDate = (dateString) => {
    const isoDateString = new Date(dateString).toISOString();
    return moment(isoDateString).locale("en").format("MMMM YYYY");
  };

  doLoadDataInvoice = (params) => {
    this.props.doLoading();
    apiInvoiceAgentList(params)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "00") {
          if (data.records.length > 0) {
            const temp = data.records.map((record) => {
              const year = record.year;
              const month = record.periode;
              const dateString = `${year}-${month}-01`;
              console.log(dateString);

              record.periodeText = this.parseAndFormatDate(record.to_date);

              // console.log(record);

              return record;
            });

            this.setState({
              invoiceData: temp,
            });
            this.setState({
              invoiceData: temp,
            });
          } else {
            this.setState({
              invoiceData: [],
            });
          }
        } else {
          this.setState({
            invoiceData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doLoadDataAgent = () => {
    apiAgentList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const temp = data.records;

            // console.log("temp: ", temp);

            if (this.state.loginInfo.logintype === 1) {
              const agentOption = temp.map((agent) => ({
                value: agent.agent_id,
                label: agent.agent_name,
                shared_profit: agent.shared_profit,
              }));
              this.setState({
                agentData: temp,
                agentOption: agentOption,
              });
            } else if (this.state.loginInfo.logintype === 2) {
              const filterAgent = temp.filter(
                (obj) => obj.agent_id === this.state.loginInfo.agent.id
              );

              const agentOption = filterAgent.map((agent) => ({
                value: agent.agent_id,
                label: agent.agent_name,
                shared_profit: agent.shared_profit,
              }));

              this.setState({
                agentData: filterAgent,
                agentOption: agentOption,
              });
            }
          } else {
            this.setState({
              agentData: [],
            });
          }
        } else {
          this.setState({
            agentData: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  doRowEdit = (item) => {
    // console.log(item);
    const ifStatusNull = item.status === null ? "UNPAID" : item.status;
    const status = this.state.statusOption.filter(
      (item) => item.value === ifStatusNull
    );

    const ifTypeNull = item.type_rate === null ? "Fixed" : item.type_rate;
    const type = this.state.typeRateOption.filter(
      (obj) => obj.value === ifTypeNull
    );

    const ifNominalRateNull =
      item.nominal_rate === null
        ? ifTypeNull === "Fixed"
          ? 10000
          : item.nominal_rate
        : item.nominal_rate;

    const ifProofPaymentPic =
      item.proof_of_payment === ""
        ? []
        : ["https://3dvam.info/" + item.proof_of_payment];

    this.setState({
      setOpenEdit: true,
      dataDetail: item,
      invoiceId: item.invoice_id,
      statusSelected: status[0],
      typeRateSelected: type[0],
      nominalRate: ifNominalRateNull,
      proofPaymentPic: ifProofPaymentPic,
    });
  };

  doRowDetail = (item) => {
    // console.log(item);
    this.setState({
      setOpenDetail: true,
      dataDetailInvoice: item,
    });
  };

  handleCloseDetail = () => {
    this.setState({
      setOpenDetail: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      setOpenEdit: false,
      dataDetail: "",
      statusSelected: null,
      typeRateSelected: null,
      nominalRate: 0,
      invoiceId: 0,
    });
  };

  doShow = () => {
    const {
      agentSelected,
      startMonthSelected,
      startYearSelected,
      endMonthSelected,
      endYearSelected,
    } = this.state;

    if (
      startMonthSelected &&
      startYearSelected &&
      endMonthSelected &&
      endYearSelected
    ) {
      let params = {
        agentId: agentSelected ? agentSelected.value : "",
        startMonthPeriod: String(startMonthSelected.value).padStart(2, "0"),
        startYearPeriod: startYearSelected.value,
        endMonthPeriod: String(endMonthSelected.value).padStart(2, "0"),
        endYearPeriod: endYearSelected.value,
      };

      // console.log("params: ", params);

      this.doLoadDataInvoice(params);
    } else {
      this.setState({
        openValidation: true,
        titleValidation: "Alert",
        titleBody: "Please select start to end period",
      });
    }
  };

  doReset = () => {
    this.setState({
      startMonthSelected: null,
      startYearSelected: null,
      endMonthSelected: null,
      endYearSelected: null,
      agentSelected: null,
      openSuccess: false,
      severity: "success",
    });

    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    let params = {
      agentId:
        this.state.loginInfo.logintype === 1
          ? ""
          : this.state.loginInfo.agent.id,
      startMonthPeriod: "",
      startYearPeriod: "",
      endMonthPeriod: "",
      endYearPeriod: "",
    };

    this.doLoadDataInvoice(params);
  };

  doSubmit = () => {
    let params = {
      invoiceId: this.state.invoiceId,
      typeRate:
        this.state.typeRateSelected !== null
          ? this.state.typeRateSelected.value
          : null,
      nominalRate: this.state.nominalRate,
      status:
        this.state.statusSelected !== null
          ? this.state.statusSelected.value
          : null,
      proofPaymentPic: this.state.proofPaymentPic,
    };
    // console.log(params);

    if (this.state.nominalRate === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Alert",
        titleBody: "Please insert nominal rate",
      });
    } else {
      this.setState({
        submitLoading: true,
      });
      apiInvoiceAgentEdit(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          // console.log(res);
          const data = res.data;
          if (data.status === "00") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Edit data successfully",
            });
            this.handleCloseDialog();
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    }
  };

  generateInvoice = () => {
    this.props.doLoading();
    apiInvoiceAgentGenerate()
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "00") {
          console.log("success");
          this.setState({
            openSuccess: true,
            openSuccessText: "Generate invoice successfully",
            severity: "success",
          });
        } else if (data.status === "99") {
          this.setState({
            openSuccess: true,
            openSuccessText: data.message,
            severity: "warning",
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  checkDataInvoice = () => {
    const { agentSelected, startMonthSelected, startYearSelected } = this.state;

    if (agentSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Warning",
        titleBody: "Please select agent",
      });
    } else if (startMonthSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Warning",
        titleBody: "Please select period month",
      });
    } else if (startYearSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Warning",
        titleBody: "Please select period year",
      });
    } else {
      let params = {
        agent_id: agentSelected ? agentSelected.value : "",
        agent_name: agentSelected ? agentSelected.label : "",
        shared_profit: agentSelected ? agentSelected.shared_profit : "",
        month: startMonthSelected ? startMonthSelected.value : 0,
        year: startYearSelected ? startYearSelected.value : 0,
      };

      console.log(params);
      this.setState({
        submitLoading: true,
      });
      apiInvoiceAgentCreated(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          const data = res.data;
          if (data.status === "00") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Invoice created successfully",
            });
            this.handleCloseAddNew();
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    }
  };

  handleChangeAgent = (agentSelected) => {
    this.setState({
      agentSelected,
    });
  };

  handleChangeStartMonth = (startMonthSelected) => {
    this.setState({
      startMonthSelected,
    });
  };

  handleChangeEndMonth = (endMonthSelected) => {
    this.setState({
      endMonthSelected,
    });
  };

  handleChangeStartYear = (startYearSelected) => {
    this.setState({
      startYearSelected,
    });
  };

  handleChangeEndYear = (endYearSelected) => {
    this.setState({
      endYearSelected,
    });
  };

  handleChangeStatus = (statusSelected) => {
    this.setState({
      statusSelected,
    });
  };

  handleChangeTypeRate = (typeRateSelected) => {
    this.setState({
      typeRateSelected,
    });
  };

  handleNominalRateChange = (e) => {
    e.persist();

    const value = e.target.value;

    window.requestAnimationFrame(() => {
      this.setState({ nominalRate: value });
    });
  };

  onUploadImage = (result) => {
    this.setState({ proofPaymentPic: result });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
    });
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Invoice?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove invoice {item.invoice_number} period{" "}
              {item.periodeText}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doRemoveInvoice(item.invoice_number)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRemoveInvoice = (invoice_number) => {
    this.setState({ showLoadingDelete: true });
    apiInvoiceAgentDelete(invoice_number)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        let data = res.data;
        if (data.status === "00") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Invoice removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        console.log(err);
      });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      agentSelected: null,
      startMonthSelected: null,
      startYearSelected: null,
    });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenAddNew}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Create Invoice
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={2} sm={3} xs={3} sx={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Agent
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} sm={9} xs={9} sx={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select an Agent"
                  value={this.state.agentSelected}
                  onChange={this.handleChangeAgent}
                  options={this.state.agentOption}
                  // isClearable
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={2} md={2} sm={3} xs={3} sx={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Period Month
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} sm={9} xs={9} sx={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Month"
                  value={this.state.startMonthSelected}
                  onChange={this.handleChangeStartMonth}
                  options={this.state.monthOptions}
                  isClearable
                />
              </Grid>

              <Grid item lg={2} md={2} sm={3} xs={3} sx={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Period Year
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} sm={9} xs={9} sx={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Year"
                  value={this.state.startYearSelected}
                  onChange={this.handleChangeStartYear}
                  options={this.state.yearOptions}
                  isClearable
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkDataInvoice()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDialogEdit = () => {
    const year = this.state.dataDetail.year;
    const month = this.state.dataDetail.periode;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenEdit}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Edit Invoice
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseDialog()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Invoice Number
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_number"
                  id="invoice_number"
                  value={this.state.dataDetail.invoice_number}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Created Invoice
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_created"
                  id="invoice_created"
                  value={new Date(
                    this.state.dataDetail.invoice_created
                  ).toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Period
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_created"
                  id="invoice_created"
                  value={new Date(`${year}-${month}-01`).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      year: "numeric",
                    }
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Agent
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_number"
                  id="invoice_number"
                  value={this.state.dataDetail.agent_name}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Sub Total
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="invoice_number"
                    id="invoice_number"
                    value={Number(
                      parseFloat(this.state.dataDetail.subtotal).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </InputGroup>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Fee {`(${this.state.dataDetail.shared_profit}%)`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="invoice_number"
                    id="invoice_number"
                    value={Number(
                      parseFloat(this.state.dataDetail.fee).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </InputGroup>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Type Rate
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Type Rate"
                  value={this.state.typeRateSelected}
                  onChange={this.handleChangeTypeRate}
                  options={this.state.typeRateOption}
                  isClearable
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Nominal Rate
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="nominalRate"
                    id="nominalRate"
                    value={this.state.nominalRate}
                    onChange={this.handleNominalRateChange}
                  />
                </InputGroup>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Status Payment
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Status"
                  value={this.state.statusSelected}
                  onChange={this.handleChangeStatus}
                  options={this.state.statusOption}
                  isClearable
                />
              </Grid>

              {this.state.statusSelected !== null ? (
                this.state.statusSelected.value === "PAID" ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Proof of Payment
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <PictureUploader
                        onUpload={this.onUploadImage}
                        picList={this.state.proofPaymentPic}
                        picLimit={1}
                      />
                    </Grid>
                  </>
                ) : null
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseDialog()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.doSubmit()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDetailInvoice = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenDetail}
        fullWidth={true}
        maxWidth="lg"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Detail Invoice
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseDetail()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <InvoicePDF data={this.state.dataDetailInvoice} />
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.doReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity={
                this.state.severity === "success" ? "success" : "warning"
              }
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Invoice Agent
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      {this.state.loginInfo.logintype === 1 ? (
                        <>
                          <div style={{ marginRight: 16 }}>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                backgroundColor: "#ff5722",
                              }}
                              startIcon={<Receipt />}
                              onClick={() => this.generateInvoice()}
                            >
                              <Typography
                                variant="button"
                                style={{
                                  color: "#fff",
                                  textTransform: "capitalize",
                                }}
                              >
                                Generate Invoice
                              </Typography>
                            </Button>
                          </div>
                          <div style={{ marginRight: 16 }}>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                backgroundColor: "#008b02",
                              }}
                              startIcon={<AddBox />}
                              onClick={() => this.doAddNew()}
                            >
                              <Typography
                                variant="button"
                                style={{
                                  color: "#fff",
                                  textTransform: "capitalize",
                                }}
                              >
                                Create Invoice
                              </Typography>
                            </Button>
                          </div>
                        </>
                      ) : null}

                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.doReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.invoiceData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderValidationAlert()}
        {this.renderDialogAddNew()}
        {this.renderDialogEdit()}
        {this.renderDetailInvoice()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default InvoicePage;
