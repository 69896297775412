import React, { Component } from "react";
import {
  apiVpsList,
  apiVpsDelete,
  apiVpsDeleteAll,
  apiVpsSetPort,
  apiMasterSlaveFixVpsMonitor,
  apiActionReset,
  apiActionRestart,
  apiActionClearVps,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import dayjs from "dayjs";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import {
  Delete,
  Close,
  Cancel,
  Check,
  DeleteOutline,
  Assignment,
  CrisisAlert,
  Save,
  RestartAlt,
  ResetTv,
} from "@mui/icons-material";
import { Input } from "reactstrap";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

class VpsPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      code_vps: "",
      vps_ip: "",
      vps_port: "",
      isOk: false,
      loginInfo: "",
    };
    this.interval = null;

    this.tableColumns = [
      {
        Header: "Last Timestamp",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_timestamp",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 150,
        Cell: (e) => (
          <Typography component="span" variant="body2">
            {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        ),
      },

      {
        Header: "VPS IP",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_ip",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vps_ip}
          </Typography>
        ),
      },
      {
        Header: "Port",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_port",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vps_port}
          </Typography>
        ),
      },
      {
        Header: "VPS Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vps_name}
          </Typography>
        ),
      },
      {
        Header: "Status",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
              color:
                e.original.vps_status === "Online" ||
                e.original.vps_status === "ON"
                  ? "green"
                  : e.original.vps_status === "Busy"
                  ? "darkorange"
                  : "red",
              fontWeight: "600",
            }}
          >
            {e.original.vps_status}
          </Typography>
        ),
      },
      {
        Header: "Memory Usage",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "memory_usage",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.memory_usage}%
          </Typography>
        ),
      },
      {
        Header: "CPU Usage",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "cpu_usage",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.cpu_usage}%
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 500,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "green",
              }}
              startIcon={<CrisisAlert />}
              onClick={() => this.doRowPopUp(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Set Port
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#194d33",
              }}
              startIcon={<RestartAlt />}
              onClick={() => this.doRestart(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Restart
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff6900",
              }}
              startIcon={<ResetTv />}
              onClick={() => this.doReset(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Reset
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadData();

    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadData = () => {
    if (this.state.tableData.length === 0) {
      this.props.doLoading();
    }
    apiVpsList()
      .then((res) => {
        if (this.state.tableData.length === 0) {
          this.props.doLoading();
        }
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = this.state.tableData;
            temp = data.records;
            // console.log("temp: ", temp);

            function isRecentUpdate(lastTimestamp) {
              let now = new Date();
              let lastUpdate = new Date(lastTimestamp);
              let difference = now - lastUpdate;
              let differenceInMinutes = difference / (1000 * 60);
              return differenceInMinutes < 5;
            }

            temp.forEach((item) => {
              if (!isRecentUpdate(item.last_timestamp)) {
                item.vps_status =
                  item.flag_auto === 1 ? item.vps_status : "Offline";
              } else {
                item.vps_status =
                  item.flag_auto === 1 ? item.vps_status : "Online";
              }
            });

            // console.log(temp);

            this.setState({ tableData: temp });
            timer = setTimeout(() => this.doLoadData(), 30000);
          }
        }
      })
      .catch((err) => {
        if (this.state.tableData.length === 0) {
          this.props.doLoading();
        }
        console.log(err);
      });
  };

  doDeleteVps = (item) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiVpsDelete(item.code_vps)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });

          apiActionClearVps({ vps: item.vps })
            .then((res) => {
              console.info(res);
            })
            .catch((err) => {
              console.err(err);
            });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  checkData = () => {
    const { group_account_name } = this.state;

    if (group_account_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Group account name empty?",
        titleBody: "Please enter group account name",
      });
    } else {
      if (this.state.setOpenEdit === true) {
        this.doSubmitUpdate();
      } else {
        this.doSubmit();
      }
    }
  };

  doRowDeleteInfo = (item) => {
    console.log("item: ", item);
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  doRestart = (data) => {
    const item = data;
    // console.log("item: ", item);

    let paramAutoRestart = {
      vps_id: item.vps_id,
      vps: item.vps,
      action: "restart",
      client_id: this.state.loginInfo.phoneno,
    };

    this.props.doLoading();
    apiActionRestart(paramAutoRestart)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "success") {
          this.setState({
            openSuccess: true,
            openSuccessText: `Successfully restart ${item.vps}.`,
          });
        } else {
          this.setState({
            openSuccess: true,
            openSuccessText: data.message,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });
  };

  doReset = (data) => {
    const item = data;
    // console.log("item: ", item);

    let paramAutoReset = {
      vps_id: item.vps_id,
      vps: item.vps,
      action: "reset",
      client_id: this.state.loginInfo.phoneno,
    };

    this.props.doLoading();
    apiActionReset(paramAutoReset)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;
        if (data.status === "success") {
          this.setState({
            openSuccess: true,
            openSuccessText: `Successfully reset ${item.vps}.`,
          });
        } else {
          this.setState({
            openSuccess: true,
            openSuccessText: data.message,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });
  };

  pressDeleteAll = () => {
    // console.log(this.state.selectRowData);
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    this.setState({ showLoadingDeleteAll: true });
    apiVpsDeleteAll(params)
      .then((res) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete VPS?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete VPS?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.LoadingInvite === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove VPS?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove {item.vps_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteVps(item)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.props.history.push("/panel/vps-detail/" + row.code_vps);
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
    this.doLoadData();
  };

  pressSync = () => {};

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      left: this.state.available,
    });
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  doRowPopUp = (item) => {
    console.log(item);
    this.setState({
      setOpenAddNew: true,
      code_vps: item.code_vps,
      vps_ip: item.vps_ip,
      vps_port: item.vps_port,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      code_vps: "",
      vps_ip: "",
      vps_port: "",
    });
  };

  checkData = () => {
    if (this.state.vps_port === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty port?",
        titleBody: "Please enter port",
      });
    } else {
      this.doSubmit();
    }
  };

  doSubmit = () => {
    let params = {
      code_vps: this.state.code_vps,
      vps_port: this.state.vps_port,
    };

    apiVpsSetPort(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Port successfully updated",
          });
          this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doFix = (item) => {
    console.log(item);
    let param = {
      vps: item.vps_ip,
      vps_name: item.vps_name,
      accountMaster: item.account_number,
    };

    console.log("param: ", param);

    this.props.doLoading();
    apiMasterSlaveFixVpsMonitor(param)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === 0) {
          this.setState({
            openSuccess: true,
            openSuccessText: data.message,
            isOk: false,
          });
        } else {
          this.setState({
            isOk: true,
            openSuccess: true,
            openSuccessText: "Successfully fixed.",
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Set Port"
                : this.state.setOpenEdit === true
                ? "Set Port"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  VPS IP
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="vps_ip"
                  id="vps_ip"
                  value={this.state.vps_ip}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  VPS Port
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="vps_port"
                  id="vps_port"
                  value={this.state.vps_port}
                  onChange={(event) =>
                    this.setState({ vps_port: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      VPS
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      {this.state.selectRowData.length > 0 ? (
                        <div style={{ marginRight: 0, marginLeft: 16 }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<DeleteOutline />}
                            onClick={() =>
                              this.setState({ openAlertDeleteAll: true })
                            }
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Delete
                            </Typography>
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderValidationAlert()}

        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
        {this.renderDialogAddNew()}
      </Box>
    );
  }
}
export default VpsPage;
