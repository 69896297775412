import React, { Component } from "react";
import {
  apiAddManageApi,
  apiJoinGroupList,
  apiJoinGroupInsert,
  apiGroupAccountList,
  apiGroupTelegramAccount,
  apiGroupAccountInput,
} from "../../Service/api";
import {
  // Api,
  TelegramClient,
} from "telegram";
import { StringSession } from "telegram/sessions";
import { uuidRandomId } from "../../../global";
import SelectMultiColumn from "../../Components/SelectMultiColumn/SelectMultiColumn";
import Select from "react-select";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  AppBar,
  Toolbar,
  Stack,
  Alert,
} from "@mui/material";
import { Input } from "reactstrap";
import { Close, AddBox, Save, Cancel } from "@mui/icons-material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class TelegramManageApiPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.codeInput = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      phonenumber: "",
      api_id: "",
      api_hash: "",
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      password: null,
      code: "",
      StringSession: "",
      setOpenCode: false,
      phoneCodeHash: "",
      openFormCode: false,
      GroupJoinShow: [],
      openGroupTelegramAccount: false,
      groupTelegramAccount: [],
      selectGroupTelegramAccountId: null,
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      showLoadingSubmit: false,
      showLoadingSubmitGroup: false,
      parameterAccount: {},
    };
  }

  componentDidMount() {
    this.getAccountTelegramGroup();
  }

  getAccountTelegramGroup = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = [];
            temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].value = temp[i].group_account_id;
              temp[i].label = temp[i].group_account_name;
            }
            this.setState({ groupTelegramAccount: temp });
          }
        }
      })
      .catch((err) => {
        console.log();
      });
  };

  getAllJoinGroup = (group_account_id) => {
    apiJoinGroupList(group_account_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = [];
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_name;
            temp[i].label = temp[i].group_title;
          }
          this.setState({ GroupJoinShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doInsertJoinGroup = (params) => {
    apiJoinGroupInsert(params)
      .then((res) => {
        // let data = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  codeCallback() {
    return new Promise((resolve, reject) => {
      let code = prompt("Please enter the code you received:", "");
      if (code !== null) {
        resolve(code);
      } else {
        reject(Error("Rejected"));
      }
    });
  }

  async GetAuthentication() {
    console.log("Loading Authentication");
    const session = new StringSession("");
    const client = new TelegramClient(
      session,
      parseInt(this.state.api_id),
      this.state.api_hash,
      {
        connectionRetries: 5,
      }
    );

    this.setState({
      showLoadingSubmit: true,
    });

    this.props.doLoading();

    await client
      .start({
        phoneNumber: async () => await this.state.phonenumber,
        phoneCode: async () => await this.codeCallback(),
      })
      .then((res) => {
        console.log("client: ", client);
        console.log("res: ", res);
        this.props.doLoading();
        var string_session = client.session.save();
        console.log(client.session.save());
        this.setState({
          StringSession: client.session.save(),
        });
        console.log(
          "%c your string session is " + client.session.save(),
          "color:#B54128"
        );
        if (
          string_session !== null ||
          string_session !== "" ||
          string_session !== undefined
        ) {
          this.setState({
            showLoadingSubmit: false,
          });

          this.doSubmit(string_session);
        } else {
          this.props.doLoading();
          this.setState({
            showLoadingSubmit: false,
          });
        }
      })
      .catch((e) => {
        this.props.doLoading();
        console.log(e.toString());
        this.setState({
          openValidation: true,
          showLoadingSubmit: false,
          titleValidation: "Error Authentication?",
          titleBody: e.toString(),
        });
      });
  }

  doJoinGroupTelegram = (parameterAccount) => {
    let dataGroupAccount = this.state.GroupJoinShow;
    if (dataGroupAccount.length > 0) {
      const promises = dataGroupAccount.map(
        (obj, i) =>
          new Promise((resolve) =>
            setTimeout(() => {
              return this.JoinChannel(parameterAccount, obj);
              // resolve();
            }, 1000 * dataGroupAccount.length - 1000 * i)
          )
      );
      Promise.all(promises).then(() => console.log("Selesai"));
    }
  };

  async JoinChannel(parameterAccount, obj) {
    console.log(parameterAccount);
    console.log(obj);
    const apiId = parseInt(parameterAccount.api_id);
    const apiHash = parameterAccount.api_hash;
    const session = new StringSession(parameterAccount.string_session);

    console.log(apiId);

    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });

    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
  }

  doSubmit = (string_session) => {
    let params = {
      manage_id: uuidRandomId(),
      phonenumber: this.state.phonenumber,
      api_id: this.state.api_id,
      api_hash: this.state.api_hash,
      string_session: string_session,
    };
    this.setState({
      parameterAccount: params,
    });
    this.props.doLoading();
    apiAddManageApi(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            phonenumber: "",
            api_id: "",
            api_hash: "",
            openSuccess: true,
            openSuccessText: "Data saved successfully",
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmitDataNewGroupAccount = () => {
    let params = {
      group_account_id: this.state.group_account_id,
      group_account_name: this.state.group_account_name,
      is_available: this.state.is_available,
    };
    this.setState({
      showLoadingSubmitGroup: true,
    });
    apiGroupAccountInput(params)
      .then((res) => {
        this.setState({
          showLoadingSubmitGroup: false,
        });
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            group_account_name: "",
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseAddNew();
          this.getAccountTelegramGroup();
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmitGroup: false,
        });
      });
  };

  checkData = () => {
    const { phonenumber, api_hash, api_id } = this.state;

    if (phonenumber === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty phone number?",
        titleBody: "Please enter your phone number",
      });
    } else if (api_id === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty API ID ?",
        titleBody: "Please enter your API ID.",
      });
    } else if (api_hash === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty API HASH ?",
        titleBody: "Please enter your API HASH.",
      });
    } else {
      this.setState({
        showLoadingSubmit: true,
      });
      this.GetAuthentication();
    }
  };

  checkCodeInput = () => {
    let newCode = this.codeInput.current.value;
    if (newCode === undefined || newCode === "" || newCode === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Code?",
        titleBody: "Please enter your code.",
      });
    } else {
      // const codePhone = Promise.resolve(newCode);
    }
    console.log(newCode);
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 3000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderCodeInput = () => {
    if (this.state.openFormCode === true) {
      return (
        <>
          <Grid item xs={2}>
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                // fontSize: 16,
                float: "left",
                marginTop: 6,
                color: "#000",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Code
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Input
              autoComplete="off"
              type="number"
              name="code"
              id="code"
              placeholder="Enter code"
              innerRef={this.codeInput}
            />
          </Grid>
        </>
      );
    }
  };

  doAddGroupAccount = () => {
    let params = {
      phonenumber: this.state.phonenumber,
      selectGroupTelegramAccountId:
        this.state.selectGroupTelegramAccountId.value,
    };
    this.props.doLoading();
    apiGroupTelegramAccount(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.handleCloseGroupAccountTelegram();
          this.setState({
            openSuccess: true,
            openSuccessText: "Data saved successfully",
          });

          this.doJoinGroupTelegram(this.state.parameterAccount);
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  checkDataNewGroupAccount = () => {
    const { group_account_name } = this.state;

    if (group_account_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Group account name empty?",
        titleBody: "Please enter group account name",
      });
    } else {
      this.doSubmitDataNewGroupAccount();
    }
  };

  handleChangeOptionGroup = (selectGroupTelegramAccountId) => {
    this.setState({ selectGroupTelegramAccountId });
    this.getAllJoinGroup(selectGroupTelegramAccountId.value);
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      openGroupTelegramAccount: true,
    });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Group Account"
                : this.state.setOpenEdit === true
                ? "Edit Group Account"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Group Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="groupname"
                  id="groupname"
                  placeholder="Enter group account name"
                  value={this.state.group_account_name}
                  onChange={(event) =>
                    this.setState({ group_account_name: event.target.value })
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  is Available
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <SelectMultiColumn
                  width={"100%"}
                  value={this.state.is_available}
                  valueColumn={"id"}
                  showColumn={"text"}
                  columns={["text"]}
                  data={this.state.availableShow}
                  onChange={this.changeAvailable}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingSubmitGroup}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.showLoadingSubmitGroup}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={
              this.state.showLoadingSubmitGroup === true ? null : <Save />
            }
            onClick={() => this.checkDataNewGroupAccount()}
          >
            {this.state.showLoadingSubmitGroup === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseGroupAccountTelegram = () => {
    this.setState({
      openGroupTelegramAccount: false,
      phonenumber: "",
      api_id: "",
      api_hash: "",
      selectGroupTelegramAccountId: null,
    });
  };

  renderDialogGroupTelegramAccount = () => {
    return (
      <Dialog
        // onClose={this.handleCloseGroupAccountTelegram}
        aria-labelledby="customized-dialog-title"
        open={this.state.openGroupTelegramAccount}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Telegram Account
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseGroupAccountTelegram()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers style={{ height: "300px" }}>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  You can put telegram accounts into sub-categories of groups.
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  to Group
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select for group telegram account"
                  value={this.state.selectGroupTelegramAccountId}
                  onChange={this.handleChangeOptionGroup}
                  options={this.state.groupTelegramAccount}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#4caf50",
                  }}
                  startIcon={<AddBox />}
                  onClick={() =>
                    this.setState({
                      setOpenAddNew: true,
                      openGroupTelegramAccount: false,
                    })
                  }
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Add
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseGroupAccountTelegram()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={this.state.showLoadingSubmit === true ? null : <Save />}
            onClick={() => this.doAddGroupAccount()}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Submit
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Telegram Manage Api
                </Typography>
                <br></br>
                <div className="contentDateLeft">
                  <div style={{ marginLeft: 0 }}>
                    <a
                      style={{ display: "table-cell", fontWeight: "bold" }}
                      href="https://my.telegram.org/auth"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Obtain API ID
                    </a>
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <Box
            sx={{ flexGrow: 1 }}
            style={{ marginBottom: 60, marginTop: 20, padding: 20 }}
          >
            <div className="telegram-manage-api-desktop">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder="Example : +1222333444555"
                    value={this.state.phonenumber}
                    onChange={(event) =>
                      this.setState({ phonenumber: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    API ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_id"
                    id="api_id"
                    placeholder="Enter API ID"
                    value={this.state.api_id}
                    onChange={(event) =>
                      this.setState({ api_id: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    API HASH
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_hash"
                    id="api_hash"
                    placeholder="Enter API HASH"
                    value={this.state.api_hash}
                    onChange={(event) =>
                      this.setState({ api_hash: event.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="telegram-manage-api-mobile">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder="Example : +1222333444555"
                    value={this.state.phonenumber}
                    onChange={(event) =>
                      this.setState({ phonenumber: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    API ID
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_id"
                    id="api_id"
                    placeholder="Enter API ID"
                    value={this.state.api_id}
                    onChange={(event) =>
                      this.setState({ api_id: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    API HASH
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_hash"
                    id="api_hash"
                    placeholder="Enter API HASH"
                    value={this.state.api_hash}
                    onChange={(event) =>
                      this.setState({ api_hash: event.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <div className="form-button-container">
            <Button
              disabled={this.state.showLoadingSubmit}
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={
                this.state.showLoadingSubmit === true ? null : <Save />
              }
              onClick={() => this.checkData()}
            >
              {this.state.showLoadingSubmit === true ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Submit
                </Typography>
              )}
            </Button>
          </div>
        </div>
        {this.renderDialogGroupTelegramAccount()}
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderSuccess()}
      </Box>
    );
  }
}
export default TelegramManageApiPage;
