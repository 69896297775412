import React, { Component } from "react";
import {
  apiAccountOption,
  apiAgentDelete,
  apiAgentInput,
  apiAgentUpdate,
  apiAgentList,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Refresh,
  Assignment,
  Delete,
  Close,
  Save,
  Cancel,
  AddBox,
  Check,
} from "@mui/icons-material";
import { uuidRandomId } from "../../../global.js";
import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class AgentPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      alias: "",
      capital: 0,
      dataAccount: [],
      optionAccountNumber: [],
      optionAccountNumberAvailable: [],
      selectedAccountId: null,
      selectedAccountMultiple: null,
      submitLoading: false,
      agent_id: 0,
      agent_name: "",
      shared_profit: 0,
      dataTableAccountUnderAgent: null,
    };

    this.tableColumns = [
      {
        Header: "Agent",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "agent_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.agent_name}
          </Typography>
        ),
      },
      {
        Header: "Shared Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "shared_profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.shared_profit}
          </Typography>
        ),
      },
      {
        Header: "Total Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "totalAccount",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.totalAccount}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadAccountOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadAccountOption = () => {
    apiAccountOption()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionAccountNumberAvailable: data.records,
              dataAccount: data.records,
            });
          } else {
            this.setState({
              optionAccountNumberAvailable: [],
            });
          }
          this.doLoadData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadData = () => {
    this.props.doLoading();
    apiAgentList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].totalAccount = temp[i].infoAccount.length;
            }

            const dataAccountSelected = [];
            for (var idx = 0; idx < temp.length; idx++) {
              for (var idx2 = 0; idx2 < temp[idx].infoAccount.length; idx2++) {
                dataAccountSelected.push({
                  accountNumber: temp[idx].infoAccount[idx2].account_number,
                });
              }
            }

            // console.log("dataAccount: ", dataAccountSelected);

            const filterDataAccount = this.state.dataAccount.filter(
              ({ value: id1 }) =>
                !dataAccountSelected.some(
                  ({ accountNumber: id2 }) => id2 === id1
                )
            );

            // console.log("filterDataAccount: ", filterDataAccount);

            this.setState({
              tableData: temp,
              optionAccountNumberAvailable: filterDataAccount,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      agent_id:
        this.state.setOpenAddNew === true
          ? uuidRandomId()
          : this.state.agent_id,
      agent_name: this.state.agent_name,
      shared_profit: this.state.shared_profit,
      selectedAccountMultiple:
        this.state.dataTableAccountUnderAgent !== null
          ? this.state.dataTableAccountUnderAgent
          : "",
    };

    this.setState({
      submitLoading: true,
    });

    if (this.state.setOpenAddNew === true) {
      apiAgentInput(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText:
                this.state.setOpenEdit === true
                  ? "Edit data successfully updated"
                  : "Data saved successfully",
            });
            this.handleCloseAddNew();
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    } else {
      apiAgentUpdate(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText:
                this.state.setOpenEdit === true
                  ? "Edit data successfully updated"
                  : "Data saved successfully",
            });
            this.handleCloseAddNew();
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    }
  };

  doDeleteItem = (agent_id) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiAgentDelete(agent_id)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  doRowEdit = (row) => {
    // const getSelected = this.state.dataAccount.filter(({ value: id1 }) =>
    //   row.infoAccount.some(({ account_number: id2 }) => id2 === id1)
    // );

    // console.log("Data: ", row);

    const dataAccountInfo = row.infoAccount;

    const newArrAccountInfo = dataAccountInfo.map((item) => ({
      value: item.account_number,
      alias: item.alias,
    }));

    this.setState({
      setOpenEdit: true,
      agent_id: row.agent_id,
      agent_name: row.agent_name,
      shared_profit: row.shared_profit,
      selectedAccountMultiple: null,
      dataTableAccountUnderAgent: newArrAccountInfo,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.doLoadAccountOption();
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      agent_id: 0,
      agent_name: "",
      shared_profit: 0,
      selectedAccountMultiple: null,
      dataTableAccountUnderAgent: [],
    });
  };

  handleChangeAccountMultiple = (value) => {
    // console.log("value: ", value);

    const selectedAccount =
      this.state.dataTableAccountUnderAgent === null
        ? []
        : this.state.dataTableAccountUnderAgent;

    // console.log("selectedAccount: ", selectedAccount);

    const newArrValue = value.map((item) => ({
      value: item.value,
      alias: item.alias,
    }));

    // console.log("newArrValue: ", newArrValue);

    const updatedAccounts =
      value !== null ? newArrValue.concat(selectedAccount) : null;

    const updatedOptions = this.state.optionAccountNumberAvailable.filter(
      (option) => !value.includes(option)
    );

    // console.log("updatedAccounts: ", updatedAccounts);

    // if (value !== null) {
    //   var filterDataAccount = this.state.dataAccount.filter(
    //     ({ value: id1 }) => !value.some(({ value: id2 }) => id2 === id1)
    //   );
    // } else {
    //   var filterDataAccount = this.state.dataAccount;
    // }

    this.setState({
      dataTableAccountUnderAgent: updatedAccounts,
      selectedAccountMultiple: null,
      optionAccountNumberAvailable: updatedOptions,
    });
  };

  doRemoveList = (item) => {
    const updatedOptions = this.state.dataTableAccountUnderAgent.filter(
      (option) => item.value !== option.value
    );

    const getToAvailable = this.state.dataTableAccountUnderAgent.filter(
      (option) => item.value === option.value
    );

    // console.log("updatedOptionsRemove: ", updatedOptions);
    // console.log("getToAvailable: ", getToAvailable);

    const newArrValueAvailable = getToAvailable.map((item) => ({
      value: item.value,
      alias: item.alias,
      label: `${item.value}-(${item.alias})`,
    }));

    const combinationAvailable = newArrValueAvailable.concat(
      this.state.optionAccountNumberAvailable
    );

    // console.log("combinationAvailable: ", combinationAvailable);

    this.setState({
      dataTableAccountUnderAgent: updatedOptions,
      optionAccountNumberAvailable: combinationAvailable,
    });
  };

  checkData = () => {
    if (this.state.agent_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Agent?",
        titleBody: "Enter agent",
      });
    } else if (this.state.shared_profit === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Shared Profit?",
        titleBody: "Enter shared profit",
      });
    } else {
      this.doSubmit();
    }
  };

  renderDialogAddNew = () => {
    const dataTableAccountUnderAgent =
      this.state.dataTableAccountUnderAgent === null
        ? []
        : this.state.dataTableAccountUnderAgent;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullScreen
        // fullWidth={true}
        // maxWidth="lg"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Agent"
                : this.state.setOpenEdit === true
                ? "Edit Agent"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Agent
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="agent_name"
                  id="agent_name"
                  value={this.state.agent_name}
                  onChange={(event) =>
                    this.setState({ agent_name: event.target.value })
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Shared Profit (%)
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="number"
                  name="shared_profit"
                  id="shared_profit"
                  value={this.state.shared_profit}
                  onChange={(event) =>
                    this.setState({ shared_profit: event.target.value })
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Account
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  isMulti
                  classNamePrefix="select"
                  className="basic-multi-select"
                  placeholder="Select a account"
                  value={this.state.selectedAccountMultiple}
                  onChange={this.handleChangeAccountMultiple}
                  options={this.state.optionAccountNumberAvailable}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={12}>
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={dataTableAccountUnderAgent}
                  columns={[
                    {
                      Header: "Account",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "value",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Alias",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "alias",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Action",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      width: 300,
                      Cell: (e) => (
                        <div>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<Delete />}
                            onClick={() => this.doRemoveList(e.original)}
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Remove
                            </Typography>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={500}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  showPagination={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Agent?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove agent {item.agent_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteItem(item.agent_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Agent
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Create Agent
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default AgentPage;
