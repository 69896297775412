import React, { Component } from "react";
import { apiAccountTradingList } from "../../Service/api";
import SelectMultiColumn from "../../Components/SelectMultiColumn/SelectMultiColumn";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  Checkbox,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Refresh,
  Edit,
  Delete,
  Close,
  Save,
  Cancel,
  Check,
  DeleteOutline,
  Sync,
} from "@mui/icons-material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MyTradingAccount extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
    };

    this.tableColumns = [];
  }

  componentDidMount() {
    this.doLoadData();
  }

  doLoadData = () => {
    this.props.doLoading();
    apiAccountTradingList(this.state.filter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === 200) {
          if (data.records.length > 0) {
            var temp = this.state.tableData;
            temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
            }
            this.setState({ tableData: temp });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  checkData = () => {
    const { group_account_name } = this.state;

    if (group_account_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Group account name empty?",
        titleBody: "Please enter group account name",
      });
    } else {
      if (this.state.setOpenEdit === true) {
        this.doSubmitUpdate();
      } else {
        this.doSubmit();
      }
    }
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  pressDeleteAll = () => {
    // console.log(this.state.selectRowData);
    // let params = {
    //   item_delete: this.state.selectRowData,
    // };
    this.props.doLoading();
    this.setState({ showLoadingDeleteAll: true });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Telegram Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete the account?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.LoadingInvite === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Group Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to delete group account name{" "}
              {item.group_account_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteGroup(item.group_account_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      group_account_id: row.group_account_id,
      group_account_name: row.group_account_name,
      is_available: row.is_available,
      right: row.info_linked,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
    this.doLoadData();
  };

  pressSync = () => {};

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      left: this.state.available,
    });
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== "" ? value.alias : value.phonenumber
          } -label`;

          return (
            <ListItem
              key={value.manage_id}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== "" ? value.alias : value.phonenumber
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  renderTransferList = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{this.customList("Available", this.state.left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{this.customList("Account List", this.state.right)}</Grid>
      </Grid>
    );
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Group Account"
                : this.state.setOpenEdit === true
                ? "Edit Group Account"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Group Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="groupname"
                  id="groupname"
                  placeholder="Enter group account name"
                  value={this.state.group_account_name}
                  onChange={(event) =>
                    this.setState({ group_account_name: event.target.value })
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  is Available
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <SelectMultiColumn
                  width={"100%"}
                  value={this.state.is_available}
                  valueColumn={"id"}
                  showColumn={"text"}
                  columns={["text"]}
                  data={this.state.availableShow}
                  onChange={this.changeAvailable}
                />
              </Grid>
              <br></br>
              {this.state.setOpenEdit === true ? (
                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Telegram Account
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                {this.state.setOpenEdit === true ? (
                  this.renderTransferList()
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Save
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  My Tranding Account
                </Typography>
                <br></br>
                <div className="contentDate">
                  <div style={{ marginRight: 16 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#008b02",
                      }}
                      startIcon={<Sync />}
                      onClick={() => this.pressSync()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Sync
                      </Typography>
                    </Button>
                  </div>
                  {this.state.selectRowData.length > 0 ? (
                    <div style={{ marginRight: 0, marginLeft: 16 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff0000",
                        }}
                        startIcon={<DeleteOutline />}
                        onClick={() =>
                          this.setState({ openAlertDeleteAll: true })
                        }
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Delete
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    onChange={(e) => this.toggleSelectAll(e)}
                    checked={this.state.selectAll}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                Cell: (row) => (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={(e) => this.handleCheck(e, row.index)}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                headerStyle: { fontWeight: "bold" },
                sortable: false,
                filterable: true,
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "No",
                headerStyle: { fontWeight: "bold" },
                accessor: "id",
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "Power",
                headerStyle: { fontWeight: "bold" },
                accessor: "power",
                style: { textAlign: "center" },
                width: 200,
              },

              {
                Header: "Status",
                headerStyle: { fontWeight: "bold" },
                accessor: "status",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Account Number",
                headerStyle: { fontWeight: "bold" },
                accessor: "account_number",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Name",
                headerStyle: { fontWeight: "bold" },
                accessor: "name",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Server",
                headerStyle: { fontWeight: "bold" },
                accessor: "server",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Comment",
                headerStyle: { fontWeight: "bold" },
                accessor: "comment",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Note",
                headerStyle: { fontWeight: "bold" },
                accessor: "note",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Balance",
                headerStyle: { fontWeight: "bold" },
                accessor: "balance",
                style: { textAlign: "center" },
                width: 200,
              },
              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: { textAlign: "center" },
                width: 200,
                Cell: (e) => (
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#3f51b5",
                      }}
                      startIcon={<Edit />}
                      onClick={() => this.doRowEdit(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Edit
                      </Typography>
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#ff0000",
                      }}
                      startIcon={<Delete />}
                      onClick={() => this.doRowDeleteInfo(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Delete
                      </Typography>
                    </Button>
                  </div>
                ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={500}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderSuccess()}
        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default MyTradingAccount;
