import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import axios from "axios";
import { serverUrl } from "../config";
import "./Default.style.css";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
import MenuProfile from "./Components/MenuProfile/MenuProfile";
import Loading from "./Components/Loading/Loading";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ListAdmin from "./Pages/ListAdmin/ListAdmin";
import EditAdmin from "./Pages/EditAdmin/EditAdmin";
import InputAdmin from "./Pages/InputAdmin/InputAdmin";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import Profile from "./Pages/Profile/Profile";
import MyTradingAccount from "./Pages/MyTradingAccount/Index";
import AccountPage from "./Pages/Account/Index";
import VpsPage from "./Pages/VPS/Index";
import VpsDetailPage from "./Pages/VPS/Detail";
import TelegramManageApiPage from "./Pages/TelegramManageApi/Index";
import TelegramGroupAccountsPage from "./Pages/TelegramGroupAccounts";
import TelegramAccountPage from "./Pages/TelegramAccounts";
import TelegramGroupVpsPage from "./Pages/TelegramGroupVPS";
import TelegramJoinGroupPage from "./Pages/TelegramJoinGroup";
import DailyBalance from "./Pages/DailyBalance";
import MasterSlaveSettingPage from "./Pages/MasterSlaveSetting";
import MasterSlaveListPage from "./Pages/MasterSlaveList";
import BuySellPage from "./Pages/BuySell";
import DailyProfit from "./Pages/DailyProfit";
import AgentPage from "./Pages/Agent";
import DailyWithdrawal from "./Pages/DailyWithdrawal";
import InvoicePage from "./Pages/Invoice";
import RatePage from "./Pages/Rate";
import ArchivedPage from "./Pages/Archived";
import AdjustmentPage from "./Pages/Adjustment";
import PerformancePage from "./Pages/Performance";
import TradeChart from "./Pages/TradeChart";
import DailyDeposit from "./Pages/DailyDeposit";
import InvoiceMemberPage from "./Pages/InvoiceMember";
import SubmissionFormListPage from "./Pages/Submission/list";
import SubmissionFormLogPage from "./Pages/Submission/log";
import WhiteLabelClientPage from "./Pages/whiteLabelClient";
import AccountDetail from "./Pages/Account/detail";
import AutomationLogPage from "./Pages/Automation/log";
import OrderTradingPage from "./Pages/OrderTrading";
import MigrationVpsPage from "./Pages/MigrationVps";
import IssuesPage from "./Pages/Automation/issues";

class Default extends Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.state = {
      loginInfo: {},
      menuProfileShow: false,
      community: {
        communityid: 0,
        communityname: "- SELECT COMMUNITY -",
        isdefault: 1,
      },
      communityList: [],
      loadingShow: false,
      redirect: false,
    };
    this.flag = {
      menuProfileJustOpen: false,
    };
    this.history = createBrowserHistory();

    this.history.listen((location, action) => {
      //event when change page
    });

    this.menuRef = null;
  }

  componentDidMount = () => {
    let loginInfo;

    if (
      this.props.location.state === undefined ||
      this.props.location.state === null
    ) {
      loginInfo = localStorage.getItem("loginInfo");

      if (
        loginInfo === undefined ||
        loginInfo === null ||
        loginInfo === "" ||
        loginInfo === "undefined" ||
        loginInfo === "null"
      ) {
        this.props.history.replace("/");
      } else {
        loginInfo = JSON.parse(loginInfo);
        this.setState({ loginInfo: loginInfo });
      }
    } else {
      loginInfo = this.props.location.state.loginInfo;
      this.setState({ loginInfo: this.props.location.state.loginInfo });
    }
    //set event click in document
    document.addEventListener("click", this.eventDocumentClick);
    if (loginInfo !== undefined) this.getCommunityAvailable(loginInfo.phoneno);
  };

  getCommunityAvailable = (phoneno) => {
    axios({
      method: "post",
      url: serverUrl + "cp_community_list_available_admin.php",
      data: { phoneno: phoneno },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let data = response.data;
        let defaultCommunity = {
          communityid: 0,
          communityname: "- SELECT COMMUNITY -",
          isdefault: 1,
        };
        let getDefault = false;

        data.records.forEach((comm) => {
          if (comm.isdefault === 1) {
            defaultCommunity = comm;
            getDefault = true;
          }
        });

        if (!getDefault && data.records.length > 0) {
          defaultCommunity = data.records[0];
        }
        this.setState({
          communityList: data.records,
          community: defaultCommunity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.eventDocumentClick);
  }

  eventDocumentClick = (event) => {
    //check if menu profile is open then close
    if (this.state.menuProfileShow) {
      if (this.flag.menuProfileJustOpen) {
        this.flag.menuProfileJustOpen = false;
      } else {
        this.setState({ menuProfileShow: !this.state.menuProfileShow });
      }
    }
  };

  onLogout = () => {
    localStorage.removeItem("loginInfo");
    this.props.history.replace("/");
  };
  toogleMenuProfile = () => {
    this.flag.menuProfileJustOpen = true;
    this.setState({ menuProfileShow: !this.state.menuProfileShow });
  };

  changeCommunity = (community) => {
    //console.log('change community : '+community);
    this.menuRef.updateParent(0);
    this.setState({ community: community });

    let { match } = this.props;
    //this.history.replace('/smartcp/panel/dashboard');
    this.history.replace(`${match.path}/dashboard`);
  };

  updateCommunity = () => {
    this.getCommunityAvailable();
  };

  doLoading = () => {
    this.setState({ loadingShow: !this.state.loadingShow });
  };

  render() {
    // let { match } = this.props;
    // console.log(`${match.path}/dashboard`);
    return (
      <Router history={this.history}>
        <div>
          <Loading isShow={this.state.loadingShow} />
          <Header
            loginInfo={this.state.loginInfo}
            community={this.state.community}
            communityList={this.state.communityList}
            toogleMenuProfile={this.toogleMenuProfile}
            changeCommunity={this.changeCommunity}
          ></Header>
          <div className="default-screen-desktop">
            <Menu
              ref={(ref) => (this.menuRef = ref)}
              loginInfo={this.state.loginInfo}
              community={this.state.community}
            ></Menu>
          </div>
          <MenuProfile
            isOpen={this.state.menuProfileShow}
            onLogout={this.onLogout}
          ></MenuProfile>
          <div className="content-container">
            <Switch>
              <Route
                path={`/panel/dashboard`}
                render={(props) => (
                  <Dashboard
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/changepassword`}
                render={(props) => (
                  <ChangePassword
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/profile`}
                render={(props) => (
                  <Profile
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/user`}
                render={(props) => (
                  <ListAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/inputadmin`}
                render={(props) => (
                  <InputAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/editadmin/:phoneno`}
                render={(props) => (
                  <EditAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/trading-account`}
                render={(props) => (
                  <MyTradingAccount
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/accounts`}
                render={(props) => (
                  <AccountPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/account-detail/:account_number`}
                render={(props) => (
                  <AccountDetail
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/vps`}
                render={(props) => (
                  <VpsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/telegram-manage-api`}
                render={(props) => (
                  <TelegramManageApiPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/telegram-accounts`}
                render={(props) => (
                  <TelegramAccountPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/telegram-group-accounts`}
                render={(props) => (
                  <TelegramGroupAccountsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/telegram-group-vps`}
                render={(props) => (
                  <TelegramGroupVpsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/telegram-join-group`}
                render={(props) => (
                  <TelegramJoinGroupPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/vps-detail/:code_vps`}
                render={(props) => (
                  <VpsDetailPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/daily-balance`}
                render={(props) => (
                  <DailyBalance
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/daily-profit`}
                render={(props) => (
                  <DailyProfit
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/daily-withdrawal`}
                render={(props) => (
                  <DailyWithdrawal
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/daily-deposit`}
                render={(props) => (
                  <DailyDeposit
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/master-slave-setting`}
                render={(props) => (
                  <MasterSlaveSettingPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/master-slave-list`}
                render={(props) => (
                  <MasterSlaveListPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/buy-sell`}
                render={(props) => (
                  <BuySellPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/agent`}
                render={(props) => (
                  <AgentPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/invoice/agent`}
                render={(props) => (
                  <InvoicePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/invoice/member`}
                render={(props) => (
                  <InvoiceMemberPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/rate`}
                render={(props) => (
                  <RatePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/archived`}
                render={(props) => (
                  <ArchivedPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/adjustment`}
                render={(props) => (
                  <AdjustmentPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/performance`}
                render={(props) => (
                  <PerformancePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/trade-chart`}
                render={(props) => (
                  <TradeChart
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/job-list`}
                render={(props) => (
                  <SubmissionFormListPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/job-log`}
                render={(props) => (
                  <SubmissionFormLogPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/clients`}
                render={(props) => (
                  <WhiteLabelClientPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/automation-log`}
                render={(props) => (
                  <AutomationLogPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/trade-activity`}
                render={(props) => (
                  <OrderTradingPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/vps-migration`}
                render={(props) => (
                  <MigrationVpsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/automation-issues`}
                render={(props) => (
                  <IssuesPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default Default;
