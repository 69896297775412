import React, { Component } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loading.style.css";

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShow: props.isShow,
    };
    this.elRef = null;
  }

  componentWillReceiveProps(props) {
    this.setState({ isShow: props.isShow });
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    if (this.state.isShow) {
      return (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.isShow}
        >
          <CircularProgress color="inherit" size="80px" />
        </Backdrop>
      );
    } else {
      return <div style={{ display: "none" }}></div>;
    }
  }
}
export default Loading;
