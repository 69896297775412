import React, { Component } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Alert,
  IconButton,
  Divider,
} from "@mui/material";
import { ExpandMore, Refresh, Handyman, Close } from "@mui/icons-material";
import {
  apiSlaveList,
  apiMasterSlaveFixVpsMonitor,
  apiMasterSlaveFixVpsMonitorAuto,
} from "../../Service/api";
import ReactTable from "react-table";
import "react-table/react-table.css";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class MasterSlaveListPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      expanded: null,
      isOk: false,
      openSuccess: false,
      openSuccessText: "",
      loginInfo: "",
    };

    this.tableColumns = [];
  }

  componentDidMount() {
    this.doLoadData();
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiSlaveList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.records.length > 0) {
          let dataArr = data.records;
          dataArr.forEach((record) => {
            record.infoSlave.forEach((info) => {
              info.status =
                info.buy === record.buy && info.sell === record.sell;
            });
          });
          dataArr.sort((a, b) => b.infoSlave.length - a.infoSlave.length);
          this.setState({
            tableData: dataArr,
          });
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    this.setState({
      tableData: [],
    });
    this.doLoadData();
  };

  handleChange = (expanded) => {
    this.setState({
      expanded: expanded === this.state.expanded ? null : expanded,
    });
  };

  // renderList = () => {
  //   if (this.state.tableData.length > 0) {
  //     return (
  //       <div>
  //         {this.state.tableData.map((obj, i) => {
  //           return (
  //             <Accordion
  //               key={obj.account_number}
  //               onChange={() => this.handleChange(obj.account_number)}
  //               expanded={this.state.expanded === obj.account_number}
  //             >
  //               <AccordionSummary
  //                 expandIcon={<ExpandMore />}
  //                 aria-controls="panel1bh-content"
  //                 id="panel1bh-header"
  //               >
  //                 <Typography
  //                   sx={{ width: "33%", flexShrink: 0 }}
  //                   style={{ fontWeight: "bold" }}
  //                 >
  //                   {obj.account_number} ({obj.alias})
  //                 </Typography>
  //                 <Typography sx={{ color: "text.secondary" }}>
  //                   Buy: {obj.buy} Sell: {obj.sell}
  //                 </Typography>
  //               </AccordionSummary>
  //               <AccordionDetails>{this.renderTable(obj)}</AccordionDetails>
  //             </Accordion>
  //           );
  //         })}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <Accordion defaultExpanded={false}>
  //         <AccordionSummary
  //           aria-controls="panel1bh-content"
  //           id="panel1bh-header"
  //         >
  //           <Typography
  //             sx={{ width: "33%", flexShrink: 0 }}
  //             style={{ fontWeight: "bold" }}
  //           >
  //             No data available
  //           </Typography>
  //         </AccordionSummary>
  //       </Accordion>
  //     );
  //   }
  // };

  renderList = () => {
    const { tableData } = this.state;
    const groupedData = tableData.reduce((acc, obj) => {
      if (!acc[obj.market]) {
        acc[obj.market] = [];
      }
      acc[obj.market].push(obj);
      return acc;
    }, {});

    if (Object.keys(groupedData).length > 0) {
      return (
        <div>
          {Object.entries(groupedData).map(([market, accounts]) => (
            <div key={market} style={{ marginBottom: "20px" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                {market}
              </Typography>
              <Divider style={{ marginBottom: "20px" }} />
              {accounts.map((obj, i) => (
                <Accordion
                  key={obj.account_number}
                  onChange={() => this.handleChange(obj.account_number)}
                  expanded={this.state.expanded === obj.account_number}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel-${obj.account_number}-content`}
                    id={`panel-${obj.account_number}-header`}
                  >
                    <Typography
                      sx={{ width: "33%", flexShrink: 0 }}
                      style={{ fontWeight: "bold" }}
                    >
                      {obj.account_number} ({obj.alias})
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Buy: {obj.buy} Sell: {obj.sell}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>{this.renderTable(obj)}</AccordionDetails>
                </Accordion>
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: "33%", flexShrink: 0 }}
              style={{ fontWeight: "bold" }}
            >
              No data available
            </Typography>
          </AccordionSummary>
        </Accordion>
      );
    }
  };

  doFix = (item, data) => {
    if (!item.status) {
      if (item.flag_auto === 1) {
        let paramFixAuto = {
          vps_id: item.vps_id,
          vps: item.vps,
          action: "fix",
          client_id: this.state.loginInfo.phoneno,
        };

        this.props.doLoading();
        apiMasterSlaveFixVpsMonitorAuto(paramFixAuto)
          .then((res) => {
            this.props.doLoading();
            const data = res.data;
            if (data.status === "success") {
              this.setState({
                isOk: true,
                openSuccess: true,
                openSuccessText: `Successfully fixed VPS ${item.vps}.`,
              });
            } else {
              this.setState({
                openSuccess: true,
                openSuccessText: data.message,
                isOk: false,
              });
            }
          })
          .catch((err) => {
            this.props.doLoading();
            console.error(err);
          });
      } else {
        let param = {
          vps: item.vps_ip,
          vps_name: item.vps,
          accountMaster: data.account_number,
        };
        this.props.doLoading();
        apiMasterSlaveFixVpsMonitor(param)
          .then((res) => {
            this.props.doLoading();
            let data = res.data;
            if (data.status === 0) {
              this.setState({
                openSuccess: true,
                openSuccessText: data.message,
                isOk: false,
              });
            } else {
              this.setState({
                isOk: true,
                openSuccess: true,
                openSuccessText: `Successfully fixed VPS ${item.vps}.`,
              });
            }
          })
          .catch((err) => {
            this.props.doLoading();
            console.log(err);
          });
      }
    }
  };

  // doFixAll = (data) => {
  //   const filterFixData = data.filter((item) => item.status === false);
  //   let uniqueVPS = {};
  //   let closeVpsData = [];

  //   filterFixData.forEach((item) => {
  //     let vpsValue = item["vps"];
  //     if (!uniqueVPS[vpsValue]) {
  //       uniqueVPS[vpsValue] = true;
  //       closeVpsData.push(item);
  //     }
  //   });

  //   closeVpsData.forEach((item) => {
  //     if (item.flag_auto === 1) {
  //       let paramFixAuto = {
  //         vps_id: item.vps_id,
  //         vps: item.vps,
  //         action: "fix",
  //         client_id: this.state.loginInfo.phoneno,
  //       };

  //       apiMasterSlaveFixVpsMonitorAuto(paramFixAuto)
  //         .then((res) => {
  //           const data = res.data;
  //           if (data.status === "success") {
  //             this.setState({
  //               isOk: true,
  //               openSuccess: true,
  //               openSuccessText: `Successfully fixed VPS ${item.vps}.`,
  //             });
  //           } else {
  //             this.setState({
  //               openSuccess: true,
  //               openSuccessText: data.message,
  //               isOk: false,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     } else {
  //       let param = {
  //         vps: item.vps_ip,
  //         vps_name: item.vps,
  //         accountMaster: item.account_number,
  //       };

  //       apiMasterSlaveFixVpsMonitor(param)
  //         .then((res) => {
  //           let data = res.data;
  //           if (data.status === 0) {
  //             this.setState({
  //               openSuccess: true,
  //               openSuccessText: data.message,
  //               isOk: false,
  //             });
  //           } else {
  //             this.setState({
  //               isOk: true,
  //               openSuccess: true,
  //               openSuccessText: `Successfully fixed VPS ${item.vps}`,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   });
  // };

  doFixAll = async (data) => {
    const filterFixData = data.filter((item) => item.status === false);
    const uniqueVPS = new Set();
    const closeVpsData = filterFixData.filter((item) => {
      if (!uniqueVPS.has(item.vps)) {
        uniqueVPS.add(item.vps);
        return true;
      }
      return false;
    });

    try {
      this.props.doLoading();

      const promises = closeVpsData.map(async (item) => {
        try {
          if (item.flag_auto === 1) {
            let paramFixAuto = {
              vps_id: item.vps_id,
              vps: item.vps,
              action: "fix",
              client_id: this.state.loginInfo.phoneno,
            };

            const { data } = await apiMasterSlaveFixVpsMonitorAuto(
              paramFixAuto
            );

            if (data.status === "success") {
              this.setState({
                isOk: true,
                openSuccess: true,
                openSuccessText: `Successfully fixed VPS ${item.vps}.`,
              });
            } else {
              this.setState({
                openSuccess: true,
                openSuccessText: data.message,
                isOk: false,
              });
            }
          } else {
            const param = {
              vps: item.vps_ip,
              vps_name: item.vps,
              accountMaster: item.account_number,
            };
            const { data } = await apiMasterSlaveFixVpsMonitor(param);

            if (data.status === 0) {
              this.setState({
                openSuccess: true,
                openSuccessText: data.message,
                isOk: false,
              });
            } else {
              this.setState({
                isOk: true,
                openSuccess: true,
                openSuccessText: `Successfully fixed VPS ${item.vps}`,
              });
            }
          }
        } catch (err) {
          console.error(`Error fixing vps ${item.vps}:`, err);
        }
      });

      await Promise.all(promises);
      this.props.doLoading();
    } catch (err) {
      console.error("Error processing items:", err);
      this.props.doLoading();
    }
  };

  renderTable = (data) => {
    const dataList = data.infoSlave;

    return (
      <>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#1273DE",
            }}
            startIcon={<Handyman />}
            onClick={() => this.doFixAll(dataList)}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 14,
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Fix All
            </Typography>
          </Button>
        </Grid>
        <br></br>
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={dataList}
          columns={[
            {
              Header: "VPS",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "vps",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: "inline-block",
                      color: e.original.status === false ? "red" : "black",
                      cursor: "pointer",
                    }}
                    onClick={() => this.doFix(e.original, data)}
                  >
                    {e.original.vps}
                  </Typography>
                </>
              ),
            },
            {
              Header: "Account",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "account_number",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: "inline-block",
                      color: e.original.status === false ? "red" : "black",
                    }}
                  >
                    {e.original.account_number}
                  </Typography>
                </>
              ),
            },

            {
              Header: "Buy",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "buy",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: "inline-block",
                      color: e.original.status === false ? "red" : "black",
                    }}
                  >
                    {parseInt(e.original.buy)}
                  </Typography>
                </>
              ),
            },
            {
              Header: "Sell",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "sell",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: "inline-block",
                      color: e.original.status === false ? "red" : "black",
                    }}
                  >
                    {parseInt(e.original.sell)}
                  </Typography>
                </>
              ),
            },
          ]}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={100}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      </>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      isOk: false,
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity={this.state.isOk ? "success" : "error"}
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Master Slave List
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        {this.renderList()}
      </Box>
    );
  }
}
export default MasterSlaveListPage;
