import React, { Component } from "react";
import {
  apiOptionAuto,
  // apiOptionManual,
  apiVpsOptionSelected,
  apiVpsDetailId,
  apiMigrationInsert,
  apiMigrationList,
  apiActionMaster,
  apiActionClearLogin,
  apiActionClearMT,
  apiMasterSlaveFixVpsMonitorAuto,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import { Input } from "reactstrap";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import {
  Refresh,
  Assignment,
  Close,
  Save,
  Cancel,
  AddBox,
  Check,
} from "@mui/icons-material";
import Select from "react-select";
import dayjs from "dayjs";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class MigrationVpsPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      optionVpsAuto: [],
      optionVpsManual: [],
      dataTableAccountVpsManual: [],
      detailInfo: null,
      loginInfo: "",
      fromType: 0,
      isSelectAllChecked: false,
      isLoading: false,
    };

    this.setVpsManual = React.createRef();
    this.setVpsAuto = React.createRef();

    this.tableColumns = [
      {
        Header: "Time",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "created_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {dayjs(e.original.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        ),
      },
      {
        Header: "From",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "from_vps_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.from_vps_name}
          </Typography>
        ),
      },
      {
        Header: "To",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "to_vps_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.to_vps_name}
          </Typography>
        ),
      },
      {
        Header: "Total Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "infoAccount",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.total}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadData();
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiMigrationList()
      .then((res) => {
        this.props.doLoading();

        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].total = temp[i].infoAccount.length;
            }
            this.setState({
              tableData: temp,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });

    apiOptionAuto()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionVpsAuto: data.records,
            });
          } else {
            this.setState({
              optionVpsAuto: [],
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiVpsOptionSelected()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionVpsManual: data.records,
            });
          } else {
            this.setState({
              optionVpsManual: [],
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  doVpsDetailId = (vps_id) => {
    this.props.doLoading();
    apiVpsDetailId(vps_id)
      .then((res) => {
        this.props.doLoading();
        const data = res.data;

        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              dataTableAccountVpsManual: data.records.map((row) => ({
                ...row,
                isChecked: false,
              })),
            });
          } else {
            this.setState({
              dataTableAccountVpsManual: [],
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.error(err);
      });
  };

  doSubmit = (params) => {
    this.setState({
      submitLoading: true,
    });
    apiMigrationInsert(params)
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        const data = res.data;
        if (data.status === "OK") {
          // this.setState({
          //   openSuccess: true,
          //   openSuccessText: "VPS migration successful to request",
          // });
          // this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });
        console.error(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  doRowEdit = (row) => {
    // console.log("row: ", row);
    this.setState({
      setOpenEdit: true,
      detailInfo: row,
      dataTableAccountVpsManual: row.infoAccount,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.doLoadData();
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      dataTableAccountVpsManual: [],
      isSelectAllChecked: false,
    });
    this.setVpsManual.current = null;
    this.setVpsAuto.current = null;
  };

  handleFromTypeChange = () => {
    this.setVpsManual.current = null;
    this.setState({
      dataTableAccountVpsManual: [],
    });
    const { fromType } = this.state;

    if (fromType === 0) {
      console.log("Manual selected");
    } else if (fromType === 1) {
      console.log("Auto selected");
    }

    apiVpsOptionSelected(fromType)
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionVpsManual: data.records,
            });
          } else {
            this.setState({
              optionVpsManual: [],
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleChangeAccountMultiple = (value) => {
    const selectedAccount =
      this.state.dataTableAccountUnderAgent === null
        ? []
        : this.state.dataTableAccountUnderAgent;

    const newArrValue = value.map((item) => ({
      value: item.value,
      alias: item.alias,
    }));

    // console.log("newArrValue: ", newArrValue);

    const updatedAccounts =
      value !== null ? newArrValue.concat(selectedAccount) : null;

    const updatedOptions = this.state.optionAccountNumberAvailable.filter(
      (option) => !value.includes(option)
    );

    // console.log("updatedAccounts: ", updatedAccounts);

    // if (value !== null) {
    //   var filterDataAccount = this.state.dataAccount.filter(
    //     ({ value: id1 }) => !value.some(({ value: id2 }) => id2 === id1)
    //   );
    // } else {
    //   var filterDataAccount = this.state.dataAccount;
    // }

    this.setState({
      dataTableAccountUnderAgent: updatedAccounts,
      selectedAccountMultiple: null,
      optionAccountNumberAvailable: updatedOptions,
    });
  };

  doRemoveList = (item) => {
    const updatedOptions = this.state.dataTableAccountUnderAgent.filter(
      (option) => item.value !== option.value
    );

    const getToAvailable = this.state.dataTableAccountUnderAgent.filter(
      (option) => item.value === option.value
    );

    // console.log("updatedOptionsRemove: ", updatedOptions);
    // console.log("getToAvailable: ", getToAvailable);

    const newArrValueAvailable = getToAvailable.map((item) => ({
      value: item.value,
      alias: item.alias,
      label: `${item.value}-(${item.alias})`,
    }));

    const combinationAvailable = newArrValueAvailable.concat(
      this.state.optionAccountNumberAvailable
    );

    // console.log("combinationAvailable: ", combinationAvailable);

    this.setState({
      dataTableAccountUnderAgent: updatedOptions,
      optionAccountNumberAvailable: combinationAvailable,
    });
  };

  generateRandomHex = (length) => {
    return [...Array(length)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };

  generateTradingPassword(type = "master") {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";

    const minLength = 8;
    const maxLength = 15;

    const allChars = uppercase + lowercase + numbers;

    let password = "";

    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];

    if (type === "investor") {
      password += numbers[Math.floor(Math.random() * numbers.length)];
    } else if (type === "master") {
      password += uppercase[Math.floor(Math.random() * uppercase.length)];
    }

    const remainingLength =
      Math.floor(Math.random() * (maxLength - minLength + 1)) +
      minLength -
      password.length;

    for (let i = 0; i < remainingLength; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  }

  checkData = () => {
    const { dataTableAccountVpsManual } = this.state;

    const checkedRows = dataTableAccountVpsManual.filter(
      (row) => row.isChecked
    );
    console.log("Checked Rows:", checkedRows);

    const from =
      (this.setVpsManual.current && this.setVpsManual.current.value) || "";
    const to = (this.setVpsAuto.current && this.setVpsAuto.current.value) || "";

    const isAutoFrom =
      this.setVpsManual.current && this.setVpsManual.current.flag_auto;

    console.log("isAutoFrom: ", isAutoFrom);

    const isAvailableTo =
      this.setVpsAuto.current && this.setVpsAuto.current.available;

    console.log("isAvailableTo: ", isAvailableTo);

    const total = checkedRows.length;

    if (from === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty from vps?",
        titleBody: "Please select from vps migration",
      });
    } else if (to === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty to vps?",
        titleBody: "Please select to vps migration",
      });
    } else if (checkedRows.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty account selected?",
        titleBody: "Please select account from vps",
      });
    } else if (from === to) {
      this.setState({
        openValidation: true,
        titleValidation: "Same VPS",
        titleBody: "From VPS and to VPS cannot be the same.",
      });
    } else if (total > isAvailableTo) {
      this.setState({
        openValidation: true,
        titleValidation: "Can't do VPS migration?",
        titleBody: `${
          this.setVpsManual.current && this.setVpsManual.current.vps_name
        } migration cannot be executed because the number of accounts on the ${
          this.setVpsAuto.current && this.setVpsAuto.current.vps_name
        } ${
          this.setVpsAuto.current && this.setVpsAuto.current.available
        } accounts left`,
      });
    } else {
      const params = {
        from_vps_id: from,
        to_vps_id: to,
        total: total,
        accounts: checkedRows,
      };

      console.log("params: ", params);

      this.doSubmit(params);

      if (isAutoFrom === 1) {
        console.info("execute from auto to auto");

        //LIST ACCOUNT CLEAR MT VPS LAMA
        const result_account_clearMT = checkedRows.map((slave) => ({
          ...slave,
          action_status: "clearMT",
        }));

        const paramClearMT = result_account_clearMT.map((item) => {
          return {
            client_id: this.state.loginInfo.phoneno,
            vps:
              this.setVpsManual.current && this.setVpsManual.current.vps_name,
            vps_id:
              this.setVpsManual.current && this.setVpsManual.current.value,
            action: item.action_status,
            account_number: item.account_number,
          };
        });

        console.log("paramClearMT: ", paramClearMT);

        // LIST ACCOUNT CLEAR LOGIN VPS BARU
        const result_account_clearLogin = checkedRows.map((slave) => ({
          ...slave,
          action_status: "clearLogin",
        }));

        const paramClearLogin = result_account_clearLogin.map((item) => {
          const masterPassword = this.generateTradingPassword("master");
          const investorPassword = this.generateTradingPassword("investor");

          return {
            client_id: this.state.loginInfo.phoneno,
            vps: this.setVpsAuto.current && this.setVpsAuto.current.vps_name,
            vps_id: this.setVpsAuto.current && this.setVpsAuto.current.value,
            action: item.action_status,
            ...(item.status !== "Master" && {
              account_number: item.account_number,
              alias: item.alias,
              password: item.password_master,
              password_master: masterPassword,
              password_investor: investorPassword,
              lot: item.lots,
            }),
          };
        });

        console.log("paramClearLogin: ", paramClearLogin);

        //FIX VPS BARU
        let paramFix = {
          vps_id: this.setVpsAuto.current && this.setVpsAuto.current.value,
          vps: this.setVpsAuto.current && this.setVpsAuto.current.vps_name,
          action: "fix",
          client_id: this.state.loginInfo.phoneno,
        };

        console.log("paramFix: ", paramFix);

        const dataExecuted = {
          paramClearMT,
          paramClearLogin,
          paramFix,
        };

        this.executeFunctions(dataExecuted);
      } else {
        console.info("execute from manual to auto");

        const paramClearMT = [];

        // LIST ACCOUNT CLEAR LOGIN VPS BARU
        const result_account_clearLogin = checkedRows.map((slave) => ({
          ...slave,
          action_status: "clearLogin",
        }));

        const paramClearLogin = result_account_clearLogin.map((item) => {
          const masterPassword = this.generateTradingPassword("master");
          const investorPassword = this.generateTradingPassword("investor");

          return {
            client_id: this.state.loginInfo.phoneno,
            vps: this.setVpsAuto.current && this.setVpsAuto.current.vps_name,
            vps_id: this.setVpsAuto.current && this.setVpsAuto.current.value,
            action: item.action_status,
            ...(item.status !== "Master" && {
              account_number: item.account_number,
              alias: item.alias,
              password: item.password_master,
              password_master: masterPassword,
              password_investor: investorPassword,
              lot: item.lots,
            }),
          };
        });

        console.log("paramClearLogin: ", paramClearLogin);

        //FIX VPS BARU
        let paramFix = {
          vps_id: this.setVpsAuto.current && this.setVpsAuto.current.value,
          vps: this.setVpsAuto.current && this.setVpsAuto.current.vps_name,
          action: "fix",
          client_id: this.state.loginInfo.phoneno,
        };

        console.log("paramFix: ", paramFix);

        const dataExecuted = {
          paramClearMT,
          paramClearLogin,
          paramFix,
        };

        this.executeFunctions(dataExecuted);
      }
    }
  };

  doApiCloseMaster = async (payload) => {
    try {
      // console.log("Close Master:", payload);
      const res = await apiActionMaster(payload);
      console.log(res);
      return "CloseMaster Success";
    } catch (err) {
      console.error("Error in Close Master:", err);
      throw err;
    }
  };

  doApiClearMT = async (payload) => {
    try {
      console.log("Clear MT:", payload);
      const res = await apiActionClearMT(payload);
      console.log(res);
      return "ClearMT Success";
    } catch (err) {
      console.error("Error in Clear MT:", err);
      throw err;
    }
  };

  doApiClearLogin = async (payload) => {
    try {
      console.log("Clear Login:", payload);
      const res = await apiActionClearLogin(payload);
      console.log(res);
      return "ClearLogin Success";
    } catch (err) {
      console.error("Error in Clear Login:", err);
      throw err;
    }
  };

  doApiFix = async (payload) => {
    try {
      console.log("Fix:", payload);
      const res = await apiMasterSlaveFixVpsMonitorAuto(payload);
      console.log(res);
      return "Fix Success";
    } catch (err) {
      console.error("Error in Fix:", err);
      throw err;
    }
  };

  doApiOpenMaster = async (payload) => {
    try {
      // console.log("Open Master:", payload);
      const res = await apiActionMaster(payload);
      console.log(res);
      return "OpenMaster Success";
    } catch (err) {
      console.error("Error in Open Master:", err);
      throw err;
    }
  };

  executeFunctions = async (data) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    console.log("data: ", data);

    const clearMT = data.paramClearMT || [];

    const clearLogin = data.paramClearLogin || [];

    const fix = data.paramFix || null;

    this.setState({ isLoading: true });

    console.log("loading TRUE");

    try {
      if (clearMT.length > 0) {
        for (const item of clearMT) {
          await this.doApiClearMT(item);
          await delay(1000);
        }
      }

      if (clearLogin.length > 0) {
        for (const item of clearLogin) {
          await this.doApiClearLogin(item);
          await delay(1000);
        }
      }

      if (fix) {
        await this.doApiFix(fix);
      }

      // console.log("All actions completed successfully");
    } catch (error) {
      console.error("Error executing actions:", error);
    } finally {
      // Set loading state ke false setelah semua proses selesai

      console.log("loading FALSE");

      this.setState({ isLoading: false });
      this.setState({
        openSuccess: true,
        openSuccessText: "VPS migration successful to request",
      });
      this.handleCloseAddNew();
    }
  };

  handleChangeVpsManual = (vps_manual) => {
    this.setVpsManual.current = vps_manual;
    this.doVpsDetailId(vps_manual.value);
    this.forceUpdate();
  };

  handleChangeVpsAuto = (vps_auto) => {
    this.setVpsAuto.current = vps_auto;
    this.forceUpdate();
  };

  handleCheckboxChange = (accountNumber) => {
    this.setState((prevState) => ({
      dataTableAccountVpsManual: prevState.dataTableAccountVpsManual.map(
        (row) =>
          row.account_number === accountNumber
            ? { ...row, isChecked: !row.isChecked }
            : row
      ),
    }));
  };

  handleSelectAllCheckboxChange = () => {
    this.setState((prevState) => {
      const isSelectAllChecked = !prevState.isSelectAllChecked;

      // Update isChecked hanya untuk row yang bukan "Master"
      const updatedData = prevState.dataTableAccountVpsManual.map((row) => {
        if (row.status !== "Master") {
          return { ...row, isChecked: isSelectAllChecked };
        }
        return row;
      });

      return {
        dataTableAccountVpsManual: updatedData,
        isSelectAllChecked,
      };
    });
  };

  renderDialogAddNew = () => {
    const dataTableAccountVps =
      this.state.dataTableAccountVpsManual === null
        ? []
        : this.state.dataTableAccountVpsManual;

    const { detailInfo } = this.state;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullScreen
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Create VPS Migration"
                : this.state.setOpenEdit === true
                ? "Detail VPS Migration"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    From
                  </Typography>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="radio"
                        name="from_type"
                        value={0}
                        checked={this.state.fromType === 0}
                        onChange={(e) =>
                          this.setState(
                            {
                              fromType: parseInt(e.target.value),
                            },
                            this.handleFromTypeChange
                          )
                        }
                      />
                      Manual
                    </label>
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="radio"
                        name="from_type"
                        value={1}
                        checked={this.state.fromType === 1}
                        onChange={(e) =>
                          this.setState(
                            { fromType: parseInt(e.target.value) },
                            this.handleFromTypeChange
                          )
                        }
                      />
                      Auto
                    </label>
                  </div> */}
                </div>
              </Grid>

              <Grid item lg={10} md={9} sm={9} xs={12}>
                {this.state.setOpenEdit === true && detailInfo !== null ? (
                  <Input
                    autoComplete="off"
                    type="text"
                    name="from_vps_name"
                    id="from_vps_name"
                    value={detailInfo.from_vps_name}
                  />
                ) : (
                  <Select
                    classNamePrefix="select"
                    placeholder="Select a from vps"
                    isClearable={true}
                    value={this.setVpsManual.current}
                    onChange={this.handleChangeVpsManual}
                    options={this.state.optionVpsManual}
                    styles={{
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    menuPlacement="bottom"
                  />
                )}
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  To
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                {this.state.setOpenEdit === true && detailInfo !== null ? (
                  <Input
                    autoComplete="off"
                    type="text"
                    name="to_vps_name"
                    id="to_vps_name"
                    value={detailInfo.to_vps_name}
                  />
                ) : (
                  <Select
                    classNamePrefix="select"
                    placeholder="Select a to vps"
                    isClearable={true}
                    value={this.setVpsAuto.current}
                    onChange={this.handleChangeVpsAuto}
                    options={this.state.optionVpsAuto}
                    styles={{
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    menuPlacement="bottom"
                  />
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={12}>
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={dataTableAccountVps}
                  columns={[
                    {
                      Header: (
                        <input
                          type="checkbox"
                          checked={this.state.isSelectAllChecked}
                          onChange={this.handleSelectAllCheckboxChange}
                        />
                      ),
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "checkbox",
                      width: 25,
                      Cell: ({ original }) =>
                        original.status === "Master" ? null : (
                          <input
                            type="checkbox"
                            checked={original.isChecked}
                            onChange={() =>
                              this.handleCheckboxChange(original.account_number)
                            }
                          />
                        ),
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Account",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "account_number",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Alias",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "alias",
                      style: {
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Lots",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "lots",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    {
                      Header: "Status",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "status",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={15}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  showPagination={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        {this.state.setOpenEdit === true && detailInfo !== null ? null : (
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.handleCloseAddNew()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Typography>
            </Button>
            &nbsp;&nbsp;
            <Button
              disabled={this.state.submitLoading}
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.checkData()}
            >
              {this.state.submitLoading === true ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Save
                </Typography>
              )}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Agent?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove agent {item.agent_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteItem(item.agent_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderDialogPleaseWait = () => {
    return (
      <Dialog open={this.state.isLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
          }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Migration process in progress...
          </Typography>
        </div>
      </Dialog>
    );
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      VPS Migration
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Create Migration
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderRemoveItemInfo()}
        {this.renderDialogPleaseWait()}
      </Box>
    );
  }
}
export default MigrationVpsPage;
