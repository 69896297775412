import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
// import axios from "axios";
// import { serverUrl } from "../../../config.js";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import DefaultUserImg from "../../../Assets/Images/user-default-image.png";

import "./Profile.style.css";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.globallang = getLanguage(activeLanguage, "global");
    this.language = getLanguage(activeLanguage, "changepassword");
    this.state = {
      oldPass: "",
      newPass: "",
      confirmPass: "",
      email: "",
      name: "",
      phonenumber: "",
      profilePicture: "",
    };
  }

  doSubmit = () => {
    if (this.state.oldPass === "") {
      alert(this.language.emptyold);
      return false;
    }
    if (this.state.newPass === "") {
      alert(this.language.emptynew);
      return false;
    }
    if (this.state.newPass !== this.state.confirmPass) {
      alert(this.language.invalidconfirm);
      return false;
    }

    // let param = {
    //   oldPass: this.state.oldPass,
    //   newPass: this.state.newPass,
    //   confirmPass: this.state.confirmPass,
    //   phonenumber: this.state.phonenumber,
    // };

    this.props.doLoading();

    // axios({
    //   method: "post",
    //   url: serverUrl + "changePassword.php",
    //   data: param,
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    // })
    //   .then((response) => {
    //     this.props.doLoading();
    //     let data = response.data;
    //     // console.log(data);
    //     if (data.status === "0001") {
    //       alert(data.message);
    //       this.setState({ oldPass: "", newPass: "", confirmPass: "" });
    //       // localStorage.removeItem("loginInfo");
    //       this.props.history.replace("/");
    //     } else {
    //       alert(this.language.failed + ", " + data.message);
    //       this.setState({ oldPass: "", newPass: "", confirmPass: "" });
    //     }
    //   })
    //   .catch((error) => {
    //     this.props.doLoading();
    //     console.log(error);
    //   });
  };

  componentDidMount = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    if (
      loginInfo === undefined ||
      loginInfo === null ||
      loginInfo === "" ||
      loginInfo === "undefined" ||
      loginInfo === "null"
    ) {
      this.props.history.replace("/");
    } else {
      loginInfo = JSON.parse(loginInfo);
      console.log(loginInfo);
      this.setState({
        email: loginInfo.email,
        name: loginInfo.name,
        phonenumber: loginInfo.phoneno,
        profilePicture: loginInfo.profilepic,
      });
    }
    //on enter event
  };

  renderProfilePic = () => {
    if (
      this.state.profilePicture === "" ||
      this.state.profilePicture === undefined
    ) {
      return <img src={DefaultUserImg} alt="defaultpic" style={{display: 'block', width: '200px', marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, border: '2px solid #adadad'}} />;
    } else
      return <img src={this.state.profilePicture} alt="profilepic" style={{display: 'block', width: '200px', marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, border: '2px solid #adadad'}} />;
  };

  render() {
    return (
      <div>
        <div className="page-header">
          Profile
        </div>
        <div className="box-container">
          <Form>
            <FormGroup>
              {this.renderProfilePic()}
            </FormGroup>

            <FormGroup>
              <Label for="old">Name :</Label>
              <Input
                disabled
                name="old"
                id="old"
                placeholder=""
                value={this.state.name}
                style={{backgroundColor: 'white'}}
                // onChange={(event) =>
                //   this.setState({ oldPass: event.target.value })
                // }
              />
            </FormGroup>

            <FormGroup>
              <Label for="old">Email :</Label>
              <Input
                disabled
                name="old"
                id="old"
                placeholder=""
                value={this.state.email}
                style={{backgroundColor: 'white'}}
              />
            </FormGroup>

            <FormGroup>
              <Label for="old">Phone Number :</Label>
              <Input
                disabled
                name="old"
                id="old"
                placeholder=""
                style={{backgroundColor: 'white'}}
                value={this.state.phonenumber}
              />
            </FormGroup>
            
            <div className="form-button-container">
              <Button 
                color="primary" 
                // onClick={() => this.doSubmit()}
                onClick={() => alert('Coming Soon Bro!')}
                >
                Change Picture
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
export default Profile;
