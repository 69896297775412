import React, { Component } from "react";
import { apiSubmissionFormLog } from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import dayjs from "dayjs";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class SubmissionFormLogPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertApprove: false,
      openAlertReject: false,
      itemSelectedRow: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingReject: false,
      showLoadingApprove: false,
      showLoadingRejectAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      submitLoading: false,
      note: "",
      loginInfo: "",
    };

    this.tableColumns = [
      {
        Header: "Time",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "created_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {dayjs(e.original.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        ),
      },
      {
        Header: "Category",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "category",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.category}
          </Typography>
        ),
      },
      {
        Header: "Ticket ID",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "ticket_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.ticket_id}
          </Typography>
        ),
      },
      {
        Header: "Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_id}
          </Typography>
        ),
      },
      {
        Header: "Status",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.status}
          </Typography>
        ),
      },
      {
        Header: "Notes",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "note",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.note === null || e.original.note === ""
              ? "-"
              : e.original.note}
          </Typography>
        ),
      },
      {
        Header: "Client",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "client_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.client_name}
          </Typography>
        ),
      },
      {
        Header: "By",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "executor",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.executor}
          </Typography>
        ),
      },
    ];
    this.noteInputRef = React.createRef();
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiSubmissionFormLog()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.code === 200) {
          if (data.records.length > 0) {
            const temp = data.records;

            this.setState({
              tableData: temp,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.doLoadData();
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Job List Log
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
      </Box>
    );
  }
}
export default SubmissionFormLogPage;
