import React, { Component } from "react";
import { apiAutomationissues, apiActionResend } from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  Alert,
  IconButton,
} from "@mui/material";
import { Refresh, Replay10Outlined, Close } from "@mui/icons-material";
import dayjs from "dayjs";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

const getCurrentFormattedDate = () => {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");
  // const hh = String(now.getHours()).padStart(2, "0");
  // const min = String(now.getMinutes()).padStart(2, "0");
  // const ss = String(now.getSeconds()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
};
class IssuesPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertApprove: false,
      openAlertReject: false,
      itemSelectedRow: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingReject: false,
      showLoadingApprove: false,
      showLoadingRejectAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      submitLoading: false,
      note: "",
      loginInfo: "",
      openDialog: false,
      messageDetail: "",
    };

    this.tableColumns = [
      {
        Header: "Time",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "created_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {dayjs(e.original.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        ),
      },

      {
        Header: "Ticket Id",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "ticket_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.ticket_id === null ? "-" : e.original.ticket_id}
          </Typography>
        ),
      },
      // {
      //   Header: "VPS",
      //   headerStyle: { fontWeight: "bold", fontSize: 14 },
      //   accessor: "vps_name",
      //   style: {
      //     textAlign: "center",
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //   },
      //   width: 300,
      //   Cell: (e) => (
      //     <Typography
      //       component="span"
      //       variant="body2"
      //       style={{
      //         display: "inline-block",
      //       }}
      //     >
      //       {e.original.vps_name === null
      //         ? "-"
      //         : `${e.original.vps_id} -  (${e.original.vps_name})`}
      //     </Typography>
      //   ),
      // },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "action",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.action === null ? "-" : e.original.action}
          </Typography>
        ),
      },
      {
        Header: "Client ID",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "client_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.client_id === null ? "-" : e.original.client_id}
          </Typography>
        ),
      },

      {
        Header: "Status",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.status === null ? "-" : e.original.status}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => {
          const { message, created_at } = e.original;

          const isChangePasswordTrue =
            message && message.indexOf("After Change Password(true)") !== -1;

          const original = created_at;
          const currentFormattedDate = getCurrentFormattedDate();

          const isDisabled = currentFormattedDate > original;

          const handleResend = () => {
            if (isChangePasswordTrue) {
              this.doResendNewTicket(e.original);
            } else {
              this.doResend(e.original);
            }
          };

          return (
            <div>
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: isDisabled ? "grey" : "#3f51b5",
                }}
                startIcon={<Replay10Outlined style={{ color: "white" }} />}
                onClick={handleResend}
                disabled={isDisabled}
              >
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Resend
                </Typography>
              </Button>
            </div>
          );
        },
      },
    ];
    this.noteInputRef = React.createRef();
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiAutomationissues()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.code === 200) {
          if (data.records.length > 0) {
            const temp = data.records;

            this.setState({
              tableData: temp,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.doLoadData();
  };

  handleClickOpen = (message) => {
    this.setState({ openDialog: true, messageDetail: message });
  };

  handleClose = () => {
    this.setState({ openDialog: false, messageDetail: "" });
  };

  doResendNewTicket = (item) => {
    console.log("item resend new ticket: ", item);
  };

  doResend = (item) => {
    // console.log("item resend: ", item);

    this.props.doLoading();

    apiActionResend(item)
      .then((res) => {
        console.info(res);
        this.props.doLoading();
        const data = res.data;
        if (data.status === "success") {
          this.setState({
            openSuccess: true,
            openSuccessText: `Successfully resend ticket ${item.ticket_id}`,
          });
        } else {
          this.setState({
            openSuccess: true,
            openSuccessText: data.message,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();

        console.error(err);
      });
  };

  renderDialog = () => {
    return (
      <Dialog
        open={this.state.openDialog}
        onClose={this.handleClose}
        aria-labelledby="message-dialog-title"
        aria-describedby="message-dialog-description"
      >
        <DialogTitle id="message-dialog-title">Message Detail</DialogTitle>
        <DialogContent>
          <DialogContentText id="message-dialog-description">
            {this.state.messageDetail === ""
              ? "No message"
              : this.state.messageDetail}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Automation Issues
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
            SubComponent={(row) => {
              return (
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "#e9ecef",
                    borderRadius: "5px",
                    fontSize: "14px",
                    lineHeight: "1.5",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Details:
                  </Typography>
                  <ul style={{ paddingLeft: "20px", margin: 0 }}>
                    <li style={{ marginBottom: "5px" }}>
                      <strong>VPS:</strong>{" "}
                      {row.original.vps_name === null
                        ? "-"
                        : `${row.original.vps_id} - (${row.original.vps_name})`}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <strong>Account:</strong>{" "}
                      {row.original.account_number === null
                        ? "-"
                        : `${row.original.account_number}`}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <strong>Lot:</strong>{" "}
                      {row.original.lot === null || row.original.lot === ""
                        ? "-"
                        : row.original.lot}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <strong>Response Time:</strong>{" "}
                      {row.original.response_time === null
                        ? "-"
                        : dayjs(row.original.response_time).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <strong>Status:</strong>{" "}
                      {row.original.status === null
                        ? "-"
                        : `${row.original.status}`}
                    </li>
                    <li>
                      <strong>Message:</strong>{" "}
                      {row.original.message === null
                        ? "-"
                        : `${row.original.message}`}
                    </li>
                  </ul>
                </div>
              );
            }}
          />
        </div>

        {this.renderDialog()}
      </Box>
    );
  }
}
export default IssuesPage;
