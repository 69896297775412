let Languages = {
  eng: {
    global: {
      add: "Add New",
      edit: "Edit",
      view: "Detail",
      delete: "Delete",
      search: "Search",
      submit: "Submit",
      cancel: "Cancel",
      uploadpicture: "Upload Picture",
      gallery: "Gallery",
      banner1: "Upload Banner Top",
      banner2: "Upload Banner Bottom",
      show: "Show",
      hidden: "Hidden",
      ready: "Ready",
      working: "Working On",
      yes: "Yes",
      no: "No",
      pending: "PENDING",
      paid: "PAID",
      process: "PROCESS",
      done: "DONE",
      cancelled: "Cancelled",
      pay: "Pay",
      delivery: "ON DELIVERY",
      delivered: "DELIVERED",
      booked: "Already Booked",
      noorder: "Not Yet Ordered",
      reset: "Reset",
      export: "Export",
      searchbydate: "Filter",
    },
    header: {
      title: "Smart Community Control Panel",
    },
    selectmulticolumn: {
      placeholder: "- SELECT -",
    },
    Menu: {
      master: "Master",
      dashboard: "Dashboard",
      slide: "Slide Promotion",
      parkinformation: "Park Information",
      parkintroduction: "Park Introduction",
      servicephilosophy: "Service Philosophy",
      parkpositioning: "Park Positioning",
      parkenterprise: "Park Enterprise",
      companyintroduction: "Company Introduction",
      businesscase: "Business Case",
      corporatecontact: "Corporate Contact",
      news: "News",
      newscategory: "News Category",
      category: "Category",
      community: "Community",
      company: "Company",
      admin: "Admin",
      user: "User",
      teknisi: "Technician Service",
      tag: "Tag",
      topslide: "Top Slide",
      bottomslide: "Bottom Slide",
      companycategory: "Company Category",
      product: "Product",
      productcategory: "Product Category",
      service: "Service",
      servicecategory: "Service Category",
      project: "Project",
      projectcategory: "Project Category",
      investment: "Investment",
      investmentcategory: "Investment Category",
      talent: "Talent",
      talentcategory: "Talent Category",
      example: "Example",
      download: "Download",
      downloadcategory: "Download Category",
      merchant: "Merchant",
      merchantcategory: "Merchant Category",
      commodity: "Commodity",
      commoditycategory: "Commodity Category",
      marketplaceevent: "Marketplace Event",
      marketplaceadvertisement: "Advertisement",
      onlinestorecategory: "Online Store Category",
      onlinestore: "Online Store",
      moments: "Moments",
      billing: "Billing",
      billingdebtor: "Billing IPKL",
      billingcategory: "Billing Category",
      servicecenter: "Service Center",
      servicecentercategory: "Service Center Category",
      homeconfig: "Home Config",
      entertainment: "Entertainment",
      userservice: "User Service Order",
      roomcategory: "Room Category",
      room: "Room Bookings",
      roomreservation: "Room Reservation",
      activitycategory: "Activity Category",
      activity: "Activity Bookings",
      activityreservation: "Activity Reservation",
      wedding: "Wedding Bookings",
      weddingreservation: "Wedding Reservation",
      nonwedding: "Facilities Bookings",
      nonweddingreservation: "Facilities Reservation",
      order: "Order",
      uservehicle: "User Vehicle",
      parking: "Parking",
      forweddingcategory: "Facilities Category",
      nonweddingcategory: "Non Wedding Category",
      uservisit: "User Visit",
      callcenter: "Emergency Call",
      directory: "Directory",
      directorycategory: "Directory Category",
      voucher: "Voucher",
      foodgarden: "Food Garden",
      newsactivities: "News Activities",
      ordermanagement: "Order Management",
      servicemanagement: "Service Management",
      marketplace: "Marketplace",
      basicmanagement: "Basic Management",
      payment: "Payment Management",
      foodorder: "Food Order",
      infocovid19: "Informasi Covid 19",
      newscovid: "News Covid-19",
      listcontactpanic: "Emergency Call",
      complaintform: "Complaint Covid-19",
      healthdeclaration: "Contact Tracing",
      dailydeclaration: "Daily Declaration",
      returntrip: "Return Trip",
      springfestival: "Return Mudik",
      callcovidcenter: "Call Center",
      bannermanagement: "Banner Management",
      bannerhome: "Home Banner",
      wfhmanagement: "WFH Management",
      wfh: "WFH (Work From Home)",
    },
    /**
     * Components
     */
    MenuProfile: {
      profile: "Profile",
      changepassword: "Change Password",
      logout: "Logout",
    },
    PictureUploader: {
      placeholder: 'Drag your file here or click "Choose file" button',
      choosefile: "Choose File",
      maxfile: "Max File",
      pictures: "Picture(s)",
      reachlimit: "Already reach limit file",
    },
    FileUploader: {
      placeholder: 'Drag your file here or click "Choose file" button',
      choosefile: "Choose File",
      maxfile: "Max file",
      file: "File(s)",
      reachlimit: "Already reach limit file",
    },
    /**
     * Pages
     */
    Login: {
      title: "Smart Community",
      phoneno: "Phone No",
      password: "Password",
      login: "LOGIN",
      alertphoneno: "Please input your phone no",
      alertpassword: "Please input your password",
    },
    changepassword: {
      title: "Change Password",
      oldpassword: "Old Password",
      newpassword: "New Password",
      confirmpassword: "Confirm New Password",
      emptyold: "Please input old password",
      emptynew: "Please input new password",
      invalidconfirm: "Confirm password is not same with new password",
      success: "Change Password Success",
      failed: "Change Password Failed",
    },
    dashboard: {
      title: "Dashboard",
    },
    example: {
      title: "example",
    },
    parkintroduction: {
      title: "Park Introduction",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    parkpositioning: {
      title: "Park Positioning",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    servicephilosophy: {
      title: "Service Philosophy",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    companyintroduction: {
      title: "Company Introduction",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    businesscase: {
      title: "Business Case",
      columntitle: "Title",
      columndescription: "Description",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete business case?",
      deletesuccess: "Delete successful",
    },
    inputbusinesscase: {
      title: "Business Case",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editbusinesscase: {
      title: "Business Case",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    corporatecontact: {
      title: "Corporate Contact",
      columnname: "Name",
      columnphone: "Phone",
      columncommunity: "Community",
      columnposition: "Position",
      columnaction: "Action",
      confirmdelete: "Delete corporate contact?",
      deletesuccess: "Delete successful",
    },
    inputcorporatecontact: {
      title: "Corporate Contact",
      fieldname: "Name",
      fieldphone: "Phone",
      fieldcommunity: "Community",
      fieldposition: "Position",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcorporatecontact: {
      title: "Corporate Contact",
      fieldname: "Name",
      fieldphone: "Phone",
      fieldcommunity: "Community",
      fieldposition: "Position",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcommunity: {
      title: "Community",
      columnname: "Community Name",
      columndefault: "Is Default",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete community?",
      deletesuccess: "Delete successful",
    },
    inputcommunity: {
      title: "Community",
      fieldname: "Community Name",
      fielddefault: "Is Default",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommunity: {
      title: "Community",
      fieldname: "Community Name",
      fielddefault: "Is Default",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcompany: {
      title: "Company",
      columnname: "Company Name",
      columncategory: "Company Category",
      columnshortdesc: "Short Desc",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete company?",
      deletesuccess: "Delete successful",
      upload: "Upload",
      savesuccess: "Save successful",
      modaltitle: "Upload File",
      modalfieldname: "File",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      validation: "Please upload a file",
    },
    inputcompany: {
      title: "Company",
      fieldname: "Company Name",
      fieldcategory: "Company Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Company Category",
      modalfieldname: "Company Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editcompany: {
      title: "Company",
      fieldname: "Company Name",
      fieldcategory: "Company Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Company Category",
      modalfieldname: "Company Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listcompanycategory: {
      title: "Company Category",
      columnname: "Company Category Name",
      columnaction: "Action",
      confirmdelete: "Delete company category?",
      deletesuccess: "Delete successful",
    },
    inputcompanycategory: {
      title: "Company Category",
      fieldname: "Company Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcompanycategory: {
      title: "Company Category",
      fieldname: "Company Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproductcategory: {
      title: "Product Category",
      columnname: "Product Category Name",
      columnaction: "Action",
      confirmdelete: "Delete product category?",
      deletesuccess: "Delete successful",
    },
    inputproductcategory: {
      title: "Product Category",
      fieldname: "Product Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editproductcategory: {
      title: "Product Category",
      fieldname: "Product Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproduct: {
      title: "Product",
      columnname: "Product Name",
      columncategory: "Product Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete product?",
      deletesuccess: "Delete successful",
    },
    inputproduct: {
      title: "Product",
      fieldname: "Product Name",
      fieldcategory: "Product Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Product Category",
      modalfieldname: "Product Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editproduct: {
      title: "Product",
      fieldname: "Product Name",
      fieldcategory: "Product Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Product Category",
      modalfieldname: "Product Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listservicecategory: {
      title: "Service Category",
      columnname: "Service Category Name",
      columnaction: "Action",
      confirmdelete: "Delete service category?",
      deletesuccess: "Delete successful",
    },
    inputservicecategory: {
      title: "Service Category",
      fieldname: "Service Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editservicecategory: {
      title: "Service Category",
      fieldname: "Service Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listservice: {
      title: "Service",
      columnname: "Service Name",
      columncategory: "Service Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service?",
      deletesuccess: "Delete successful",
    },
    inputservice: {
      title: "Service",
      fieldname: "Service Name",
      fieldcategory: "Service Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Category",
      modalfieldname: "Service Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editservice: {
      title: "Service",
      fieldname: "Service Name",
      fieldcategory: "Service Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Category",
      modalfieldname: "Service Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listtag: {
      title: "Tag",
      columnname: "Tag Name",
      columnaction: "Action",
      confirmdelete: "Delete tag?",
      deletesuccess: "Delete successful",
    },
    inputtag: {
      title: "Tag",
      fieldname: "Tag Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edittag: {
      title: "Tag",
      fieldname: "Tag Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listadmin: {
      title: "Admin",
      columnphone: "Phone",
      columnname: "Name",
      columntype: "Type",
      columnemail: "Email",
      columnlastlogin: "Last Login",
      columnsuspend: "Is Suspend",
      columnaction: "Action",
      confirmdelete: "Delete admin?",
      deletesuccess: "Delete successful",
    },
    inputadmin: {
      title: "Admin",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldtype: "Type",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldaccesscommunity: "Access Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
    },
    editadmin: {
      title: "Admin",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldtype: "Type",
      fieldemail: "Email",
      fieldaccesscommunity: "Access Community",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listuser: {
      title: "User",
      columnphone: "Phone",
      columnname: "Name",
      columnusertype: "User Type",
      columngender: "Gender",
      columncompany: "Company",
      columnlocation: "Location",
      columnemail: "Email",
      columnlastip: " IP",
      columnlastlogin: "Last Login",
      columnsuspend: "Is Suspend",
      columnaction: "Action",
      confirmdelete: "Delete user?",
      deletesuccess: "Delete successful",
    },
    inputuser: {
      title: "Input User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
    },
    edituser: {
      title: "Edit User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      columnplateno: "Plate No",
      columnvehicletype: "Vehicle Type",
      confirmdeleteuservehicle: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
      modaluservehicletitle: "Edit User Vehicle",
    },
    listteknisi: {
      title: "Teknisi",
      columnphone: "Phone",
      columnname: "Name",
      columnusertype: "User Type",
      columngender: "Gender",
      columncompany: "Company",
      columnlocation: "Location",
      columnemail: "Email",
      columnposition: " Position",
      columnlastlogin: "Last Login",
      columnavailable: "Is Available",
      columnsuspend: "Is Suspend",
      columnstatus: "Is Status",
      columnaction: "Action",
      confirmdelete: "Delete Teknisi?",
      deletesuccess: "Delete successful",
    },
    inputteknisi: {
      title: "Input Teknisi",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fieldstatus: "Status",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
    },
    editteknisi: {
      title: "Edit User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldstatus: "Status",
      fieldlocation: "Location",
      fieldavailable: "Available",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      columnplateno: "Plate No",
      columnvehicletype: "Vehicle Type",
      confirmdeleteuservehicle: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
      modaluservehicletitle: "Edit User Vehicle",
    },
    listnewscategory: {
      title: "News Category",
      columnname: "News Category Name",
      columnaction: "Action",
      confirmdelete: "Delete news category?",
      deletesuccess: "Delete successful",
    },
    inputnewscategory: {
      title: "News Category",
      fieldname: "News Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editnewscategory: {
      title: "News Category",
      fieldname: "News Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listnews: {
      title: "News Management",
      messagenumber: "News Number",
      columntitle: "Title",
      columncommunity: "Community",
      columnavailable: "Status",
      columnaction: "Action",
      confirmdelete: "Are you sure you want to delete?",
      deletesuccess: "Delete success",
      newscategory: "News category",
      releasetime: "Release date",
      publisher: "Publisher",
      pinned: "Pinned state",
      displaystate: "Display state",
      reading: "Reading",
      approvalstatus: "Audit status",
      time: "Time",
    },
    inputnews: {
      title: "News Management",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    editnews: {
      title: "News Management",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    listprojectcategory: {
      title: "Project Category",
      columnname: "Project Category Name",
      columnaction: "Action",
      confirmdelete: "Delete project category?",
      deletesuccess: "Delete successful",
    },
    inputprojectcategory: {
      title: "Project Category",
      fieldname: "Project Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editprojectcategory: {
      title: "Project Category",
      fieldname: "Project Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproject: {
      title: "Project",
      columnname: "Project Name",
      columncategory: "Project Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete project?",
      deletesuccess: "Delete successful",
    },
    inputproject: {
      title: "Project",
      fieldname: "Project Name",
      fieldcategory: "Project Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Project Category",
      modalfieldname: "Project Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editproject: {
      title: "Project",
      fieldname: "Project Name",
      fieldcategory: "Project Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Project Category",
      modalfieldname: "Project Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listinvestmentcategory: {
      title: "Investment Category",
      columnname: "Investment Category Name",
      columnaction: "Action",
      confirmdelete: "Delete investment category?",
      deletesuccess: "Delete successful",
    },
    inputinvestmentcategory: {
      title: "Investment Category",
      fieldname: "Investment Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editinvestmentcategory: {
      title: "Investment Category",
      fieldname: "Investment Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listinvestment: {
      title: "Investment",
      columnname: "Investment Name",
      columncategory: "Investment Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete investment?",
      deletesuccess: "Delete successful",
    },
    inputinvestment: {
      title: "Investment",
      fieldname: "Investment Name",
      fieldcategory: "Investment Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Investment Category",
      modalfieldname: "Investment Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editinvestment: {
      title: "Investment",
      fieldname: "Investment Name",
      fieldcategory: "Investment Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Investment Category",
      modalfieldname: "Investment Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listtalentcategory: {
      title: "Talent Category",
      columnname: "Talent Category Name",
      columnaction: "Action",
      confirmdelete: "Delete talent category?",
      deletesuccess: "Delete successful",
    },
    inputtalentcategory: {
      title: "Talent Category",
      fieldname: "Talent Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edittalentcategory: {
      title: "Talent Category",
      fieldname: "Talent Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listtalent: {
      title: "Talent",
      columnname: "Talent Name",
      columncategory: "Talent Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete talent?",
      deletesuccess: "Delete successful",
    },
    inputtalent: {
      title: "Talent",
      fieldname: "Talent Name",
      fieldcategory: "Talent Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Talent Category",
      modalfieldname: "Talent Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    edittalent: {
      title: "Talent",
      fieldname: "Talent Name",
      fieldcategory: "Talent Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Talent Category",
      modalfieldname: "Talent Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listdownloadcategory: {
      title: "Download Category",
      columnname: "Download Category Name",
      columnaction: "Action",
      confirmdelete: "Delete download category?",
      deletesuccess: "Delete successful",
    },
    inputdownloadcategory: {
      title: "Download Category",
      fieldname: "Download Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdownloadcategory: {
      title: "Download Category",
      fieldname: "Download Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdownload: {
      title: "Download",
      columnname: "Download Name",
      columncategory: "Download Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete download?",
      deletesuccess: "Delete successful",
    },
    inputdownload: {
      title: "Download",
      fieldname: "Download Name",
      fieldcategory: "Download Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Download Category",
      modalfieldname: "Download Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editdownload: {
      title: "Download",
      fieldname: "Download Name",
      fieldcategory: "Download Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Download Category",
      modalfieldname: "Download Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listmerchantcategory: {
      title: "Merchant Category",
      columnname: "Merchant Category Name",
      columnaction: "Action",
      confirmdelete: "Delete merchant category?",
      deletesuccess: "Delete successful",
    },
    inputmerchantcategory: {
      title: "Merchant Category",
      fieldname: "Merchant Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmerchantcategory: {
      title: "Merchant Category",
      fieldname: "Merchant Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listmerchant: {
      title: "Merchant",
      columnname: "Merchant Name",
      columncategory: "Merchant Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnppn: "PPN Tax",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete merchant?",
      deletesuccess: "Delete successful",
    },
    inputmerchant: {
      title: "Merchant",
      fieldname: "Merchant Name",
      fieldcategory: "Merchant Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Merchant Category",
      modalfieldname: "Merchant Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editmerchant: {
      title: "Merchant",
      fieldname: "Merchant Name",
      fieldcategory: "Merchant Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Merchant Category",
      modalfieldname: "Merchant Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listcommoditycategory: {
      title: "Commodity Category",
      columnname: "Commodity Category Name",
      columnaction: "Action",
      confirmdelete: "Delete commodity category?",
      deletesuccess: "Delete successful",
    },
    inputcommoditycategory: {
      title: "Commodity Category",
      fieldname: "Commodity Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommoditycategory: {
      title: "Commodity Category",
      fieldname: "Commodity Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcommodity: {
      title: "Commodity",
      columnname: "Commodity Name",
      columncategory: "Commodity Category",
      columntags: "Tags",
      columnmerchant: "Merchant",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete commodity?",
      deletesuccess: "Delete successful",
    },
    inputcommodity: {
      title: "Commodity",
      fieldname: "Commodity Name",
      fieldcategory: "Commodity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldmerchant: "Merchant",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommodity: {
      title: "Commodity",
      fieldname: "Commodity Name",
      fieldcategory: "Commodity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldmerchant: "Merchant",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listmarketplaceevent: {
      title: "Marketplace Event",
      columntitle: "Title",
      columndescription: "Description",
      columnstartdate: "Start Date",
      columnenddate: "End Date",
      columnstarttime: "Start Time",
      columnendtime: " End Time",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete marketplace event?",
      deletesuccess: "Delete successful",
    },
    inputmarketplaceevent: {
      title: "Input Marketplace Event",
      fieldlocation: "Location",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldstartdate: "Start Date",
      fieldenddate: "End Date",
      fieldstarttime: "Start Time",
      fieldendtime: "End Time",
      fieldtype: "Type",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editmarketplaceevent: {
      title: "Edit Marketplace Event",
      fieldlocation: "Location",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldstartdate: "Start Date",
      fieldenddate: "End Date",
      fieldstarttime: "Start Time",
      fieldendtime: "End Time",
      fieldtype: "Type",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listmarketplaceadvertisement: {
      title: "Marketplace Advertisement",
      columnname: "Name",
      columnbannerpic: "Banner Picture",
      columnlink: "Link",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete marketplace advertisement?",
      deletesuccess: "Delete successful",
    },
    inputmarketplaceadvertisement: {
      title: "Input Marketplace Advertisement",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmarketplaceadvertisement: {
      title: "Edit Marketplace Advertisement",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listonlinestorecategory: {
      title: "Online Store Category",
      columnname: "Online Store Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete online store category?",
      deletesuccess: "Delete successful",
    },
    inputonlinestorecategory: {
      title: "Online Store Category",
      fieldname: "Online Store Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editonlinestorecategory: {
      title: "Online Store Category",
      fieldname: "Online Store Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listonlinestore: {
      title: "Online Store",
      columnname: "Online Store Name",
      columncategory: "Online Store Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete online store?",
      deletesuccess: "Delete successful",
    },
    inputonlinestore: {
      title: "Online Store",
      fieldname: "Online Store Name",
      fieldcategory: "Online Store Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Online Store Category",
      modalfieldname: "Online Store Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editonlinestore: {
      title: "Online Store",
      fieldname: "Online Store Name",
      fieldcategory: "Online Store Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Online Store Category",
      modalfieldname: "Online Store Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listmoments: {
      title: "Moments",
      columnname: "Name",
      columndesc: "Moment Description",
      columntags: "Tags",
      columncommunity: "Community",
      columndate: "Date",
      columnhidden: "Is Hidden",
      columnaction: "Action",
      confirmdelete: "Delete moment?",
      deletesuccess: "Delete successful",
    },
    inputmoments: {
      title: "Moments",
      fieldname: "Name",
      fielddesc: "Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldhidden: "Is Hidden",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmoments: {
      title: "Moments",
      fieldname: "Name",
      fielddesc: "Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldhidden: "Is Hidden",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcomment: "Comment",
      columnname: "Name",
      columncomment: "Comment",
      columnhidden: "Hidden",
      columnaction: "Action",
      confirmdelete: "Delete comment?",
      deletesuccess: "Delete successful",
      modaltitle: "Edit Comment",
      modalfieldname: "Comment",
      modalfieldhidden: "Hidden",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
    },
    listbilling: {
      title: "Billing",
      columnname: "User",
      columncategory: "Billing Category",
      columnmonth: "Month",
      columnyear: "Year",
      columncustomerid: "Customer ID",
      columnbillingtype: "Billing Type",
      columnbillingamount: "Billing Amount",
      columnbillingstatus: "Billing Status",
      columnaction: "Action",
      confirmdelete: "Delete billing?",
      deletesuccess: "Delete successful",
    },
    listbillingdebtor: {
      title: "Billing IPKL",
      columnname: "User",
      columntransaksi: "Transaksi ID",
      columncategory: "External ID",
      columnmonth: "Debtor Acct",
      columnyear: "Billing Amount",
      columncustomerid: "Billing Status",
      columnbillingtype: "Paid Date",
      columnbillingamount: "Paid Date",
      columnbillingstatus: "Phone Number",
      columnaction: "Action",
      confirmdelete: "Delete billing?",
      deletesuccess: "Delete successful",
    },
    inputbilling: {
      title: "Billing",
      fieldphoneno: "User",
      fieldcategory: "Billing Category",
      fieldmonth: "Select Month",
      fieldyear: "Select Year",
      fieldcustomerid: "Customer ID",
      fieldbillingtype: "Billing Type",
      fieldamount: "Billing Amount",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      fieldaddress: "Address",
    },
    editbilling: {
      title: "Billing",
      fieldphoneno: "User",
      fieldcategory: "Billing Category",
      fieldmonth: "Select Month",
      fieldyear: "Select Year",
      fieldcustomerid: "Customer ID",
      fieldbillingtype: "Billing Type",
      fieldamount: "Billing Amount",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    detailbilling: {
      title: "Billing Detail Debtor",
      fieldexternalid: "Invoice Number",
      fieldphone: "Phone Number",
      fieldname_debtor: "Name Debtor",
      fieldtransaki_amount: "Transaksi Amount",
      fieldpayer_email: "Email",
      fieldtransaksi_date: "Transaksi Date",
      fieldtransaksi_id: "Transaksi ID",
      fieldpaid_amount: "Paid Amount",
      fieldpayment_method: "Payment Method",
      fieldpayment_channel: "Payment Channel",
      fieldstatus_payment: "Status Payment",
      fieldpayment_date: "Payment Date",
      fielddebtor: "Debtor Account",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      invoicenumber: "Invoice Number",
      amountpayment: "Amount Payment",
    },
    listbillingcategory: {
      title: "Billing Category",
      columnname: "Billing Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete billing category?",
      deletesuccess: "Delete successful",
    },
    inputbillingcategory: {
      title: "Billing Category",
      fieldname: "Billing Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editbillingcategory: {
      title: "Billing Category",
      fieldname: "Billing Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listservicecenter: {
      title: "Service Center",
      columnname: "Name",
      columncategory: "Service Center Category",
      columndesc: "Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center?",
      deletesuccess: "Delete successful",
    },
    inputservicecenter: {
      title: "Service Center",
      fieldname: "Name",
      fieldcategory: "Service Center Category",
      fielddesc: "Description",
      fieldprice: "Price",
      fieldavailable: "Is Available",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Center Category",
      modalfieldname: "Service Center Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editservicecenter: {
      title: "Service Center",
      fieldname: "Name",
      fieldcategory: "Service Center Category",
      fielddesc: "Description",
      fieldprice: "Price",
      fieldavailable: "Is Available",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Center Category",
      modalfieldname: "Service Center Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listservicecentercategory: {
      title: "Service Center Category",
      columnname: "Service Center Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center category?",
      deletesuccess: "Delete successful",
    },
    inputservicecentercategory: {
      title: "Service Center Category",
      fieldname: "Service Center Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editservicecentercategory: {
      title: "Service Center Category",
      fieldname: "Service Center Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      columnname: "Service Center Item Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center item?",
      deletesuccess: "Delete successful",
      modaltitle: "New Service Center Item",
      modalfieldname: "Service Center Item Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    homeconfig: {
      title: "Home Config",
      fieldnewschecked: "News Feed",
      fieldnewscount: "Show News",
      fieldadvertisechecked: "Advertise",
      fieldadvertisecount: "Show Advertise",
      columntitle: "Title",
      columndesc: "Description",
      columnlink: "Link",
      columnavailable: "Is Available",
      columnaction: "Action",
      modaltitle: "Advertisement",
      modalfieldtitle: "Title",
      modalfielddesc: "Description",
      modalfieldlink: "Link",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      confirmdelete: "Delete advertisement?",
      deletesuccess: "Delete successful",
    },
    listentertainment: {
      title: "Entertainment",
      columnlink: "Link",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete entertainment?",
      deletesuccess: "Delete successful",
    },
    inputentertainment: {
      title: "Entertainment",
      fieldlink: "Link",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editentertainment: {
      title: "Entertainment",
      fieldlink: "Link",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listuserservice: {
      title: "User Service",
      columnmain: "Service Center",
      columnprice: "Total Price",
      columnstatuspay: "Status Payment",
      columnname: "User",
      columnphone: "Phone Number",
      columncategory: "Service Center Category",
      columnrequestdate: "Request Date",
      columnnote: "Note",
      columncustomerid: "Customer ID",
      columnassigned: "Assigned People",
      columnstatus: "Status Service",
      columnteknisi: "Technician",
      columnaction: "Action",
      confirmdelete: "Delete user service?",
      deletesuccess: "Delete successful",
    },
    inputuserservice: {
      title: "User Service",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edituserservice: {
      title: "User Service",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      userservicedetail: "User Service Detail",
      columnservicecenter: "Service Center",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldservicecenter: "Service Center",
      fieldquantity: "Quantity",
      modaltitle: "Edit User Service Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete user service detail?",
      deletesuccess: "Delete successful",
    },
    listroomcategory: {
      title: "Room Category",
      columnname: "Room Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room category?",
      deletesuccess: "Delete successful",
    },

    inputroomcategory: {
      title: "Room Category",
      fieldname: "Room Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editroomcategory: {
      title: "Room Category",
      fieldname: "Room Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listroom: {
      title: "Room",
      columnname: "Room Name",
      columncategory: "Room Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    listvoucher: {
      title: "Voucher",
      columnname: "Voucher Name",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnvaliddatein: "Valid Date in",
      columnvaliddateout: "Valid Date out",
      columnprice: "Price",
      columndiscount: "Discount",
      columnlimit: "Limit",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    inputroom: {
      title: "Room",
      fieldname: "Room Name",
      fieldcategory: "Room Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    inputvoucher: {
      title: "Voucher",
      fieldname: "Voucher Name",
      fielddesc: "Voucher Description",
      fieldvaliddatein: "Valid Date In",
      fieldvaliddateout: "Valid Date Out",
      fieldvoucherprice: "Voucher Price",
      fieldvoucherdisc: "Voucher Discount",
      fieldvoucherlimit: "Voucher Limit",
      fieldvoucherpic: "Voucher Picture",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editvoucher: {
      title: "Voucher",
      fieldname: "Voucher Name",
      fielddesc: "Voucher Description",
      fieldvaliddatein: "Valid Date In",
      fieldvaliddateout: "Valid Date Out",
      fieldvoucherprice: "Voucher Price",
      fieldvoucherdisc: "Voucher Discount",
      fieldvoucherlimit: "Voucher Limit",
      fieldvoucherpic: "Voucher Picture",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editroom: {
      title: "Room",
      fieldname: "Room Name",
      fieldcategory: "Room Category",
      fieldprice: "Price",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listroomreservation: {
      title: "Room Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Room",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete room reservation?",
      deletesuccess: "Delete successful",
    },
    listforweddingreservation: {
      title: "Wedding Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Facilities Name",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete reservation?",
      deletesuccess: "Delete successful",
    },
    listnonweddingreservation: {
      title: "Facilities Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Facilities Name",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete reservation?",
      deletesuccess: "Delete successful",
    },
    inputroomreservation: {
      title: "Room Reservation",
      fieldphoneno: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editroomreservation: {
      title: "Room Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editforweddingreservation: {
      title: "Wedding Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Facilities",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editnonweddingreservation: {
      title: "Facilities Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listactivitycategory: {
      title: "Activity Category",
      columnname: "Activity Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete activity category?",
      deletesuccess: "Delete successful",
    },
    inputactivitycategory: {
      title: "Activity Category",
      fieldname: "Activity Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editactivitycategory: {
      title: "Activity Category",
      fieldname: "Activity Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listactivity: {
      title: "Activity",
      columnname: "Activity Name",
      columncategory: "Activity Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete activity?",
      deletesuccess: "Delete successful",
    },
    inputactivity: {
      title: "Activity",
      fieldname: "Activity Name",
      fieldcategory: "Activity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Activity Category",
      modalfieldname: "Activity Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editactivity: {
      title: "Activity",
      fieldname: "Activity Name",
      fieldcategory: "Activity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldprice: "Price",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Activity Category",
      modalfieldname: "Activity Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listactivityreservation: {
      title: "Activity Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnactivity: "Activity",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete activity reservation?",
      deletesuccess: "Delete successful",
    },
    inputactivityreservation: {
      title: "Activity Reservation",
      fieldphoneno: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldactivity: "Activity",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editactivityreservation: {
      title: "Activity Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldactivity: "Activity",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listorder: {
      title: "Online Store Order",
      columnname: "User",
      columnaddress: "Address",
      columntotal: "Total Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete this order?",
      deletesuccess: "Delete successful",
      alertpaid: "This order has been paid",
      alertcancelled: "This order has been cancelled",
      confirmpayment: "Mark this order as paid?",
      paymentsuccess: "This order has been marked as paid",
    },
    inputorder: {
      title: "Online Store Order",
      fieldphoneno: "User",
      fieldlabel: "Deliver to",
      fieldaddress: "Address",
      fieldtotal: "Total Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editorder: {
      title: "Online Store Order",
      fieldphoneno: "User",
      fieldlabel: "Deliver to",
      fieldaddress: "Address",
      fieldtotal: "Total Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      orderdetail: "Order Detail",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      columnmerchant: "Merchant",
      columncommodity: "Commodity",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldmerchant: "Merchant",
      fieldcommodity: "Commodity",
      fieldprice: "Price",
      fieldquantity: "Quantity",
      modaltitle: "Edit Order Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete order detail?",
      deletesuccess: "Delete successful",
    },
    listuservehicle: {
      title: "User Vehicle",
      columnname: "User",
      columnplate: "Plate No",
      columntype: "Vehicle Type",
      columnaction: "Action",
      confirmdelete: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
    },
    inputuservehicle: {
      title: "User Vehicle",
      fieldphoneno: "User",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edituservehicle: {
      title: "User Vehicle",
      fieldphoneno: "User",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listparking: {
      title: "Parking",
      columnname: "User",
      columnuservehicle: "User Vehicle",
      columnstartdate: "Start Date",
      columnenddate: "End Date",
      columnstarttime: "Start Time",
      columnendtime: " End Time",
      columnamount: "Amount",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete parking?",
      deletesuccess: "Delete successful",
    },
    inputforweddingcategory: {
      title: "Facilities Category",
      fieldname: "Facilities Category Name",
      fieldusefor: "Facilities Use",
      fieldavailable: "Is Available",
      fieldlocation: "Location",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editforweddingcategory: {
      title: "Wedding Category",
      fieldname: "Wedding Category Name",
      fieldlocation: "Location",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listforweddingcategory: {
      title: "Facilities Category",
      columnname: "Facilities Category Name",
      columnuse: "Use For",
      columnlocation: "Location",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room category?",
      deletesuccess: "Delete successful",
    },
    listforwedding: {
      title: "Wedding Bookings",
      columnname: "Banquet Facilities Name",
      columncategory: "Facilities Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    listnonwedding: {
      title: "Non Wedding Bookings",
      columnname: "Banquet Facilities Name",
      columncategory: "Facilities Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    inputparking: {
      title: "Parking",
      fieldphoneno: "User",
      fielduservehicle: "User Vehicle",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldamount: "Amount",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editparking: {
      title: "Parking",
      fieldphoneno: "User",
      fielduservehicle: "User Vehicle",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldamount: "Amount",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listuservisit: {
      title: "User Visit",
      columnhost: "Host",
      columnhostaddress: "Host Address",
      columnvisit: "Visit Date",
      columnleave: "Leave Date",
      columnvisitor: "Visitor",
      columnvisitorcontact: "Visitor Contact",
      columnremark: "Remark",
      columnaction: "Action",
      confirmdelete: "Delete user visit?",
      deletesuccess: "Delete successful",
    },
    inputuservisit: {
      title: "User Visit",
      fieldhost: "Host",
      fieldhostaddress: "Host Address",
      fieldvisit: "Visit Date",
      fieldleave: "Leave Date",
      fieldvisitor: "Visitor",
      fieldvisitoric: "ID",
      fieldvisitorname: "Visitor Name",
      fieldvisitorpic: "Visitor Picture",
      fieldvisitorcontact: "Visitor Contact",
      fieldremark: "Remark",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    edituservisit: {
      title: "User Visit",
      fieldhost: "Host",
      fieldhostaddress: "Host Address",
      fieldvisit: "Visit Date",
      fieldleave: "Leave Date",
      fieldvisitor: "Visitor",
      fieldvisitoric: "ID",
      fieldvisitorname: "Visitor Name",
      fieldvisitorpic: "Visitor Picture",
      fieldvisitorcontact: "Visitor Contact",
      fieldremark: "Remark",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listcallcenter: {
      title: "Call Center",
      columnname: "Call Center Name",
      columnphone: "Phone Number",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete call center?",
      deletesuccess: "Delete successful",
    },
    inputcallcenter: {
      title: "Call Center",
      fieldname: "Call Center Name",
      fieldphone: "Phone Number",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcallcenter: {
      title: "Call Center",
      fieldname: "Call Center Name",
      fieldphone: "Phone Number",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdirectorycategory: {
      title: "Directory Category",
      columnname: "Directory Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete directory category?",
      deletesuccess: "Delete successful",
    },
    inputdirectorycategory: {
      title: "Directory Category",
      fieldname: "Directory Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdirectorycategory: {
      title: "Directory Category",
      fieldname: "Directory Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdirectory: {
      title: "Directory",
      columnname: "Directory  Name",
      columncategory: "Directory Category",
      columnaddress: "Address",
      columnwebsite: "Website",
      columnphone: "Phone Number",
      columnfacebook: "Facebook",
      columntwitter: "Twitter",
      columninstagram: "Instagram",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete directory?",
      deletesuccess: "Delete successful",
    },
    inputdirectory: {
      title: "Directory",
      fieldlocation: "Location",
      fieldname: "Directory Name",
      fieldcategory: "Directory Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdirectory: {
      title: "Directory",
      fieldlocation: "Location",
      fieldname: "Directory Name",
      fieldcategory: "Directory Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listfoodgarden: {
      title: "Food Garden",
      columnname: "Food Garden  Name",
      columncategory: "Food Garden Category",
      columnaddress: "Address",
      columnphone: "Phone Number",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete Food Garden?",
      deletesuccess: "Delete successful",
    },
    inputfoodgarden: {
      title: "Food Garden",
      fieldlocation: "Location",
      fieldname: "Food Garden Name",
      fieldcategory: "Directory Category",
      fieldaddress: "Address",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      modalcancel: "Cancel",
      modalsubmit: "Submit",
      fieldtags: "Tags",
      modalfieldname: "Direct Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editfoodgarden: {
      title: "Food Garden",
      fieldlocation: "Location",
      fieldname: "Food Garden Name",
      fieldcategory: "Food Garden Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listfoodorder: {
      title: "Food Order",
      columnprice: "Total Price",
      columnstatuspay: "Status Payment",
      columnname: "User",
      columnphone: "Phone Number",
      columncategory: "Merchan Name",
      columnrequestdate: "Request Date",
      columnnote: "Note",
      columncustomerid: "Customer ID",
      columnassigned: "Assigned People",
      columnstatus: "Status Order",
      columnteknisi: "Driver",
      columnaction: "Action",
      confirmdelete: "Delete user food order?",
      deletesuccess: "Delete successful",
    },
    inputfoodorder: {
      title: "Food Order",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editfoodorder: {
      title: "Food Order",
      fieldphoneno: "User Order",
      fieldcategory: "Merchant Name",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date Order",
      fieldnote: "Note",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      userservicedetail: "User Order List",
      columnservicecenter: "Order Name",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldservicecenter: "Service Center",
      fieldquantity: "Quantity",
      modaltitle: "Edit User Service Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete user service detail?",
      deletesuccess: "Delete successful",
    },

    listcomplaintform: {
      title: "Complaint Covid-19",
      complaintname: "Complaint Name",
      complaintdesc: "Description Detail",
      tanggalcomplaint: "Complaint Date",
      phonenumber: "Phone Number",
      nama: "Nama User",
      statuscomplaint: "Status Complaint",
      columnaction: "Action",
      confirmdelete: "Delete user complaint?",
      deletesuccess: "Delete successful",
    },

    detailcomplaintform: {
      title: "Complaint Covid-19",
      complaintname: "Complaint Name",
      complaintdesc: "Description Detail",
      tanggalcomplaint: "Complaint Date",
      phonenumber: "Phone Number",
      nama: "Nama User",
      statuscomplaint: "Status Complaint",
      columnaction: "Action",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },

    listhealthdeclaration: {
      title: "Daily Declaration",
      name: "Name",
      date: "Tanggal",
      phonenumber: "No. Hp",
      company: "Nama Perusahaan",
      cluster: "Cluster",
      city: "City",
      combo1: "Apakah Anda adalah termasuk dalam kelompok berikut?",
      combo2: "Adakah anda batuk, sesak dada, demam, lelah, pilek, diare?",
      combo3:
        "Jika anda mempunyai gejala pada pertanyaan no 2 diatas, apakah anda sebelumnya bertemu saudara atau teman?",
      combo4: "Sudahkah Anda menghubungi orang-orang berikut pada hari itu?",
      note: "Note",
      export: "Export",
    },

    listreturntrip: {
      title: "Return Trip",
      columnname: "Name",
      columncompany: "Nama Perusahaan",
      columncluster: "Cluster",
      columnphone: "No. Hp",
      columndate: "Tangga;",
      columnoffice: "Lokasi Kantor",
      columnbacktoofficehour: "Waktu kembali ke kantor",
      columnstartworkinghour: "Mulai Jam Kerja (Termasuk Remote Office)",
      columndeparture: "Titik Awal Keberangkatan",
      columnarrival: "Titik Kedatangan",
      columntransporation: "Transfortasi yang di anda gunakan",
      columnflightnumber: "Nomor plat transfortasi umum",
      columnhubei: "Apakah melewati area PSBB?",
      columnnote: "Note",
      columnaction: "Action",
      deletesuccess: "Delete successful",
      confirmdelete: "Do you want to delete ?",
      export: "Export",
    },
    listspringfestival: {
      title: "Return Mudik",
      columnname: "Nama",
      columncompany: "Nama Perusahaan",
      columncluster: "Cluster",
      columnphone: "No. Hp",
      columndate: "Tanggal",
      columnoffice: "Lokasi Kantor",
      columncity: "Kota yang dikunjungi",
      columncombo1:
        "Apakah Anda pernah ke atau melalui area PSBB selama dua minggu ini?",
      columncombo2:
        "Sudahkah Anda menghubungi orang-orang yang telah mengunjungi atau melewati area PSBB dalam dua minggu terakhir?",
      columncombo3:
        "Apakah kerabat atau teman mengalami demam, kelelahan, pilek, diare, batuk, sesak dada, dalam dua minggu terakhir?",
      columncombo4:
        "Pernahkah Anda mengunjungi dokter dalam dua minggu terakhir?",
      columncombo5: "Jika ada perawatan medis",
      columnnote: "Note",
      columnaction: "Action",
      deletesuccess: "Delete successful",
      confirmdelete: "Do you want to delete ?",
      export: "Export",
    },
    listhomebannerpage: {
      title: "Home Banner",
      columnname: "Name",
      columnbannerpic: "Banner Picture",
      columnlink: "Link",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete banner?",
      deletesuccess: "Delete successful",
    },
    inputhomebannerpage: {
      title: "Input Home Banner",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edithomebannerpage: {
      title: "Edit Home Banner",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listwfh: {
      title: "WFH (Work From Home)",
      messagenumber: "News Number",
      columntitle: "Title",
      columncommunity: "Community",
      columnavailable: "Status",
      columnaction: "Action",
      confirmdelete: "Are you sure you want to delete?",
      deletesuccess: "Delete success",
      newscategory: "News category",
      releasetime: "Release date",
      publisher: "Publisher",
      pinned: "Pinned state",
      displaystate: "Display state",
      reading: "Reading",
      approvalstatus: "Audit status",
      time: "Time",
    },
    inputwfh: {
      title: "WFH (Work From Home)",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    editwfh: {
      title: "WFH (Work From Home)",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
  },
};

export function getLanguage(country, page) {
  return Languages[country][page];
}
