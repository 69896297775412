import React, { Component } from "react";
import { MqttProvider } from "./App/Contexts/MqttProvider";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faTrash,
  faCaretDown,
  faFile,
  faPlus,
  faSearch,
  faPlusSquare,
  faSync,
  faRandom,
  faTimesCircle,
  faCheckSquare,
  faCheckCircle,
  faPenSquare,
  faFileExcel,
  faFileExport,
  faSave,
  faChevronCircleLeft,
  faPaperPlane,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import Login from "./App/Pages/Login/Login";
import Default from "./App/Default";
// import toast, { Toaster } from "react-hot-toast";

// import {
//   generateToken,
//   messaging,
// } from "./App/Components/Notifications/firebase";
// import { onMessage } from "firebase/messaging";

library.add(
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faTrash,
  faCaretDown,
  faFile,
  faPlus,
  faSearch,
  faPlusSquare,
  faSync,
  faRandom,
  faTimesCircle,
  faCheckSquare,
  faCheckCircle,
  faPenSquare,
  faFileExcel,
  faFileExport,
  faSave,
  faChevronCircleLeft,
  faPaperPlane,
  faLink
);

class App extends Component {
  // componentDidMount() {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log("payload: ", payload);
  //     toast(
  //       (t) => (
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <div>
  //             <strong style={{ fontSize: "16px" }}>
  //               {payload.notification.title}
  //             </strong>

  //             <br />
  //             <span>{payload.notification.body}</span>
  //           </div>
  //         </div>
  //       ),
  //       {
  //         duration: 5000,
  //         style: {
  //           background: "#fdf9e8",
  //           color: "#000",
  //         },
  //         icon: "🔔",
  //       }
  //     );
  //   });
  // }

  render() {
    return (
      // <div>
      <MqttProvider>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/panel" component={Default} />
        </Switch>
      </MqttProvider>

      /* <Toaster position="bottom-right" reverseOrder={false} /> */
      /* </div> */
    );
  }
}

export default App;
