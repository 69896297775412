import axios from "axios";
import { webserviceurl } from "./baseUrl";

export function apiAccountTradingList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_trading_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountListActive() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_list_actived.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountListAdjustment() {
  // const v1 = "/accountMt4_list_adjustment.php"
  const v2 = "/accountMt4_list_adjustment_v2.php";
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + v2,
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountListArchived() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_list_archived.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountDelete(code_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMT4_delete.php",
      data: { code_id: code_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUnlink(account_number, vps_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMT4_unlink.php",
      data: { account_number: account_number, vps_id: vps_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDetail(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_detail.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDelete(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_delete.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountAvailable() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_available.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/groupList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiViewList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/viewList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAddManageApi(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_manage_api.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactDelete(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_delete.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGrabContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/grab_contact_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupId(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_group_id.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactListNoLimit(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_list_nolimit.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupIdNoLimit(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_group_id_nolimit.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDelete(contact_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_delete.php",
      data: { contact_id: contact_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegramDelete(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_delete.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiAccount() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_join.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupList(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/join_group_list.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupDelete(telegram_group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_delete.php",
      data: { telegram_group_id: telegram_group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiImportContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/import_contact.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDelete(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_delete.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_telegram.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupTelegramAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/add_telegram_account_group.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountBanned(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_banned.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountSession(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_session.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountFlood(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_flood.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxInvite(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_maxinvite.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_maxdate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateNormalAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_normal.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountActive(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_active.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsDelete(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_delete.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardVps() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_vps.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUpdateAlias(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_reupdate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTelegramAccountGroupList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTelegramAccountGroupInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountOptionAll() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_option_all.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalanceDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalance() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalanceDateByAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance_date_byAgent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalanceByAgent(agent_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance_byAgent.php",
      data: { agent_id: agent_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveDelete(masterId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_delete.php",
      data: { masterId: masterId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSlaveList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/slave_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSlaveListMaster() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/slave_list_master.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveFixVpsMonitor(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_fix_vps_monitor.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveFixVpsMonitorAuto(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_fix.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionClearVps(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_clear_vps.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionRestart(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_restart.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionReset(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_reset.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsSetPort(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_set_port.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_account.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitAccountType(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_account_type.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfit() {
  // const v1 = "/daily_profit.php";
  const v2 = "/daily_profit_v2.php";
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + v2,
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitNoHistory() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_no_history.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyWithdrawalDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_withdrawal_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyWithdrawalAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_withdrawal_account.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyWithdrawalAccountType(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_withdrawal_account_type.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyWithdrawal() {
  // const v1 = "/daily_withdrawal.php";
  const v2 = "/daily_withdrawal_v2.php";
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + v2,
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyDeposit() {
  // const v1 = "/daily_deposit.php";
  const v2 = "/daily_deposit_v2.php";
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + v2,
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyDepositDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_deposit_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyDepositAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_deposit_account.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAgentList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/agent_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAgentInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/agent_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAgentUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/agent_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAgentDelete(agent_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/agent_delete.php",
      data: { agent_id: agent_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceAgentList(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_agent_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceAgentCreated(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_agent_created.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceAgentGenerate() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_agent_generate.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceAgentEdit(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_agent_edit.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceAgentDelete(invoice_number) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_agent_delete.php",
      data: { invoice_number: invoice_number },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

//
export function apiInvoiceMemberList(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_member_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceMemberCreated(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_member_created.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceMemberEdit(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_member_edit.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInvoiceMemberDelete(invoice_number) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/invoice_member_delete.php",
      data: { invoice_number: invoice_number },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiRateOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/rate_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountMt4UpdateRate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_update_rate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountMt4UpdateTypeCurrency(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_update_type.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiRateList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/rate_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiRateInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/rate_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiRateUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/rate_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiRateDelete(rate_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/rate_delete.php",
      data: { rate_id: rate_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountMt4_ByAgent(agent_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_byAgent.php",
      data: { agent_id: agent_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitByAgent(agent_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_byAgent.php",
      data: { agent_id: agent_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitDateByAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_profit_date_byAgent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSettingPassword(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/setting_password.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMonthlyAllProfit(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_profit_monthly.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyCutLossDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_cut_loss_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyCutLossAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_cut_loss_account.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyCutLossAccountType(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_cut_loss_account_type.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyCutLoss() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_cut_loss.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyProfitAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_daily_profit_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMonthlyProfitAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_monthly_profit_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiYearlyProfitAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_yearly_profit_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardTakeProfit() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_take_profit.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardDailyProfitAdmin() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_daily_profit_admin.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardDailyWdAdmin() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_daily_wd_admin.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardDailyDepositAdmin() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_daily_deposit_admin.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardDailyEquityBalanceAdmin() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_daily_equity_admin.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardSumDeposit() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_sum_deposit.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardSumProfit() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_sum_profit.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardSumWithdrawal() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_sum_withdrawal.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardSumInitialDeposit() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_sum_initial_deposit.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPerformanceProfit() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/performance_profit_agent.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTradeChartList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/trade_chart_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardTotalAccountActive() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_active_total.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSubmissionFormList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/submission_form_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSubmissionFormUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/submission_form_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSubmissionAccountAddAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/submission_account_add_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiSubmissionFormLog() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/submission_form_log.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteLabelCreate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/white_label_clients.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteLabelList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/white_label_client_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteLabelDelete(id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/white_label_client_delete.php",
      data: { id: id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteLabelUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/white_label_client_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountDetail(account_number) {
  // const v1 = "/accountMt4_detail.php";
  const v2 = "/accountMt4_detail_v2.php";
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + v2,
      data: { account_number: account_number },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUpdateData(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_update_data.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCountJobList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/count_joblist.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsOptionAvailable() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_option_available.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountNullOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_null_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsAddAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_add_vps.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionAutoLogin(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_login.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionClearMT(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_clearMT.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionChangeLot(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_change_lot.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionAutoLogout(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_logout.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionAutoReActive(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_reactive.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionAutoClose(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_close.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAutomationLog() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/automation_log.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAutomationissues() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/automation_issues.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionResend(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_resend_ticket.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTokenNotif(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/token_notif.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAgentOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/agent_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterSlaveOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_slave_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUserOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/user_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTradingView(interval) {
  try {
    const response = axios({
      method: "get",
      url: `${webserviceurl}/trading_view.php?interval=${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTradingViewV2(interval, symbol) {
  try {
    const response = axios({
      method: "get",
      url: `${webserviceurl}/trading_view_v2.php?interval=${interval}&symbol=${symbol}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOrderTradingToday() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/order_trading_today.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOrderTradingDaily() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/order_trading_daily.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOrderTradingHourly() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/order_trading_hourly.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOrderTradingRangeHourly() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/order_trading_range_hourly.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOrderTradingMonthly() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/order_trading_monthly.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountAutoOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_auto_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAutoActionSubmission(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/auto_action_submission.php",
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAutoActionSubmissionUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/auto_action_submission_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAutoReject(account_number) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/auto_rejected.php",
      data: { account_number: account_number },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMigrationInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/migration_vps_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMigrationList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/migration_vps_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDetailId(vps_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_detail_id.php",
      data: { vps_id: vps_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOptionAuto() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_option_auto.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOptionManual() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_option_manual.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsOptionSelected() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_option_selected.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionMaster(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_master.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiActionClearLogin(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/action_clear_login.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMarketOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/market_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiMasterUpdateMarket(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/master_update_market.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
