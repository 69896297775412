import React, { Component } from "react";
import { Input } from "reactstrap";
import axios from "axios";
import SelectMultiColumn from "../../Components/SelectMultiColumn/SelectMultiColumn";
import { serverUrl } from "../../../config.js";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { ArrowBackIos, Cancel, Save, Close } from "@mui/icons-material";
import ButtonUI from "@mui/material/Button";
import {
  Typography,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Stack,
  Alert,
  IconButton,
  Slide,
} from "@mui/material";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const stylesListDialog = {
//   inline: {
//     display: "inline",
//   },
// };

class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.globallang = getLanguage(activeLanguage, "global");
    this.language = getLanguage(activeLanguage, "editadmin");
    this.state = {
      phoneno: props.match.params.phoneno,
      name: "",
      logintype: "",
      profilepic: [],
      email: "",
      issuspend: 0,
      dataShow: [],
      communityList: [],
      MerchantShow: [],
      suspendShow: [
        { issuspendId: 0, issuspendName: "No" },
        { issuspendId: 1, issuspendName: "Yes" },
      ],
      typeLoginOption: [],
      typeLoginSelected: null,
      dataAgent: [],
      agentSelected: null,
      accountSelected: null,
      accountOption: [],
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      accountAllOption: [],
    };
  }

  selectRole = (dataShow) => {
    axios
      .post(
        serverUrl + "cp_get_logincptype_list.php",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.status === "ok") {
          if (data.records.length > 0) {
            this.setState({
              typeLoginOption: data.records,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  doApiAgent = () => {
    axios
      .post(
        serverUrl + "agent_list.php",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const newArray = data.records.map((item) => ({
              label: item.agent_name,
              value: item.agent_id,
              infoAccount: item.infoAccount,
              shared_profit: item.shared_profit,
            }));

            this.setState({
              dataAgent: newArray,
            });
          }
        }

        this.doGetData();
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  doApiAccount = () => {
    // this.props.doLoading();
    axios
      .post(
        serverUrl + "accountMt4_option.php",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response) => {
        this.doApiAgent();
        // this.props.doLoading();
        const data = response.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              accountOption: data.records,
              accountAllOption: data.records,
            });
          }
        }
      })
      .catch((error) => {
        // this.props.doLoading();
        console.log(error);
        alert(error);
      });
  };

  suspendHandleChange = (issuspend) => {
    this.setState({
      issuspend: issuspend,
    });
  };

  checkData = () => {
    const {
      phoneno,
      name,
      typeLoginSelected,
      agentSelected,
      accountSelected,
      email,
      password,
      confirmpass,
    } = this.state;

    if (phoneno === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty phone number?",
        titleBody: "Enter phone number",
      });
    } else if (name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty name?",
        titleBody: "Enter name",
      });
    } else if (typeLoginSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty type?",
        titleBody: "Select type",
      });
    } else if (
      typeLoginSelected !== null &&
      typeLoginSelected.value === 2 &&
      agentSelected === null
    ) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty agent?",
        titleBody: "Select agent",
      });
    } else if (
      typeLoginSelected !== null &&
      typeLoginSelected.value === 3 &&
      agentSelected !== null &&
      accountSelected === null
    ) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty account number?",
        titleBody: "Select account number",
      });
    } else if (
      typeLoginSelected !== null &&
      typeLoginSelected.value === 4 &&
      agentSelected === null &&
      accountSelected === null
    ) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty account number?",
        titleBody: "Select account number",
      });
    } else if (email === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty email?",
        titleBody: "Enter email",
      });
    } else if (password === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty password?",
        titleBody: "Enter password",
      });
    } else if (confirmpass === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty confirm password?",
        titleBody: "Enter confirm password",
      });
    } else if (password !== confirmpass) {
      this.setState({
        openValidation: true,
        titleValidation: "Warning!",
        titleBody: "Password and confirm password do not match.",
      });
    } else {
      this.onSubmit();
    }
  };

  componentDidMount = (dataShow) => {
    this.selectRole(dataShow);

    this.doApiAccount();
    this.props.doLoading();
  };

  doGetData = () => {
    axios
      .post(
        serverUrl + "admin_by_phoneno.php",
        {
          phoneno: this.state.phoneno,
        },

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((response, logintype) => {
        this.props.doLoading();

        const data = response.data;
        if (data.status === "ok") {
          const record = data.record;

          let tmp = [];
          if (record.profilepic !== "") {
            tmp.push(record.profilepic);
          }

          if (record.logintype === 1) {
            const filterType = this.state.typeLoginOption.filter(
              (obj) => obj.value === record.logintype
            );

            this.setState({ phoneno: record.phoneno });
            this.setState({ name: record.name });
            this.setState({
              typeLoginSelected: filterType[0],
            });

            this.setState({ profilepic: tmp });
            this.setState({ email: record.email });
            this.setState({ issuspend: record.suspend });
          } else if (record.logintype === 2 || record.logintype === 3) {
            const filterType = this.state.typeLoginOption.filter(
              (obj) => obj.value === record.logintype
            );

            //console.log("filterType: ", filterType);

            const agentId = record.agent === "" ? "" : record.agent.id;

            const filterAgent = this.state.dataAgent.filter(
              (obj) => obj.value === agentId
            );

            //console.log("filterAgent: ", filterAgent);

            const acctInfo = filterAgent.length > 0 ? filterAgent[0] : [];

            //console.log("acctInfo: ", acctInfo);

            const newAccountOption = acctInfo.infoAccount.map((obj) => ({
              value: obj.account_number,
              label: `${obj.account_number}(${obj.alias})`,
            }));

            //console.log("newAccountOption: ", newAccountOption);

            this.setState({
              accountOption: newAccountOption,
            });

            const dataInfoAccount = record.infoAccount;

            //console.log("dataInfoAccount: ", dataInfoAccount);

            const filteredAccounts = acctInfo.infoAccount.filter((account) => {
              return dataInfoAccount.some(
                (info) => info.account.toString() === account.account_number
              );
            });

            //console.log("filteredAccounts: ", filteredAccounts);

            const newAccount = filteredAccounts.map((obj) => ({
              value: obj.account_number,
              label: `${obj.account_number}(${obj.alias})`,
            }));

            //console.log("newAccount: ", newAccount);

            this.setState({ phoneno: record.phoneno });
            this.setState({ name: record.name });
            this.setState({
              typeLoginSelected: filterType[0],
            });
            this.setState({
              agentSelected: filterAgent.length > 0 ? filterAgent[0] : null,
            });

            this.setState({
              accountSelected: newAccount.length > 0 ? newAccount : null,
            });
            this.setState({ profilepic: tmp });
            this.setState({ email: record.email });
            this.setState({ issuspend: record.suspend });
          } else if (record.logintype === 4) {
            const filterType = this.state.typeLoginOption.filter(
              (obj) => obj.value === record.logintype
            );

            const dataInfoAccount = record.infoAccount;

            const filteredAccounts = this.state.accountOption.filter(
              (account) => {
                return dataInfoAccount.some(
                  (info) => info.account.toString() === account.value
                );
              }
            );

            console.log("accountAllOption: ", this.state.accountAllOption);

            this.setState({ phoneno: record.phoneno });
            this.setState({ name: record.name });
            this.setState({
              typeLoginSelected: filterType[0],
            });
            this.setState({
              agentSelected: null,
            });

            this.setState({
              accountSelected:
                filteredAccounts.length > 0 ? filteredAccounts : null,
            });
            this.setState({ profilepic: tmp });
            this.setState({ email: record.email });
            this.setState({ issuspend: record.suspend });
          }
        }
      })
      .catch((error) => {
        this.props.doLoading();
      });
  };

  onSubmit = () => {
    let params = {
      phoneno: this.state.phoneno,
      name: this.state.name,
      logintype: this.state.typeLoginSelected.value,
      agent:
        this.state.agentSelected === null ? "" : this.state.agentSelected.value,
      account: this.state.accountSelected,
      email: this.state.email,
      password: this.state.password,
      suspend: this.state.issuspend,
    };
    // console.log(params);
    this.props.doLoading();
    axios
      .post(serverUrl + "admin_update.php", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        this.props.doLoading();
        this.setState({
          openSuccess: true,
        });
      })
      .catch((error) => {
        this.props.doLoading();
        console.log(error);
        alert(error);
      });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.props.history.push("/panel/user"), 1000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => this.props.history.push("/panel/user")}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.language.savesuccess}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleChangeTypeLogin = (typeLoginSelected) => {
    if (typeLoginSelected.value === 4) {
      this.doApiAccount();
    }

    this.setState({
      typeLoginSelected,
      agentSelected: null,
      accountSelected: null,
    });
  };

  handleChangeAgent = (agentSelected) => {
    // console.log(agentSelected);

    const newAccount = agentSelected.infoAccount.map((obj) => ({
      value: obj.account_number,
      label: `${obj.account_number}(${obj.alias})`,
    }));

    // console.log(newAccount);

    this.setState({
      agentSelected,
      accountOption: newAccount,
      accountSelected: null,
    });
  };

  handleChangeAccount = (accountSelected) => {
    this.setState({
      accountSelected,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const type = this.state.typeLoginSelected;
    const agent = this.state.agentSelected;
    return (
      <div>
        <div className="page-header">
          <ButtonUI
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#000",
            }}
            startIcon={<ArrowBackIos />}
            onClick={() => this.props.history.push("/panel/user")}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 12,
                color: "#fff",
                textTransform: "capitalize",
                marginLeft: -10,
              }}
            >
              Back
            </Typography>
          </ButtonUI>{" "}
          <Typography
            component="span"
            variant="h2"
            style={{
              fontSize: 16,
              color: "#000",
              fontWeight: "bold",
              textTransform: "capitalize",
              float: "right",
            }}
          >
            Edit User
          </Typography>
          <span className="dash">&nbsp;&nbsp;</span>
        </div>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {this.language.fieldphone}
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="phoneno"
                  id="phoneno"
                  placeholder="08xxxxxxxxxx"
                  value={this.state.phoneno}
                  // onChange={(event) =>
                  //   this.setState({ phoneno: event.target.value })
                  // }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  for="name"
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {this.language.fieldname}
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="name"
                  id="name"
                  value={this.state.name}
                  placeholder={this.language.fieldname}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {this.language.fieldtype}
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select type"
                  value={this.state.typeLoginSelected}
                  onChange={this.handleChangeTypeLogin}
                  options={this.state.typeLoginOption}
                />
              </Grid>

              {type !== null ? (
                type.value === 2 || type.value === 3 ? (
                  <>
                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Agent
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Select
                        classNamePrefix="select"
                        placeholder="Select agent"
                        value={this.state.agentSelected}
                        onChange={this.handleChangeAgent}
                        options={this.state.dataAgent}
                      />
                    </Grid>
                  </>
                ) : null
              ) : null}

              {type !== null && agent !== null ? (
                type.value === 3 ? (
                  <>
                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Account
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Select
                        isMulti
                        classNamePrefix="select"
                        className="basic-multi-select"
                        value={this.state.accountSelected}
                        onChange={this.handleChangeAccount}
                        options={this.state.accountOption}
                        isClearable
                      />
                    </Grid>
                  </>
                ) : null
              ) : null}

              {type !== null && agent === null && type.value === 4 ? (
                <>
                  <Grid item lg={2} md={3} sm={3} xs={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Account
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={9} sm={9} xs={12}>
                    <Select
                      isMulti
                      classNamePrefix="select"
                      className="basic-multi-select"
                      value={this.state.accountSelected}
                      onChange={this.handleChangeAccount}
                      options={this.state.accountOption}
                      isClearable
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  for="name"
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {this.language.fieldemail}
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  placeholder={this.language.fieldemail}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  for="name"
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {this.language.fieldsuspend}
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <SelectMultiColumn
                  width={"100%"}
                  value={this.state.issuspend}
                  valueColumn={"issuspendId"}
                  showColumn={"issuspendName"}
                  columns={["issuspendName"]}
                  data={this.state.suspendShow}
                  onChange={this.suspendHandleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <ButtonUI
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/user")}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Cancel
              </Typography>
            </ButtonUI>{" "}
            &nbsp;&nbsp;
            <ButtonUI
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.checkData()}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Save
              </Typography>
            </ButtonUI>{" "}
          </div>
        </div>
        {this.renderValidationAlert()}
        {this.renderSuccess()}
      </div>
    );
  }
}
export default EditAdmin;
