import React, { Component } from "react";
import {
  apiAccountOption,
  apiMasterSlaveInsert,
  apiMasterSlaveList,
  apiMasterSlaveDelete,
  apiMasterSlaveDeleteAll,
  apiMarketOption,
  apiMasterUpdateMarket,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  Checkbox,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Refresh,
  Assignment,
  Delete,
  Close,
  Save,
  Cancel,
  Check,
  DeleteOutline,
  AddBox,
} from "@mui/icons-material";
import { formatNumber, uuidRandomId } from "../../../global.js";
import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class MasterSlaveSettingPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      alias: "",
      capital: 0,
      dataAccount: [],
      optionAccountNumber: [],
      optionAccountNumberAvailable: [],
      selectedAccountId: null,
      selectedAccountMultiple: null,
      selectedAccountSlave: null,
      masterId: "",
      master: "",
      submitLoading: false,
      marketOption: [],
    };

    this.tableColumns = [
      {
        Header: "Master",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },
      {
        Header: "Market",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "market",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => {
          return (
            <select
              value={e.original.market}
              onChange={(event) => {
                const updatedData = [...this.state.tableData];
                updatedData[e.index].market = event.target.value;
                this.doApiUpdateMarket(e.original);
                this.setState({ tableData: updatedData });
              }}
            >
              <option value={0}>Select Market</option>
              {this.state.marketOption.length > 0 &&
                this.state.marketOption.map((obj) => (
                  <option key={obj.value} value={obj.value}>
                    {obj.label}
                  </option>
                ))}
            </select>
          );
        },
      },
      {
        Header: "Total Slave",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "totalSlave",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.totalSlave}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },

        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadAccountOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadAccountOption = () => {
    apiAccountOption()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionAccountNumber: data.records,
              dataAccount: data.records,
            });
          } else {
            this.setState({
              optionAccountNumber: [],
            });
          }
          this.doLoadData();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    apiMarketOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              marketOption: data.records,
            });
          } else {
            this.setState({
              marketOption: [],
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  doLoadData = () => {
    this.props.doLoading();
    apiMasterSlaveList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = this.state.tableData;
            temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].totalSlave = temp[i].infoSlave.length;
            }

            const optionAccountNumber = this.state.optionAccountNumber;

            const filterData = optionAccountNumber.filter(
              ({ value: id1 }) =>
                !temp.some(({ account_number: id2 }) => id2 === id1)
            );

            // console.log(filterData);

            const dataSlave = [];
            for (var idx = 0; idx < temp.length; idx++) {
              for (var idx2 = 0; idx2 < temp[idx].infoSlave.length; idx2++) {
                dataSlave.push({
                  accountNumber: temp[idx].infoSlave[idx2].account_number,
                });
              }
            }

            // console.log(dataSlave);

            const filterDataSlave = filterData.filter(
              ({ value: id1 }) =>
                !dataSlave.some(({ accountNumber: id2 }) => id2 === id1)
            );

            // console.log(filterDataSlave);

            this.setState({
              tableData: temp,
              optionAccountNumber: filterDataSlave,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        }
      })
      .catch((err) => {
        if (this.state.tableData.length === 0) {
          this.props.doLoading();
        }
        console.log(err);
      });
  };

  doApiUpdateMarket = (item) => {
    let params = {
      master_id: item.master_id,
      market: item.market,
    };

    apiMasterUpdateMarket(params)
      .then((res) => {
        // const data = res.data;
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      masterId:
        this.state.setOpenAddNew === true
          ? uuidRandomId()
          : this.state.masterId,
      accountCode: this.state.selectedAccountId.code_id,
      accountNumber: this.state.selectedAccountId.value,
      selectedAccountMultiple:
        this.state.selectedAccountMultiple === null
          ? []
          : this.state.selectedAccountMultiple,
    };

    console.log(params);

    this.setState({
      submitLoading: true,
    });

    apiMasterSlaveInsert(params)
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseAddNew();
          // this.pressReset();
        }
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });
        console.log(err);
      });
  };

  doDeleteItem = (masterId) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiMasterSlaveDelete(masterId)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  pressDeleteAll = () => {
    // console.log(this.state.selectRowData);
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    this.setState({ showLoadingDeleteAll: true });
    apiMasterSlaveDeleteAll(params)
      .then((res) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Telegram Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete the account master?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.LoadingInvite === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove master {item.account_number}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteItem(item.master_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    console.log(row);

    console.log(this.state.dataAccount.account_number);

    const optionNumber = this.state.dataAccount.filter(
      (obj) => obj.value === row.account_number
    );

    // console.log(optionNumber);

    // console.log("option awal: ", this.state.dataAccount);

    let filterResult = this.state.dataAccount.filter(
      (obj, idx) => obj.value !== row.account_number
    );

    // console.log("option available: ", filterResult);

    const getSelected = filterResult.filter(({ value: id1 }) =>
      row.infoSlave.some(({ account_number: id2 }) => id2 === id1)
    );

    // console.log(getSelected);

    console.log("optionNumber: ", optionNumber);

    this.setState({
      setOpenEdit: true,
      masterId: row.master_id,
      master: optionNumber.length > 0 ? optionNumber[0].label : "",
      selectedAccountId: optionNumber[0],
      selectedAccountMultiple: getSelected,
      optionAccountNumberAvailable: this.state.optionAccountNumber,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
    this.doLoadAccountOption();
  };

  pressSync = () => {};

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      masterId: "",
      selectedAccountId: null,
      selectedAccountMultiple: null,
      master: "",
      optionAccountNumberAvailable: [],
    });
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== "" ? value.alias : value.phonenumber
          } -label`;

          return (
            <ListItem
              key={value.manage_id}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== "" ? value.alias : value.phonenumber
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  handleUpdateCapital = (e) => {
    var value = e.target.value;

    this.setState({
      capital: formatNumber(value),
    });
  };

  renderTransferList = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{this.customList("Available", this.state.left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{this.customList("Account List", this.state.right)}</Grid>
      </Grid>
    );
  };

  checkData = () => {
    if (this.state.selectedAccountId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Master?",
        titleBody: "Select a master",
      });
    }
    // else if (this.state.selectedAccountMultiple === null) {
    //   this.setState({
    //     openValidation: true,
    //     titleValidation: "Empty Slave?",
    //     titleBody: "Select a slave",
    //   });
    // }
    else {
      this.doSubmit();
    }
  };

  handleChangeAccount = (selectedAccountId) => {
    this.setState({
      selectedAccountId: selectedAccountId,
    });

    // console.log("option awal: ", this.state.optionAccountNumber);

    let filterResult = this.state.optionAccountNumber.filter(
      (obj, idx) => obj.value !== selectedAccountId.value
    );

    // console.log("option available: ", filterResult);
    this.setState({
      optionAccountNumberAvailable: filterResult,
    });
  };

  handleChangeAccountMultiple = (value) => {
    const selectedAccount =
      this.state.selectedAccountMultiple === null
        ? []
        : this.state.selectedAccountMultiple;
    const updatedAccounts =
      value !== null ? value.concat(selectedAccount) : null;

    const updatedOptions = this.state.optionAccountNumberAvailable.filter(
      (option) => !value.includes(option)
    );

    this.setState({
      selectedAccountMultiple: updatedAccounts,
      selectedAccountSlave: null,
      optionAccountNumberAvailable: updatedOptions,
    });
  };

  doRemoveList = (item) => {
    // console.log("item: ", item);
    // console.log(
    //   "selectedAccountMultiple: ",
    //   this.state.selectedAccountMultiple
    // );
    // console.log(
    //   "optionAccountNumberAvailable: ",
    //   this.state.optionAccountNumberAvailable
    // );

    const updatedOptions = this.state.selectedAccountMultiple.filter(
      (option) => item.value !== option.value
    );

    const getToAvailable = this.state.selectedAccountMultiple.filter(
      (option) => item.value === option.value
    );

    // console.log("getToAvailable: ", getToAvailable);

    const combinationAvailable = getToAvailable.concat(
      this.state.optionAccountNumberAvailable
    );

    // console.log("combinationAvailable: ", combinationAvailable);

    this.setState({
      selectedAccountMultiple: updatedOptions,
      optionAccountNumberAvailable: combinationAvailable,
    });
  };

  renderDialogAddNew = () => {
    const dataTableAccountSlave =
      this.state.selectedAccountMultiple === null
        ? []
        : this.state.selectedAccountMultiple;

    // console.log("dataTableAccountSlave: ", dataTableAccountSlave);
    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullScreen
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Master Slave"
                : this.state.setOpenEdit === true
                ? "Edit Master Slave"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12} sx={22}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Master
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12} sx={12}>
                {this.state.setOpenAddNew === true ? (
                  <>
                    <Select
                      classNamePrefix="select"
                      placeholder="Select a account"
                      value={this.state.selectedAccountId}
                      onChange={this.handleChangeAccount}
                      options={this.state.optionAccountNumber}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      autoComplete="off"
                      type="text"
                      name="maseter"
                      id="maseter"
                      value={this.state.master}
                    />
                  </>
                )}
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12} sx={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Slave
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12} sx={12}>
                <Select
                  isMulti
                  isClearable={false}
                  classNamePrefix="select"
                  className="basic-multi-select"
                  placeholder="Select a account"
                  value={this.state.selectedAccountSlave}
                  onChange={this.handleChangeAccountMultiple}
                  options={this.state.optionAccountNumberAvailable}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={12}>
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={dataTableAccountSlave}
                  columns={[
                    {
                      Header: "Account",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "value",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      Cell: (e) => (
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {e.original.value}
                        </Typography>
                      ),
                    },
                    {
                      Header: "Alias",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "alias",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      Cell: (e) => (
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {e.original.alias}
                        </Typography>
                      ),
                    },
                    {
                      Header: "Action",
                      headerStyle: { fontWeight: "bold", fontSize: 14 },
                      accessor: "",
                      style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },

                      width: 300,
                      Cell: (e) => (
                        <div>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<Delete />}
                            onClick={() => this.doRemoveList(e.original)}
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Remove
                            </Typography>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={defaultFilterMethod}
                  defaultPageSize={500}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  showPagination={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Master Slave Setting
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Create Slave
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>

                      {this.state.selectRowData.length > 0 ? (
                        <div style={{ marginRight: 0, marginLeft: 16 }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<DeleteOutline />}
                            onClick={() =>
                              this.setState({ openAlertDeleteAll: true })
                            }
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Delete
                            </Typography>
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}

        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default MasterSlaveSettingPage;
