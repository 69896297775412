import React, { Component, createRef } from "react";
import { MqttContext } from "../../Contexts/MqttProvider.js";
import Chart from "react-apexcharts";
import Select from "react-select";
import dayjs from "dayjs";
import { apiTradingViewV2 } from "../../Service/api";
import {
  ButtonGroup,
  Button,
  Grid,
  Paper,
  Typography,
  Box,
} from "@mui/material";

class TradeChart extends Component {
  constructor(props) {
    super(props);
    this.mqttRef = React.createRef();
    this.state = {
      highest: 0,
      lowest: 0,
      interval: "M5",
      topicTradeChart: "TradechartV2",
      topicTradeData: "TradedataV2",
      minX: null,
      maxX: null,
      symbolOption: [
        { value: "EURUSD", label: "EURUSD" },
        { value: "BTCUSDc", label: "BTCUSDc" },
      ],
      dataTradeChart: { data: [] },
      currentPriceBuy: null,
      currentPriceSell: null,
      takeProfit: [],
      groupOrders: [],
    };

    this.isSubscribed = false;

    this.intervalRef = createRef();
    this.intervalRef.current = "M5";

    this.symbolRef = createRef();
    this.symbolRef.current = { value: "EURUSD", label: "EURUSD" };
  }

  static contextType = MqttContext;

  componentDidMount() {
    // console.log("componentDidMount - tested");

    this.doLoadTradingViewChart(
      this.intervalRef.current,
      this.symbolRef.current.value
    );
    this.updateTradeTick();
    this.updateChartData();
    this.checkMqttConnection();
  }

  componentWillUnmount() {
    const { unsubscribeFromTopic } = this.context;
    const { topicTradeChart, topicTradeData } = this.state;

    if (this.isSubscribed) {
      unsubscribeFromTopic(
        `${topicTradeData}/+/${this.symbolRef.current.value}`
      );
      unsubscribeFromTopic(
        `${topicTradeChart}/${this.symbolRef.current.value}/${this.intervalRef.current}`
      );
      this.isSubscribed = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      messagesTradeDataEURUSD,
      messagesTradeChartEURUSD,
      messagesTradeDataBTCUSDc,
      messagesTradeChartBTCUSDc,
    } = this.context;

    // console.log(
    //   "shouldComponentUpdate - messagesTradeDataBTCUSDc: ",
    //   messagesTradeDataBTCUSDc
    // );

    return (
      nextContext.messagesTradeDataEURUSD !== messagesTradeDataEURUSD ||
      nextContext.messagesTradeChartEURUSD !== messagesTradeChartEURUSD ||
      nextContext.messagesTradeDataBTCUSDc !== messagesTradeDataBTCUSDc ||
      nextContext.messagesTradeChartBTCUSDc !== messagesTradeChartBTCUSDc
    );
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      messagesTradeDataEURUSD,
      messagesTradeChartEURUSD,
      messagesTradeDataBTCUSDc,
      messagesTradeChartBTCUSDc,
      isConnected,
    } = this.context || {};

    // console.log(
    //   "componentDidUpdate - messagesTradeChartBTCUSDc: ",
    //   messagesTradeChartBTCUSDc
    // );

    if (prevProps.isConnected !== isConnected) {
      this.checkMqttConnection();
    }

    if (
      prevState.messagesTradeChartEURUSD !== messagesTradeChartEURUSD ||
      prevState.messagesTradeChartBTCUSDc !== messagesTradeChartBTCUSDc
    ) {
      // this.updateChartData();

      setTimeout(() => {
        this.updateChartData();
      }, 10000);
    }

    if (
      prevState.messagesTradeDataEURUSD !== messagesTradeDataEURUSD ||
      prevState.messagesTradeDataBTCUSDc !== messagesTradeDataBTCUSDc
    ) {
      // this.updateTradeTick();
      setTimeout(() => {
        this.updateTradeTick();
      }, 10000);
    }
  }

  getTradeChartMsg = () => {
    const { messagesTradeChartEURUSD, messagesTradeChartBTCUSDc } =
      this.context;
    return this.symbolRef.current.value === "EURUSD"
      ? messagesTradeChartEURUSD
      : messagesTradeChartBTCUSDc;
  };

  updateChartData = () => {
    const tradeChartMsg = this.getTradeChartMsg();

    if (
      tradeChartMsg &&
      tradeChartMsg.time_of_bar &&
      tradeChartMsg.open !== undefined &&
      tradeChartMsg.high !== undefined &&
      tradeChartMsg.low !== undefined &&
      tradeChartMsg.close !== undefined
    ) {
      const newDataPoint = {
        x: tradeChartMsg.time_of_bar,
        y: [
          tradeChartMsg.open,
          tradeChartMsg.high,
          tradeChartMsg.low,
          tradeChartMsg.close,
        ],
      };

      // Calculate the current high and low
      const currentHigh = Math.max(...newDataPoint.y);
      const currentLow = Math.min(...newDataPoint.y);

      // Update the highest and lowest values in the state
      this.setState((prevState) => ({
        highest: Math.max(prevState.highest, currentHigh),
        lowest: Math.min(prevState.lowest, currentLow),
      }));

      // Update the dataTradeChart with the new data point
      this.setState((prevState) => {
        const dataArray = prevState.dataTradeChart.data || [];

        const index = dataArray.findIndex(
          (point) => point.x === newDataPoint.x
        );
        const updatedData =
          index !== -1
            ? [
                ...dataArray.slice(0, index),
                newDataPoint,
                ...dataArray.slice(index + 1),
              ]
            : [...dataArray, newDataPoint];

        return {
          dataTradeChart: { ...prevState.dataTradeChart, data: updatedData },
        };
      });
    }
  };

  getTradeTick = () => {
    const { messagesTradeDataEURUSD, messagesTradeDataBTCUSDc } = this.context;
    return this.symbolRef.current.value === "EURUSD"
      ? messagesTradeDataEURUSD
      : messagesTradeDataBTCUSDc;
  };

  updateTradeTick = () => {
    const tradeTick = this.getTradeTick();

    // console.log("tradeTick: ", tradeTick);

    if (tradeTick.priceBuy && tradeTick.priceSell) {
      const { currentPriceBuy, currentPriceSell } = this.state;
      if (
        tradeTick.priceBuy !== currentPriceBuy ||
        tradeTick.priceSell !== currentPriceSell
      ) {
        // console.log("tradeTick.priceBuy: ", tradeTick.priceBuy);
        // console.log("tradeTick.priceSell: ", tradeTick.priceSell);

        this.setState({
          currentPriceBuy: tradeTick.priceBuy,
          currentPriceSell: tradeTick.priceSell,
        });
      }
    }

    if (tradeTick.groupOrders) {
      const takeProfits = tradeTick.groupOrders.map((item) => item.takeProfit);
      this.setState({ takeProfit: takeProfits });

      const groupOrder = tradeTick.groupOrders.map((item) => ({
        y: item.takeProfit,
        borderColor: item.type === 0 ? "#59c9b6" : "#ff1100",
        label: {
          offsetX: 200,
          position: "left",
          borderColor: item.type === 0 ? "#59c9b6" : "#ff1100",
          style: {
            color: "#fff",
            background: item.type === 0 ? "#59c9b6" : "#ff1100",
            textAlign: "left",
          },
          text: `${item.typeText} ${item.totalOrder} - T/P ${item.takeProfit} (${item.pointDiff} Points)`,
        },
      }));

      this.setState({ groupOrders: groupOrder });
    }
  };

  getMemoAnnotations = () => {
    const { currentPriceBuy, currentPriceSell, groupOrders } = this.state;

    return {
      buy: currentPriceBuy,
      sell: currentPriceSell,
      order: groupOrders,
    };
  };

  getChartOptions = () => {
    const {
      highest,
      lowest,
      currentPriceBuy,
      currentPriceSell,
      takeProfit,
      minX,
      maxX,
    } = this.state;
    const memoAnnotations = this.getMemoAnnotations();

    return {
      chart: {
        height: 500,
        type: "candlestick",
        stackOnlyBar: true,
        events: {
          zoomed: (chartContext, { xaxis }) => {
            this.setState({ minX: xaxis.min, maxX: xaxis.max });
          },
          beforeResetZoom: () => ({
            xaxis: { min: null, max: null },
          }),
        },
      },
      title: {
        text: "",
        align: "left",
      },
      tooltip: {
        enabled: true,
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (val) => dayjs.unix(val).format("DD MMM HH:mm"),
          style: { fontSize: "8px" },
        },
        min: minX,
        max: maxX,
      },
      yaxis: {
        tooltip: { enabled: true },
        max:
          Math.max(
            highest,
            lowest,
            currentPriceBuy,
            currentPriceSell,
            ...(takeProfit || [0])
          ) + 0.0001,
        min:
          Math.min(
            highest,
            lowest,
            currentPriceBuy,
            currentPriceSell,
            ...(takeProfit || [0])
          ) - 0.0001,
        opposite: true,
        labels: {
          formatter: (val) => parseFloat(val).toFixed(5),
        },
      },
      plotOptions: {
        candlestick: {
          colors: { upward: "#59c9b6", downward: "#ff1100" },
        },
      },
      markers: {
        size: 0,
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      fill: {
        type: "solid",
        opacity: 0.5,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: true, speed: 350 },
      },
      annotations: {
        yaxis: [
          {
            y: memoAnnotations.buy,
            borderColor: "#59c9b6",
            label: {
              text: memoAnnotations.buy,
              offsetX: 60,
              style: {
                color: "#fff",
                background: "#59c9b6",
                textAlign: "left",
              },
            },
          },
          {
            y: memoAnnotations.sell,
            borderColor: "#ff1100",
            label: {
              offsetX: 60,
              text: memoAnnotations.sell,
              style: {
                color: "#fff",
                background: "#ff1100",
                textAlign: "left",
              },
            },
          },
          ...(memoAnnotations.order || []),
        ],
      },
    };
  };

  checkMqttConnection() {
    const { isConnected, subscribeToTopic } = this.context;
    const { topicTradeChart, topicTradeData } = this.state;

    if (isConnected) {
      if (!this.isSubscribed) {
        console.log("MQTT is connected:", isConnected);
        subscribeToTopic(`${topicTradeData}/+/${this.symbolRef.current.value}`);
        subscribeToTopic(
          `${topicTradeChart}/${this.symbolRef.current.value}/${this.intervalRef.current}`
        );
        this.isSubscribed = true;
      }
    } else {
      if (this.isSubscribed) {
        console.log("MQTT is not connected.");
        this.isSubscribed = false;
      }
    }
  }

  doLoadTradingViewChart = (interval, symbol) => {
    apiTradingViewV2(interval, symbol)
      .then((res) => {
        const data = res.data;
        if (data.code === 200) {
          const recordTradeChart = data.record || [];
          const chartOHLC = recordTradeChart.map((item) => ({
            x: item.time_of_bar,
            y: [item.open, item.high, item.low, item.close],
          }));

          const allValues = chartOHLC.flatMap((item) => item.y || []);
          const highest =
            allValues.length > 0 ? Math.max(...allValues) : -Infinity;
          const lowest =
            allValues.length > 0 ? Math.min(...allValues) : Infinity;

          // console.log("chartOHLC: ", chartOHLC);
          this.setState({
            highest,
            lowest,
            dataTradeChart: {
              data: chartOHLC,
            },
          });

          if (window.innerWidth < 600) {
            const dataTradeChart = chartOHLC.slice(-20);
            const xValues = dataTradeChart.map((item) => item.x);
            const minX = Math.min(...xValues);
            const maxX = Math.max(...xValues);
            this.setState({
              minX,
              maxX,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleIntervalChange = (newInterval) => {
    const { unsubscribeFromTopic, subscribeToTopic } = this.context;
    const { topicTradeChart, topicTradeData } = this.state;

    if (this.intervalRef.current !== newInterval) {
      if (this.isSubscribed) {
        unsubscribeFromTopic(
          `${topicTradeData}/+/${this.symbolRef.current.value}`
        );
        unsubscribeFromTopic(
          `${topicTradeChart}/${this.symbolRef.current.value}/${this.intervalRef.current}`
        );
        this.isSubscribed = false;
      }

      this.intervalRef.current = newInterval;

      this.setState({
        maxX: null,
        minX: null,
      });

      subscribeToTopic(`${topicTradeData}/+/${this.symbolRef.current.value}`);
      subscribeToTopic(
        `${topicTradeChart}/${this.symbolRef.current.value}/${newInterval}`
      );
      this.isSubscribed = true;

      this.doLoadTradingViewChart(newInterval, this.symbolRef.current.value);
      this.forceUpdate();
    }
  };

  handleChangeSymbol = (symbol) => {
    const { unsubscribeFromTopic, subscribeToTopic } = this.context;
    const { topicTradeChart, topicTradeData } = this.state;

    if (this.symbolRef.current !== symbol) {
      if (this.isSubscribed) {
        unsubscribeFromTopic(
          `${topicTradeData}/+/${this.symbolRef.current.value}`
        );
        unsubscribeFromTopic(
          `${topicTradeChart}/${this.symbolRef.current.value}/${this.intervalRef.current}`
        );
        this.isSubscribed = false;
      }

      this.symbolRef.current = symbol;

      this.setState({
        maxX: null,
        minX: null,
      });

      subscribeToTopic(`${topicTradeData}/+/${symbol.value}`);
      subscribeToTopic(
        `${topicTradeChart}/${symbol.value}/${this.intervalRef.current}`
      );
      this.isSubscribed = true;

      this.doLoadTradingViewChart(this.intervalRef.current, symbol.value);
      this.forceUpdate();
    }
  };

  render() {
    const chartOptions = this.getChartOptions();
    const { symbolOption, dataTradeChart } = this.state;

    // console.log("dataTradeChart: ", dataTradeChart);
    // console.log("chartOptions");

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper
              style={{
                borderLeft: 6,
                borderColor: "#2f55a2",
                color: "#FFF",
                maxHeight: 100,
                padding: 16,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={{
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Trade Chart
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ViewSelectOption
                    handleChangeSymbol={this.handleChangeSymbol}
                    symbolOption={symbolOption}
                    symbol={this.symbolRef.current}
                  />
                  <ButtonInterval
                    handleIntervalChange={this.handleIntervalChange}
                    interval={this.intervalRef.current}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2} mt={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TradingView
              dataTradeChart={dataTradeChart}
              chartOptions={chartOptions}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const ViewSelectOption = React.memo(
  ({ handleChangeSymbol, symbolOption, symbol }) => (
    <Select
      classNamePrefix="select"
      placeholder="Symbol"
      value={symbol}
      onChange={handleChangeSymbol}
      options={symbolOption}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          width: window.innerWidth < 600 ? 100 : 150,
          fontSize: window.innerWidth < 600 ? "12px" : "14px",
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: window.innerWidth < 600 ? "12px" : "14px",
        }),
      }}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      menuPlacement="bottom"
    />
  )
);

const ButtonInterval = React.memo(({ handleIntervalChange, interval }) => {
  const data = ["M1", "M5", "M15", "M30", "H1"];
  return (
    <ButtonGroup
      variant="contained"
      aria-label="outlined button group"
      style={{
        height: "36px",
        marginLeft: window.innerWidth < 600 ? "5px" : "10px",
        fontSize: window.innerWidth < 600 ? "12px" : "14px",
      }}
    >
      {data.map((item) => (
        <Button
          key={item}
          size="small"
          onClick={() => handleIntervalChange(item)}
          style={{
            backgroundColor: interval === item ? "#1f324b" : "#fff",
            color: interval === item ? "#fff" : "#000",
            fontSize: window.innerWidth < 600 ? "12px" : "14px",
          }}
        >
          {item}
        </Button>
      ))}
    </ButtonGroup>
  );
});

const TradingView = React.memo(({ dataTradeChart, chartOptions }) => (
  <div className="box-container">
    <Chart
      type="candlestick"
      height={450}
      options={chartOptions}
      series={[{ name: "candle", data: dataTradeChart.data || [] }]}
    />
  </div>
));

export default TradeChart;
