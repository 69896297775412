import React, { Component } from "react";
import { Box, Paper, Grid, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import {
  apiOrderTradingDaily,
  apiOrderTradingHourly,
  apiOrderTradingMonthly,
  apiOrderTradingRangeHourly,
  apiOrderTradingToday,
} from "../../Service/api";
import dayjs from "dayjs";

class OrderTradingPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      orderTradingDaily: [],
      orderTradingHourly: [],
      orderTradingMonthly: [],
      orderTradingRangeHourly: [],
      orderTradingToday: [],
    };
  }

  componentDidMount() {
    this.doLoadData();
  }

  componentWillUnmount() {}

  doLoadData = () => {
    apiOrderTradingToday()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              orderTradingToday: data.records,
            });
          } else {
            this.setState({
              orderTradingToday: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiOrderTradingDaily()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              orderTradingDaily: data.records,
            });
          } else {
            this.setState({
              orderTradingDaily: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiOrderTradingHourly()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              orderTradingHourly: data.records,
            });
          } else {
            this.setState({
              orderTradingHourly: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiOrderTradingMonthly()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              orderTradingMonthly: data.records,
            });
          } else {
            this.setState({
              orderTradingMonthly: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiOrderTradingRangeHourly()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              orderTradingRangeHourly: data.records,
            });
          } else {
            this.setState({
              orderTradingRangeHourly: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {
      orderTradingHourly,
      orderTradingMonthly,
      orderTradingDaily,
      orderTradingRangeHourly,
      orderTradingToday,
    } = this.state;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} mb={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper
              style={{
                borderLeft: 6,
                borderColor: "#2f55a2",
                color: "#FFF",
                maxHeight: 100,
                padding: 16,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={{
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Trade Activity
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                backgroundColor: "#1f324b",
                padding: 16,
                borderRadius: 3,
                fontWeight: "bolder",
                fontSize: 14,
                color: "white",
              }}
            >
              Order Position Latest
            </Typography>
            <OrderPositionLatest orderTradingToday={orderTradingToday} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                backgroundColor: "#1f324b",
                padding: 16,
                borderRadius: 3,
                fontWeight: "bolder",
                fontSize: 14,
                color: "white",
              }}
            >
              Best Time Frame for Position
            </Typography>
            <OrderTimeFramePosition
              orderTradingRangeHourly={orderTradingRangeHourly}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                backgroundColor: "#1f324b",
                padding: 16,
                borderRadius: 3,
                fontWeight: "bolder",
                fontSize: 14,
                color: "white",
              }}
            >
              Best Hours for Position
            </Typography>
            <OrderBestHoursPosition orderTradingHourly={orderTradingHourly} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                backgroundColor: "#1f324b",
                padding: 16,
                borderRadius: 3,
                fontWeight: "bolder",
                fontSize: 14,
                color: "white",
              }}
            >
              Best Daily for Position
            </Typography>
            <OrderBestDailyPosition orderTradingDaily={orderTradingDaily} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                backgroundColor: "#1f324b",
                padding: 16,
                borderRadius: 3,
                fontWeight: "bolder",
                fontSize: 14,
                color: "white",
              }}
            >
              Best Month for Position
            </Typography>
            <OrderBestMonthPosition orderTradingMonthly={orderTradingMonthly} />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const OrderPositionLatest = React.memo(({ orderTradingToday }) => {
  // Today
  const seriesToday = [
    {
      name: "Total Buy",
      data: orderTradingToday.map((record) => record.total_buy),
    },
    {
      name: "Total Sell",
      data: orderTradingToday.map((record) => record.total_sell),
    },
  ];

  const optionsToday = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#59c9b6", "#ff1100"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: orderTradingToday.map((record) =>
        dayjs(record.time_closed).format("DD MMM HH:mm:ss")
      ),
      labels: {
        style: {
          fontSize: "8px",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    legend: {
      position: "top",
    },
  };

  return (
    <div className="box-container">
      <Chart
        options={optionsToday}
        series={seriesToday}
        type="line"
        height={350}
      />
    </div>
  );
});

const OrderTimeFramePosition = React.memo(({ orderTradingRangeHourly }) => {
  // Range Hourly
  const seriesRangeHourly = [
    {
      name: "Total Buy",
      data: orderTradingRangeHourly.map((record) => record.total_buy),
    },
    {
      name: "Total Sell",
      data: orderTradingRangeHourly.map((record) => record.total_sell),
    },
  ];

  const optionsRangeHourly = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: true },
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
      },
    },
    xaxis: {
      categories: orderTradingRangeHourly.map(
        (record) => `${record.start_time}-${record.end_time}`
      ),
    },
    // yaxis: {
    //   title: { text: "Position Orders" },
    // },
    fill: {
      opacity: 1,
    },
    colors: ["#59c9b6", "#ff1100"],
    tooltip: { shared: true, intersect: false },
    legend: { position: "top" },
  };

  return (
    <div className="box-container">
      <Chart
        options={optionsRangeHourly}
        series={seriesRangeHourly}
        type="bar"
        height={600}
      />
    </div>
  );
});

const OrderBestHoursPosition = React.memo(({ orderTradingHourly }) => {
  const totalSellData = orderTradingHourly.map((item) => item.total_sell);
  const totalBuyData = orderTradingHourly.map((item) => item.total_buy);
  const tradingTimes = orderTradingHourly.map((item) => item.trading_time);

  // Hourly
  const seriesHourly = [
    {
      name: "Total Buy",
      data: totalBuyData,
    },
    {
      name: "Total Sell",
      data: totalSellData,
    },
  ];

  const optionsHourly = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: tradingTimes,
      // title: {
      //   text: "Total Orders",
      // },
    },
    // yaxis: {
    //   title: {
    //     text: "Trading Time",
    //   },
    // },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 40,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#59c9b6", "#ff1100"],
    tooltip: {
      y: {
        formatter: (val) => `${val} orders`,
      },
    },
  };

  return (
    <div className="box-container">
      <Chart
        options={optionsHourly}
        series={seriesHourly}
        type="bar"
        height={600}
      />
    </div>
  );
});

const OrderBestDailyPosition = React.memo(({ orderTradingDaily }) => {
  // Daily
  const seriesDaily = [
    {
      name: "Total Buy",
      data: orderTradingDaily.map((record) => record.total_buy),
    },
    {
      name: "Total Sell",
      data: orderTradingDaily.map((record) => record.total_sell),
    },
  ];

  const optionsDaily = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#59c9b6", "#ff1100"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
      padding: {
        // Add padding here
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: orderTradingDaily.map((record) => record.trading_day),
    },
    legend: {
      position: "top",
    },
  };

  return (
    <div className="box-container">
      <Chart
        options={optionsDaily}
        series={seriesDaily}
        type="line"
        height={350}
      />
    </div>
  );
});

const OrderBestMonthPosition = React.memo(({ orderTradingMonthly }) => {
  // monthly
  const seriesMonthly = [
    {
      name: "Total Buy",
      data: orderTradingMonthly.map((record) => record.total_buy),
    },
    {
      name: "Total Sell",
      data: orderTradingMonthly.map((record) => record.total_sell),
    },
    // {
    //   name: "Percentage",
    //   data: orderTradingMonthly.map((record) => record.percentage_of_total),
    // },
  ];

  const optionsMonthly = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: true },
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
      },
    },
    xaxis: {
      categories: orderTradingMonthly.map((record) => record.month_name),
    },
    // yaxis: {
    //   title: { text: "Position Orders" },
    // },
    fill: {
      opacity: 1,
    },
    colors: ["#59c9b6", "#ff1100"],
    tooltip: { shared: true, intersect: false },
    legend: { position: "top" },
  };
  return (
    <div className="box-container">
      <Chart
        options={optionsMonthly}
        series={seriesMonthly}
        type="bar"
        height={350}
      />
    </div>
  );
});

export default OrderTradingPage;
