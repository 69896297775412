import React, { Component } from "react";
import {
  apiSubmissionFormUpdate,
  apiSubmissionFormList,
  apiCountJobList,
  apiVpsOptionAvailable,
  apiActionAutoLogin,
  apiActionChangeLot,
  apiActionAutoLogout,
  apiActionAutoReActive,
  // apiActionAutoClose,
  apiAccountAutoOption,
  apiAutoActionSubmission,
  apiAutoActionSubmissionUpdate,
  apiMasterSlaveOption,
  apiUserOption,
  apiAgentOption,
  apiAutoReject,
  apiSubmissionAccountAddAgent,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  Refresh,
  Close,
  Cancel,
  Check,
  CheckBox,
  Save,
  Assessment,
  Publish,
  AddBox,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { Input, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import dayjs from "dayjs";
import Select, { components } from "react-select";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomOption = (props) => {
  const color = props.data.flag_auto === "1" ? "green" : "blue";

  return (
    <components.Option {...props}>
      <div style={{ color, display: "flex", alignItems: "center" }}>
        {props.data.label}
      </div>
    </components.Option>
  );
};

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class SubmissionFormListPage extends Component {
  constructor(props) {
    // console.log("props: ", props);
    super(props);
    this.setAccountNumber = React.createRef();
    this.setAlias = React.createRef();
    this.setPassword = React.createRef();
    this.setLot = React.createRef();
    this.setCapital = React.createRef();
    this.setNote = React.createRef();
    this.setCategory = React.createRef();
    this.setCategory.current = {
      value: "OPENING ACCOUNT",
      label: "OPENING ACCOUNT",
    };
    this.setAgent = React.createRef();
    this.setMaster = React.createRef();
    this.setUser = React.createRef();

    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertApprove: false,
      openAlertReject: false,
      itemSelectedRow: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingReject: false,
      showLoadingApprove: false,
      showLoadingRejectAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      submitLoading: false,
      note: "",
      loginInfo: "",
      showPassword: false,
      jobItemDetail: null,
      setOpenDetail: false,
      optionVps: [],
      vpsSelected: null,
      categoryOption: [
        { value: "OPENING ACCOUNT", label: "OPENING ACCOUNT" },
        { value: "CHANGE LOT", label: "CHANGE LOT" },
        { value: "SUSPEND", label: "SUSPEND" },
        { value: "ACTIVE", label: "ACTIVE" },
      ],
      accountAutoOption: [],
      optionAgent: [],
      optionMasterSlave: [],
      optionUser: [],
      secretKey:
        "e69902f8ca7bf37cb776f323384f502205cb907e8935c118faf6803e239402d6",
      agentSelected: null,
    };

    this.tableColumns = [
      {
        Header: "Time",
        id: "timeColumn",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: (row) => row.updated_at || row.created_at,
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ row }) => {
          // console.log("row: ", row);
          const timestamp =
            (row && row.timeColumn) || (row && row.timeColumn) || "N/A";

          return (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
              }}
            >
              {timestamp !== "N/A"
                ? dayjs(timestamp).format("DD/MM/YYYY HH:mm:ss")
                : "N/A"}
            </Typography>
          );
        },
        width: 180,
      },
      {
        Header: "Category",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.status}
          </Typography>
        ),
      },
      {
        Header: "Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_id}
          </Typography>
        ),
      },
      {
        Header: "Note",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "note",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.note}
          </Typography>
        ),
      },
      {
        Header: "Client",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "client",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.client}
          </Typography>
        ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 220,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assessment />}
              onClick={() => this.doRowDetail(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.noteInputRef = React.createRef();
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadData();
    this.doLoadOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiSubmissionFormList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.code === 200) {
          if (data.records.length > 0) {
            const temp = data.records;

            this.setState({
              tableData: temp,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doLoadOption = () => {
    apiVpsOptionAvailable()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          // console.log("option vps: ", data.records);
          const optionVps = data.records.map((vps) => {
            return {
              value: vps.value,
              label: `${vps.label} (${
                vps.flag_auto === "1" ? "Auto" : "Manual"
              }) - ${vps.available} available`,
              vps_name: vps.vps_name,
              flag_auto: vps.flag_auto,
              available: vps.available,
            };
          });

          this.setState({
            optionVps: optionVps,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    apiAccountAutoOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              accountAutoOption: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    apiAgentOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionAgent: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });

    apiMasterSlaveOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionMasterSlave: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });

    apiUserOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionUser: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  doApproveItem = (item) => {
    // console.log("item : ", item);
    this.props.doLoading();
    this.setState({ showLoadingApprove: true });

    const vps = this.state.vpsSelected;

    // console.log("vps: ", vps);

    let params = {
      account_id: item.account_id,
      alias: item.alias,
      password: item.password,
      lot: item.lot,
      capital: item.capital,
      ticket_id: item.ticket_id,
      category: item.status,
      key: item.key,
      executor: this.state.loginInfo.name,
      status: 4,
      note: this.state.note,
    };

    const agent =
      this.setAgent.current && Array.isArray(this.setAgent.current)
        ? this.setAgent.current
        : this.setAgent.current
        ? [this.setAgent.current]
        : [];

    const master =
      this.setMaster.current && Array.isArray(this.setMaster.current)
        ? this.setMaster.current
        : this.setMaster.current
        ? [this.setMaster.current]
        : [];

    const vpsArr = vps && Array.isArray(vps) ? vps : vps ? [vps] : [];

    let payloadAddAgent = {
      account_number: item.account_id,
      agent: agent,
      master: master,
      vps: vps !== null ? (vps.flag_auto === "1" ? [] : vpsArr) : [],
    };

    // console.log("payloadAddAgent: ", payloadAddAgent);

    if (item.client !== "3DVAM") {
      apiSubmissionFormUpdate(params)
        .then((res) => {
          this.setState({ showLoadingApprove: false });
          this.props.doLoading();
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data successfully approve",
              openAlertApprove: false,
              openAlertReject: false,
              setOpenDetail: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ showLoadingApprove: false });
          this.props.doLoading();
          console.log(err);
        });

      if (item.status === "OPENING ACCOUNT") {
        apiSubmissionAccountAddAgent(payloadAddAgent)
          .then((res) => {
            const data = res.data;
            console.info(data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      apiAutoActionSubmissionUpdate(params)
        .then((res) => {
          this.setState({ showLoadingApprove: false });
          this.props.doLoading();
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data successfully approve",
              openAlertApprove: false,
              openAlertReject: false,
              setOpenDetail: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ showLoadingApprove: false });
          this.props.doLoading();
          console.log(err);
        });
    }

    if (vps !== null) {
      //flag Auto
      if (vps.flag_auto === "1") {
        console.log("start vps automation");
        if (item.status === "OPENING ACCOUNT") {
          const actionStatus = item.status === "OPENING ACCOUNT" ? "login" : "";
          let paramsAuto = {
            client_id: this.state.loginInfo.phoneno,
            vps: vps.vps_name,
            action: actionStatus,
            vps_id: vps.value,
            account_number: item.account_id,
            alias: item.alias,
            password: item.password,
            password_master: item.password_master,
            password_investor: item.password_investor,
            lot: item.lot,
          };

          console.log("paramsAuto: ", paramsAuto);
          apiActionAutoLogin(paramsAuto)
            .then((res) => {
              const data = res.data;
              if (data.status === "success") {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              } else {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                jobItemDetail: null,
                vpsSelected: null,
              });
            });
        } else if (item.status === "CHANGE LOT") {
          const actionStatus = item.status === "CHANGE LOT" ? "changeLot" : "";
          let paramsAuto = {
            client_id: this.state.loginInfo.phoneno,
            vps: vps.vps_name,
            action: actionStatus,
            vps_id: vps.value,
            account_number: item.account_id,
            alias: item.alias,
            lot: item.lot,
          };

          console.log("paramsAuto: ", paramsAuto);
          apiActionChangeLot(paramsAuto)
            .then((res) => {
              const data = res.data;
              if (data.status === "success") {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              } else {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                jobItemDetail: null,
                vpsSelected: null,
              });
            });
        } else if (item.status === "ACTIVE") {
          const actionStatus = "active";
          let paramsAuto = {
            client_id: this.state.loginInfo.phoneno,
            vps: vps.vps_name,
            action: actionStatus,
            vps_id: vps.value,
            account_number: item.account_id,
            lot: item.lot,
          };

          console.log("paramsAuto: ", paramsAuto);
          apiActionAutoReActive(paramsAuto)
            .then((res) => {
              const data = res.data;
              if (data.status === "success") {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              } else {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                jobItemDetail: null,
                vpsSelected: null,
              });
            });
        } else if (item.status === "INACTIVE") {
          const actionStatus = "logout";
          let paramsAuto = {
            client_id: this.state.loginInfo.phoneno,
            vps: vps.vps_name,
            action: actionStatus,
            vps_id: vps.value,
            account_number: item.account_id,
          };

          console.log("paramsAuto: ", paramsAuto);
          apiActionAutoLogout(paramsAuto)
            .then((res) => {
              const data = res.data;
              if (data.status === "success") {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              } else {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                jobItemDetail: null,
                vpsSelected: null,
              });
            });
        } else if (item.status === "SUSPENDED") {
          const actionStatus = "logout";
          let paramsAuto = {
            client_id: this.state.loginInfo.phoneno,
            vps: vps.vps_name,
            action: actionStatus,
            vps_id: vps.value,
            account_number: item.account_id,
          };

          console.log("paramsAuto: ", paramsAuto);
          apiActionAutoLogout(paramsAuto)
            .then((res) => {
              const data = res.data;
              if (data.status === "success") {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              } else {
                this.setState({
                  jobItemDetail: null,
                  vpsSelected: null,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                jobItemDetail: null,
                vpsSelected: null,
              });
            });
        }
      }
    }
  };

  doRejectItem = (item) => {
    this.props.doLoading();
    this.setState({ showLoadingReject: true });
    let params = {
      account_id: item.account_id,
      alias: item.alias,
      password: item.password,
      lot: item.lot,
      capital: item.capital,
      ticket_id: item.ticket_id,
      category: item.status,
      key: item.key,
      executor: this.state.loginInfo.name,
      status: 5,
      note: this.state.note,
    };

    if (item.client !== "3DVAM") {
      // console.log("params reject: ", params);
      apiSubmissionFormUpdate(params)
        .then((res) => {
          this.setState({ showLoadingReject: false });
          this.props.doLoading();
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data successfully reject",
              openAlertApprove: false,
              openAlertReject: false,
              setOpenDetail: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ showLoadingReject: false });
          this.props.doLoading();
          console.log(err);
        });
    } else {
      apiAutoActionSubmissionUpdate(params)
        .then((res) => {
          this.setState({ showLoadingReject: false });
          this.props.doLoading();
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data successfully reject",
              openAlertApprove: false,
              openAlertReject: false,
              setOpenDetail: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ showLoadingReject: false });
          this.props.doLoading();
          console.log(err);
        });
    }

    apiAutoReject(item.account_id)
      .then((res) => {
        const data = res.data;
        console.info(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  doRowApproveInfo = (item) => {
    this.setState({
      openAlertApprove: true,
      itemSelectedRow: item,
    });
  };

  doRowRejectInfo = (item) => {
    this.setState({
      openAlertReject: true,
      itemSelectedRow: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertApprove: false,
      openAlertReject: false,
      itemSelectedRow: {},
      selectRowData: [],
      note: "",
    });
  };

  doRowDetail = (item) => {
    // console.log("item detail: ", item);
    const vpsData = this.state.optionVps;
    const agentData = this.state.optionAgent;

    // console.log("agentData: ", agentData);
    const agentSelected = agentData.filter(
      (agent) => agent.label.toLowerCase() === item.client.toLowerCase()
    );

    // console.info("agentSelected: ", agentSelected);

    this.setAgent.current = agentSelected.length > 0 ? agentSelected[0] : null;
    this.forceUpdate();

    this.setState({
      selectedAgent: agentSelected.length > 0 ? agentSelected[0] : null,
    });

    const vpsSelected = vpsData.filter((data) =>
      item.vps.some((v) => v.vps_id === data.value)
    );
    // console.info("vpsSelected: ", vpsSelected);
    this.setState({
      jobItemDetail: item,
      setOpenDetail: true,
      vpsSelected: vpsSelected.length > 0 ? vpsSelected[0] : null,
    });
  };

  handleCloseOpenDetail = () => {
    this.setState({
      jobItemDetail: null,
      setOpenDetail: false,
      openAlertApprove: false,
      openAlertReject: false,
      vpsSelected: null,
    });
  };

  doProcessedApproved = () => {
    this.setState({
      openAlertApprove: true,
    });
  };

  doProcessedReject = () => {
    this.setState({
      openAlertReject: true,
    });
  };

  handleChangeVps = (vps) => {
    this.setState({
      vpsSelected: vps,
    });
  };

  renderOpenDetail = () => {
    if (this.state.jobItemDetail !== null) {
      const { openAlertApprove, openAlertReject, jobItemDetail, optionVps } =
        this.state;
      const item = jobItemDetail;

      let vpsOptionAuto = [];
      let vpsOptionManual = [];

      if (item.client === "3DVAM") {
        if (item.status === "OPENING ACCOUNT") {
          vpsOptionAuto = optionVps.filter(
            (obj) => obj.available !== 0 && obj.flag_auto === "1"
          );
        } else {
          const tempOption = optionVps.filter((obj) => obj.flag_auto === "1");
          vpsOptionAuto = tempOption.map((item) => {
            const labelParts = item.label.split(" - ");
            return {
              ...item,
              label: labelParts[0],
            };
          });
        }
      } else {
        if (item.status === "OPENING ACCOUNT") {
          vpsOptionManual = optionVps.filter((obj) => obj.available !== 0);
        } else {
          vpsOptionManual = optionVps.map((item) => {
            const labelParts = item.label.split(" - ");
            return {
              ...item,
              label: labelParts[0],
            };
          });
        }
      }
      // console.log("vpsOptionAuto: ", vpsOptionAuto);

      // console.log("optionVps: ", optionVps);

      const title =
        openAlertApprove === true
          ? `Approve Account: ${item.account_id}`
          : openAlertReject === true
          ? `Reject Account: ${item.account_id}`
          : "Job List Account Details";
      return (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpenDetail}
          fullWidth={true}
          maxWidth="md"
        >
          <AppBar style={stylesDialog.appBar}>
            <Toolbar>
              <Typography variant="h5" style={stylesDialog.title}>
                {title}
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => this.handleCloseOpenDetail()}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          {openAlertApprove === true ? (
            <>
              <DialogContent dividers>
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{ marginBottom: 60, marginTop: 20 }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        VPS
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Select
                        classNamePrefix="select"
                        placeholder="Select a vps"
                        value={this.state.vpsSelected}
                        onChange={this.handleChangeVps}
                        options={
                          item.client === "3DVAM"
                            ? vpsOptionAuto
                            : vpsOptionManual
                        }
                        components={{ Option: CustomOption }}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                      />
                    </Grid>

                    {item.client === "3DVAM" ? null : item.client !== "3DVAM" &&
                      (item.status === "OPENING ACCOUNT" ||
                        item.status === "ACTIVE") ? (
                      <>
                        <Grid item lg={2} md={3} sm={3} xs={12}>
                          <Typography
                            component="span"
                            variant="subtitle1"
                            style={{
                              float: "left",
                              marginTop: 6,
                              textTransform: "capitalize",
                            }}
                          >
                            Agent
                          </Typography>
                        </Grid>
                        <Grid item lg={10} md={9} sm={9} xs={12}>
                          <Select
                            classNamePrefix="select"
                            placeholder="Select a agent"
                            isClearable={true}
                            value={this.setAgent.current}
                            onChange={this.handleChangeAgent}
                            options={this.state.optionAgent}
                            styles={{
                              menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            menuPlacement="top"
                          />
                        </Grid>
                      </>
                    ) : null}

                    {item.client === "3DVAM" ? null : item.client !== "3DVAM" &&
                      (item.status === "OPENING ACCOUNT" ||
                        item.status === "ACTIVE") ? (
                      <>
                        <Grid item lg={2} md={3} sm={3} xs={12}>
                          <Typography
                            component="span"
                            variant="subtitle1"
                            style={{
                              float: "left",
                              marginTop: 6,
                              textTransform: "capitalize",
                            }}
                          >
                            Master
                          </Typography>
                        </Grid>
                        <Grid item lg={10} md={9} sm={9} xs={12}>
                          <Select
                            classNamePrefix="select"
                            placeholder="Select a master"
                            isClearable={true}
                            onChange={this.handleChangeMaster}
                            options={this.state.optionMasterSlave}
                            styles={{
                              menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            menuPlacement="top"
                          />
                        </Grid>
                      </>
                    ) : null}

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Note
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <TextField
                        id="note"
                        type="text"
                        fullWidth
                        value={this.state.note}
                        inputRef={this.noteInputRef}
                        onChange={this.handleNoteChange}
                        variant="outlined"
                        label={<div style={{ height: 0 }} />}
                        InputProps={{
                          notched: false,
                        }}
                        sx={{
                          "& fieldset": {
                            top: 0,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.showLoadingApprove}
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#008b02",
                  }}
                  startIcon={<Publish />}
                  onClick={() => this.doApproveItem(item)}
                >
                  {this.state.showLoadingApprove === true ? (
                    <CircularProgress style={{ color: "#fff" }} size={24} />
                  ) : (
                    <Typography
                      variant="button"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Submit Approved
                    </Typography>
                  )}
                </Button>
                &nbsp;&nbsp;
                <Button
                  disabled={this.state.showLoadingApprove}
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#d0021b",
                  }}
                  startIcon={<Cancel />}
                  onClick={() =>
                    this.setState({
                      openAlertApprove: false,
                      openAlertReject: false,
                    })
                  }
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Cancel
                  </Typography>
                </Button>
              </DialogActions>
            </>
          ) : openAlertReject === true ? (
            <>
              <>
                <DialogContent dividers>
                  <Box
                    sx={{ flexGrow: 1 }}
                    style={{ marginBottom: 60, marginTop: 20 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="note"
                          label="Note"
                          type="text"
                          fullWidth
                          value={this.state.note}
                          inputRef={this.noteInputRef}
                          onChange={this.handleNoteChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={this.state.showLoadingReject}
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#008b02",
                    }}
                    startIcon={<Publish />}
                    onClick={() => this.doRejectItem(item)}
                  >
                    {this.state.showLoadingReject === true ? (
                      <CircularProgress style={{ color: "#fff" }} size={24} />
                    ) : (
                      <Typography
                        variant="button"
                        style={{
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Submit Rejected
                      </Typography>
                    )}
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={this.state.showLoadingReject}
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#d0021b",
                    }}
                    startIcon={<Cancel />}
                    onClick={() =>
                      this.setState({
                        openAlertApprove: false,
                        openAlertReject: false,
                      })
                    }
                  >
                    <Typography
                      variant="button"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Button>
                </DialogActions>
              </>
            </>
          ) : (
            <>
              <DialogContent dividers>
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{ marginBottom: 60, marginTop: 20 }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Time
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="time"
                        id="time"
                        value={dayjs(item.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      />
                    </Grid>

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Category
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="category"
                        id="category"
                        value={item.status}
                      />
                    </Grid>

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Ticket ID
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="ticket_id"
                        id="ticket_id"
                        value={item.ticket_id}
                      />
                    </Grid>

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Account
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="account_id"
                        id="account_id"
                        value={item.account_id}
                      />
                    </Grid>

                    {item.status !== "OPENING ACCOUNT" ? null : (
                      <>
                        <Grid item lg={2} md={3} sm={3} xs={12}>
                          <Typography
                            component="span"
                            variant="subtitle1"
                            style={{
                              float: "left",
                              marginTop: 6,
                              textTransform: "capitalize",
                            }}
                          >
                            Password
                          </Typography>
                        </Grid>
                        <Grid item lg={10} md={9} sm={9} xs={12}>
                          <InputGroup>
                            <Input
                              autoComplete="off"
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              name="alias"
                              id="alias"
                              value={item.password_initial_decrypt}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <span onClick={this.handleTogglePassword}>
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </span>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Grid>
                      </>
                    )}

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Lot
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="lot"
                        id="lot"
                        value={item.lot}
                      />
                    </Grid>

                    {item.status !== "OPENING ACCOUNT" ? null : (
                      <>
                        <Grid item lg={2} md={3} sm={3} xs={12}>
                          <Typography
                            component="span"
                            variant="subtitle1"
                            style={{
                              float: "left",
                              marginTop: 6,
                              textTransform: "capitalize",
                            }}
                          >
                            Capital
                          </Typography>
                        </Grid>
                        <Grid item lg={10} md={9} sm={9} xs={12}>
                          <Input
                            autoComplete="off"
                            type="text"
                            name="capital"
                            id="capital"
                            value={item.capital}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item lg={2} md={3} sm={3} xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Note
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={9} xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="note"
                        id="note"
                        value={item.note}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.submitLoading}
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#004dcf",
                  }}
                  startIcon={<CheckBox />}
                  onClick={() => this.doProcessedApproved()}
                >
                  {this.state.submitLoading === true ? (
                    <CircularProgress style={{ color: "#fff" }} size={24} />
                  ) : (
                    <Typography
                      variant="button"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Approve
                    </Typography>
                  )}
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#d0021b",
                  }}
                  startIcon={<Cancel />}
                  onClick={() => this.doProcessedReject()}
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Reject
                  </Typography>
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      );
    }
  };

  handleNoteChange = () => {
    const noteValue = this.noteInputRef.current.value;
    this.setState({ note: noteValue });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      note: "",
    });
    this.doLoadData();
    apiCountJobList()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          const count = data.count;
          localStorage.setItem("count", JSON.stringify(count));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  renderApproveItemInfo = () => {
    let item = this.state.itemSelectedRow;
    let Category = item && item.status ? this.toTitleCase(item.status) : "";
    return (
      <Dialog
        open={this.state.openAlertApprove}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Approve {Category}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to approve account {item.account_id}?
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            value={this.state.note}
            inputRef={this.noteInputRef}
            onChange={this.handleNoteChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingApprove}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingApprove}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={
              this.state.showLoadingApprove === true ? null : <Check />
            }
            onClick={() => this.doApproveItem(item)}
          >
            {this.state.showLoadingApprove === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRejectItemInfo = () => {
    let item = this.state.itemSelectedRow;
    let Category = item && item.status ? this.toTitleCase(item.status) : "";
    return (
      <Dialog
        open={this.state.openAlertReject}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Reject {Category}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to reject account {item.account_id}?
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            value={this.state.note}
            inputRef={this.noteInputRef}
            onChange={this.handleNoteChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingReject}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingReject}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingReject === true ? null : <Check />}
            onClick={() => this.doRejectItem(item)}
          >
            {this.state.showLoadingReject === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      note: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
    });
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleChangeCategory = (category) => {
    this.setCategory.current = category;
    this.forceUpdate();
  };

  handleChangeAgent = (agent) => {
    this.setAgent.current = agent;
    this.forceUpdate();
  };

  handleChangeMaster = (master) => {
    this.setMaster.current = master;
    this.forceUpdate();
  };

  handleChangeUser = (user) => {
    this.setUser.current = user;
    this.forceUpdate();
  };

  handleChangeAccount = (account) => {
    this.setAccountNumber.current = account;
    this.forceUpdate();
  };

  generateRandomHex = (length) => {
    return [...Array(length)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };

  generateTradingPassword(type = "master") {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";

    const minLength = 8;
    const maxLength = 15;

    const allChars = uppercase + lowercase + numbers;

    let password = "";

    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];

    if (type === "investor") {
      password += numbers[Math.floor(Math.random() * numbers.length)];
    } else if (type === "master") {
      password += uppercase[Math.floor(Math.random() * uppercase.length)];
    }

    const remainingLength =
      Math.floor(Math.random() * (maxLength - minLength + 1)) +
      minLength -
      password.length;

    for (let i = 0; i < remainingLength; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  }

  checkData = () => {
    const category =
      (this.setCategory.current && this.setCategory.current.value) || "";
    const account_number =
      (this.setAccountNumber.current && this.setAccountNumber.current.value) ||
      "";
    const lot = (this.setLot.current && this.setLot.current.value) || "";
    const password =
      (this.setPassword.current && this.setPassword.current.value) || "";
    const capital =
      (this.setCapital.current && this.setCapital.current.value) || "";
    const alias = (this.setAlias.current && this.setAlias.current.value) || "";
    const agent =
      this.setAgent.current && Array.isArray(this.setAgent.current)
        ? this.setAgent.current
        : this.setAgent.current
        ? [this.setAgent.current]
        : [];

    const master =
      this.setMaster.current && Array.isArray(this.setMaster.current)
        ? this.setMaster.current
        : this.setMaster.current
        ? [this.setMaster.current]
        : [];

    const user =
      this.setUser.current && Array.isArray(this.setUser.current)
        ? this.setUser.current
        : this.setUser.current
        ? [this.setUser.current]
        : [];

    const masterPassword = this.generateTradingPassword("master");
    const investorPassword = this.generateTradingPassword("investor");

    if (category === undefined || category === null || category === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty category?",
        titleBody: "Please select a category",
      });
    } else if (
      account_number === undefined ||
      account_number === null ||
      account_number === ""
    ) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty account number?",
        titleBody: "Enter account number",
      });
    } else if (category === "OPENING ACCOUNT" && alias === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty alias?",
        titleBody: "Enter alias",
      });
    } else if (category === "OPENING ACCOUNT" && password === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty password?",
        titleBody: "Enter password",
      });
    } else if (category === "OPENING ACCOUNT" && lot === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty lot?",
        titleBody: "Enter lot",
      });
    } else if (category === "CHANGE LOT" && lot === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty lot?",
        titleBody: "Enter lot",
      });
    } else if (category === "ACTIVE" && lot === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty lot?",
        titleBody: "Enter lot",
      });
    } else {
      const paramsOpening = {
        category: category,
        subCategory: "",
        ticket_id: this.generateRandomHex(16),
        secretKey: this.state.secretKey,
        data: {
          account_id: account_number,
          alias: alias,
          lot: lot,
          password: password,
          password_master: masterPassword,
          password_investor: investorPassword,
          capital: capital,
          note: "",
          agent: agent,
          user: user,
          master: master,
        },
      };

      const paramsChangeLot = {
        category: "ACCOUNT ADJUSTMENT",
        subCategory: category,
        ticket_id: this.generateRandomHex(16),
        secretKey: this.state.secretKey,
        data: {
          account_id: account_number,
          lot: lot,
          note: "",
        },
      };

      const paramsSuspend = {
        category: category,
        subCategory: "",
        ticket_id: this.generateRandomHex(16),
        secretKey: this.state.secretKey,
        data: {
          account_id: account_number,
        },
      };

      const paramsActive = {
        category: category,
        subCategory: "",
        ticket_id: this.generateRandomHex(16),
        secretKey: this.state.secretKey,
        data: {
          account_id: account_number,
          lot: lot,
        },
      };

      const setupParamsCategory =
        category === "OPENING ACCOUNT"
          ? paramsOpening
          : category === "CHANGE LOT"
          ? paramsChangeLot
          : category === "SUSPEND"
          ? paramsSuspend
          : category === "ACTIVE"
          ? paramsActive
          : {};

      console.info("setupParamsCategory: ", setupParamsCategory);
      this.setState({
        submitLoading: true,
      });
      apiAutoActionSubmission(setupParamsCategory)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          const data = res.data;
          if (data.code === 200) {
            this.setState({
              openSuccess: true,
              openSuccessText: "Successfully add ticket.",
              setOpenAddNew: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.error(err);
        });
    }
  };

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  renderDialogAddAccount = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenAddNew}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Add Ticket
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Category
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a category"
                  onChange={this.handleChangeCategory}
                  options={this.state.categoryOption}
                  value={this.setCategory.current}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="bottom"
                />
              </Grid>

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Account Number
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Select a account"
                      onChange={this.handleChangeAccount}
                      options={this.state.accountAutoOption}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      menuPlacement="bottom"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Account Number
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Input
                      autoComplete="off"
                      type="number"
                      name="account_number"
                      id="account_number"
                      innerRef={this.setAccountNumber}
                    />
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Alias
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Input
                      autoComplete="off"
                      type="text"
                      name="capital"
                      id="capital"
                      innerRef={this.setAlias}
                    />
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Password
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        type={this.state.showPassword ? "text" : "password"}
                        name="alias"
                        id="alias"
                        innerRef={this.setPassword}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <span onClick={this.handleTogglePassword}>
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </span>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" &&
              this.setCategory.current.value !== "CHANGE LOT" &&
              this.setCategory.current.value !== "ACTIVE" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Lot
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Input
                      autoComplete="off"
                      type="number"
                      name="lot"
                      id="lot"
                      innerRef={this.setLot}
                    />
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Agent
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Select a agent"
                      isClearable={true}
                      onChange={this.handleChangeAgent}
                      options={this.state.optionAgent}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      menuPlacement="top"
                    />
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      Master
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Select a master"
                      isClearable={true}
                      onChange={this.handleChangeMaster}
                      options={this.state.optionMasterSlave}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      menuPlacement="top"
                    />
                  </Grid>
                </>
              )}

              {this.setCategory.current.value !== "OPENING ACCOUNT" ? null : (
                <>
                  <Grid item lg={3} md={3} xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        textTransform: "capitalize",
                      }}
                    >
                      User
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Select a user"
                      isClearable={true}
                      onChange={this.handleChangeUser}
                      options={this.state.optionUser}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      menuPlacement="top"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Job List Incoming
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Add Ticket
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderValidationAlert()}
        {this.renderOpenDetail()}
        {this.renderDialogAddAccount()}
      </Box>
    );
  }
}
export default SubmissionFormListPage;
