import React, { Component } from "react";
import _ from "lodash";
import {
  apiVpsDetail,
  apiAccountAvailable,
  apiAccountUpdate,
  apiAccountUnlink,
  apiAccountUpdateAlias,
  apiAccountMt4UpdateRate,
  apiRateOption,
  apiAccountMt4UpdateTypeCurrency,
} from "../../Service/api";
import ReactTable from "react-table";
import { Input } from "reactstrap";
import {
  ArrowBackIos,
  Cancel,
  Save,
  AddBox,
  Assignment,
  Close,
  LinkOff,
  Check,
} from "@mui/icons-material";
import ButtonUI from "@mui/material/Button";
import { formatBytes } from "../../../global";
import {
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Checkbox,
  DialogContent,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardHeader,
  Card,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Slide,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

// let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class VpsDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code_vps: props.match.params.code_vps,
      last_timestamp: "",
      status: "",
      vps_id: "",
      vps_name: "",
      cpu_usage: "",
      memory_usage: "",
      storage_usage: "",
      vps_ip: "",
      vps_port: "",
      vps_network_usage: "",
      dataShow: [],
      communityList: [],
      messageError: "",
      setOpenValidation: false,
      dataAccountAvailable: [],
      available: [],
      left: [],
      right: [],
      checked_transfer: [],
      setOpenDialogAdd: false,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openSuccess: false,
      openSuccessText: "",
      checkedAccountList: [],
      dataDetail: "",
      alias: "",
      lots: "",
      setOpenEdit: false,
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
      showLoadingDelete: false,
      selectedAccountId: null,
      optionAccount: [],
      submitLoading: false,
      loginInfo: "",
      rateOption: [],
      typeOption: [
        { value: "Cents", label: "Cents" },
        { value: "Standart", label: "Standart" },
      ],
      type_currency: null,
      rateSelected: null,
    };
    this.handleAliasChange = _.debounce(this.handleAliasChange, 0);
    this.handleLotsChange = _.debounce(this.handleLotsChange, 0);
    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Account Number",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "f;ex-start",
          alignItems: "center",
        },
        width: 250,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },
      {
        Header: "Equity",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "equity",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
              }}
            >
              {Number(parseFloat(e.original.equity).toFixed(2)).toLocaleString(
                "en",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
          </>
        ),
      },

      {
        Header: "Rate",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "rate_id",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => {
          if (this.state.loginInfo.logintype === 1) {
            return (
              <select
                value={e.original.rate_id}
                onChange={(event) => {
                  const updatedData = [...this.state.tableData];
                  updatedData[e.index].rate_id = event.target.value;
                  this.doApiUpdateRate(e.original);
                  this.setState({ tableData: updatedData });
                }}
              >
                <option value={0}>Select Rate</option>
                {this.state.rateOption.length > 0 &&
                  this.state.rateOption.map((obj) => (
                    <option key={obj.value} value={obj.value}>
                      {obj.label}
                    </option>
                  ))}
              </select>
            );
          } else {
            return (
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {e.original.rate_name}
              </Typography>
            );
          }
        },
      },

      {
        Header: "Type",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "type_currency",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => {
          return (
            <select
              value={e.original.type_currency}
              onChange={(event) => {
                const updatedData = [...this.state.tableData];
                updatedData[e.index].type_currency = event.target.value;
                this.doApiUpdateTypeCurrency(e.original);
                this.setState({ tableData: updatedData });
              }}
            >
              <option value={0}>Select Type</option>
              {this.state.typeOption.length > 0 &&
                this.state.typeOption.map((obj) => (
                  <option key={obj.value} value={obj.value}>
                    {obj.label}
                  </option>
                ))}
            </select>
          );
        },
      },
      {
        Header: "Lots Size",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "lots",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {value === "" ? "-" : value}
          </Typography>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                View
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<LinkOff />}
              onClick={() => this.doRowUnlinkAccount(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Unlink
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    this.doLoadData(this.state.code_vps);
    this.doLoadOption();
  };

  componentWillUnmount() {
    // clearTimeout(timer);
  }

  doLoadData = (code_vps) => {
    if (this.state.vps_id === "") {
      this.props.doLoading();
    }
    apiVpsDetail(code_vps)
      .then((res) => {
        if (this.state.vps_id === "") {
          this.props.doLoading();
        }
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.record.account_list;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
          }

          function isRecentUpdate(lastTimestamp) {
            let now = new Date();
            let lastUpdate = new Date(lastTimestamp);
            let difference = now - lastUpdate;
            let differenceInMinutes = difference / (1000 * 60);
            return differenceInMinutes < 5;
          }

          const statusVPS = !isRecentUpdate(data.record.last_timestamp)
            ? "OFF"
            : "ON";
          this.setState({
            code_vps: data.record.code_vps,
            last_timestamp: data.record.last_timestamp,
            status: statusVPS,
            vps_id: data.record.vps_id,
            vps_name: data.record.vps_name,
            cpu_usage: data.record.cpu_usage,
            memory_usage: data.record.memory_usage,
            storage_usage: data.record.storage_usage,
            vps_ip: data.record.vps_ip,
            vps_port: data.record.vps_port,
            vps_network_usage: data.record.vps_network_usage,
            tableData: temp,
            vps_status: data.record.vps_status,
          });

          this.doLoadAvailableAccount(data.record.account_list);

          // timer = setTimeout(() => this.doLoadData(code_vps), 30000);
        }
      })
      .catch((err) => {
        if (this.state.vps_id === "") {
          this.props.doLoading();
        }
        console.log(err);
      });
  };

  doLoadAvailableAccount = (dataAccount) => {
    apiAccountAvailable()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var results = data.records.filter(
              ({ account_number: id1 }) =>
                !dataAccount.some(({ account_number: id2 }) => id2 === id1)
            );

            const dataArr = results;

            for (var i = 0; i < dataArr.length; i++) {
              dataArr[i].label =
                dataArr[i].account_number + "(" + dataArr[i].alias + ")";
              dataArr[i].value = dataArr[i].account_number;
            }

            // console.log("dataArr: ", dataArr);

            this.setState({
              left: results,
              available: results,
              optionAccount: dataArr,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSubmitUpdate = () => {
    let params = {
      code_vps: this.state.code_vps,
      vps_id: this.state.vps_id,
      checkedAccountList: this.state.selectedAccountId,
    };
    if (this.state.selectedAccountId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Account empty?",
        titleBody: "No account selected",
      });
    } else {
      this.setState({
        showLoadingSubmit: true,
      });
      this.props.doLoading();
      apiAccountUpdate(params)
        .then((res) => {
          this.setState({
            showLoadingSubmit: false,
          });
          this.props.doLoading();
          let data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Data saved successfully",
            });
            this.doLoadData(this.state.code_vps);
            this.HandleCloseAddAccount();
          }
        })
        .catch((err) => {
          this.props.doLoading();
          this.setState({
            showLoadingSubmit: false,
          });
        });
    }
  };

  doUnlinkAccount = (account_number) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiAccountUnlink(account_number, this.state.vps_id)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data unlink successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
          this.doLoadData(this.state.code_vps);
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doLoadOption = () => {
    apiRateOption()
      .then((res) => {
        const data = res.data;
        // console.log(data);
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              rateOption: data.records,
            });
          } else {
            this.setState({
              rateOption: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doApiUpdateRate = (item) => {
    // console.log("item: ", item);

    let params = {
      code_id: item.code_id,
      rate_id: item.rate_id,
    };

    apiAccountMt4UpdateRate(params)
      .then((res) => {
        // const data = res.data;
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doApiUpdateTypeCurrency = (item) => {
    // console.log("item: ", item);

    let params = {
      code_id: item.code_id,
      type_currency: item.type_currency,
    };

    apiAccountMt4UpdateTypeCurrency(params)
      .then((res) => {
        // const data = res.data;
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderTableAccount = () => {
    return (
      <div className="box-container">
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.tableData}
          columns={this.tableColumns}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      </div>
    );
  };

  SetAddAccount = () => {
    this.setState({
      setOpenDialogAdd: true,
    });
  };

  HandleCloseAddAccount = () => {
    this.setState({
      setOpenDialogAdd: false,
      selectedAccountId: null,
      // left: this.state.available,
    });
  };

  renderAddAccountAvailable = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenDialogAdd}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Add Account
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.HandleCloseAddAccount()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {this.renderTransferList()}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.HandleCloseAddAccount()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={this.state.showLoadingSubmit === true ? null : <Save />}
            onClick={() => this.doSubmitUpdate()}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== ""
              ? value.account_number + " (" + value.alias + ")"
              : value.account_number
          } -label`;

          return (
            <ListItem
              key={value.account_number}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== ""
                    ? value.account_number + " (" + value.alias + ")"
                    : value.account_number
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  handleChangeAccountNumber = (selectGroupAccountNumberId) => {
    // console.log("selectGroupAccountNumberId: ", selectGroupAccountNumberId);

    this.setState({
      selectGroupAccountNumberId,
    });
  };

  hanldeChangeSearch = (value) => {
    // console.log(value);
    const dataArr = this.state.left;

    const getData = dataArr.filter((x) =>
      x.account_number.toLowerCase().includes(value.toLowerCase())
    );

    if (getData.length > 0 || undefined || null) {
      this.setState({
        left: getData,
      });
    } else {
      // console.log("kosong");
      this.setState({
        left: this.state.available,
      });
    }

    // console.log(getData);
  };

  handleChangeAccount = (selectedAccountId) => {
    // console.log("selectedAccountId: ", selectedAccountId);
    this.setState({
      selectedAccountId: selectedAccountId,
    });
  };

  renderTransferList = () => {
    // const dataArr = this.state.left;

    // for (var i = 0; i < dataArr.length; i++) {
    //   dataArr[i].label =
    //     dataArr[i].account_number + "(" + dataArr[i].alias + ")";
    //   dataArr[i].value = dataArr[i.account_number];
    // }

    // console.log("dataArr: ", dataArr);

    // let rightChecked = this.intersection(
    //   this.state.checked_transfer,
    //   this.state.right
    // );

    // let leftChecked = this.intersection(
    //   this.state.checked_transfer,
    //   this.state.left
    // );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <Select
              isMulti
              classNamePrefix="select"
              className="basic-multi-select"
              placeholder="Select a account"
              value={this.state.selectedAccountId}
              onChange={this.handleChangeAccount}
              options={this.state.optionAccount}
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
        </Grid>
        {/* <Grid item>
          {this.customList("Account Available", this.state.left)}
        </Grid> */}

        {/* <Grid item>
          <div className="telegram-manage-api-desktop">
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </div>
          <div className="telegram-manage-api-mobile" style={{ marginTop: 15 }}>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &#8679;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &#8681;
              </Button>
            </Grid>
          </div>
        </Grid> */}

        {/* <Grid item>{this.customList("Account List", this.state.right)}</Grid> */}
      </Grid>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.setState({ openSuccess: false }), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Unlink Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to unlink account {item.account_number}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doUnlinkAccount(item.account_number)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowUnlinkAccount = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      dataDetail: row,
      alias: row.alias,
      lots: row.lots,
    });
  };

  handleCloseDetail = () => {
    this.setState({
      setOpenEdit: false,
      dataDetail: "",
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      dataDetail: "",
      alias: "",
      mql5Link: "",
      capital: 0,
      lots: "",
    });
  };

  checkData = () => {
    if (this.state.alias === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty alias?",
        titleBody: "Please enter alias",
      });
    } else if (this.state.lots === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty lots size?",
        titleBody: "Please enter lots size",
      });
    } else {
      this.doSubmit();
    }
  };

  doSubmit = () => {
    let params = {
      code_id: this.state.dataDetail.code_id,
      alias: this.state.alias,
      lots: this.state.lots,
      // capital: this.state.capital.replace(/,/g, ""),
      // mql5Link: this.state.mql5Link,
    };

    // console.log(params);

    this.setState({ submitLoading: true });
    apiAccountUpdateAlias(params)
      .then((res) => {
        this.setState({ submitLoading: false });

        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.doLoadData(this.state.code_vps);
          this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        this.setState({ submitLoading: false });

        console.log(err);
      });
  };

  handleAliasChange = (value) => {
    this.setState({ alias: value });
  };

  handleLotsChange = (value) => {
    const validValue = value.replace(/[^0-9.]/g, "");
    const parts = validValue.split(".");
    if (parts.length <= 2) {
      this.setState({ lots: validValue });
    }
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        // onClose={this.handleCloseDetail}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Account"
                : this.state.setOpenEdit === true
                ? "Account Details"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseDetail()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Date Insert
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="date_insert"
                  id="date_insert"
                  value={this.state.dataDetail.date_insert}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Last Timestamp
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="lasttimestamp"
                  id="lasttimestamp"
                  value={this.state.dataDetail.last_timestamp}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Alias
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="alias"
                  id="alias"
                  value={this.state.alias}
                  onChange={(event) =>
                    this.handleAliasChange(event.target.value)
                  }
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Account Number
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="account_number"
                  id="account_number"
                  value={this.state.dataDetail.account_number}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Server Name
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="server_name"
                  id="server_name"
                  value={this.state.dataDetail.server_name}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Leverage
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="balance"
                  id="balance"
                  value={"1:" + this.state.dataDetail.leverage}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Capital
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="balance"
                  id="balance"
                  value={Number(
                    parseFloat(this.state.dataDetail.capital).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Balance
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="balance"
                  id="balance"
                  value={Number(
                    parseFloat(this.state.dataDetail.balance).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Equity
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="equity"
                  id="equity"
                  value={Number(
                    parseFloat(this.state.dataDetail.equity).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Lots Size
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="lots"
                  id="lots"
                  value={this.state.lots}
                  onChange={(event) =>
                    this.handleLotsChange(event.target.value)
                  }
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Margin Usage
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="margin_usage"
                  id="margin_usage"
                  value={Number(
                    parseFloat(this.state.dataDetail.margin_used).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Margin %
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="margin_percent"
                  id="margin_percent"
                  value={
                    Number(
                      parseFloat(this.state.dataDetail.margin_percent).toFixed(
                        2
                      )
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    }) + "%"
                  }
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Sum From Buy
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="jml_open_from_buy"
                  id="jml_open_from_buy"
                  value={parseFloat(
                    this.state.dataDetail.jml_open_from_buy
                  ).toFixed(2)}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Sum From Sell
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="jml_open_from_sell"
                  id="jml_open_from_sell"
                  value={parseFloat(
                    this.state.dataDetail.jml_open_from_sell
                  ).toFixed(2)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <ButtonUI
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#000",
            }}
            startIcon={<ArrowBackIos />}
            // onClick={() => this.props.history.push("/panel/vps")}
            onClick={() => this.props.history.goBack()}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 12,
                color: "#fff",
                textTransform: "capitalize",
                marginLeft: -10,
              }}
            >
              Back
            </Typography>
          </ButtonUI>{" "}
          <Typography
            component="span"
            variant="h2"
            style={{
              fontSize: 16,
              color: "#000",
              fontWeight: "bold",
              textTransform: "capitalize",
              float: "right",
            }}
          >
            VPS Details
          </Typography>
          <span className="dash">&nbsp;&nbsp;</span>
        </div>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="telegram-manage-api-desktop">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Last Timestamp
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="last_timestamp"
                    id="last_timestamp"
                    value={this.state.last_timestamp}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="status"
                    id="status"
                    style={{
                      color:
                        this.state.vps_status === "ON" ||
                        this.state.vps_status === "Online"
                          ? "green"
                          : this.state.vps_status === "Busy"
                          ? "darkorange"
                          : "red",
                      fontWeight: "600",
                    }}
                    value={this.state.vps_status}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_id"
                    id="vps_id"
                    value={this.state.vps_id}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS Alias
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_name"
                    id="vps_name"
                    value={this.state.vps_name}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    CPU Usage
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="cpu_usage"
                    id="cpu_usage"
                    value={this.state.cpu_usage + "%"}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Memory Usage
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="memory_usage"
                    id="memory_usage"
                    value={this.state.memory_usage + "%"}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Storage Usage
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="storage_usage"
                    id="storage_usage"
                    value={this.state.storage_usage + "%"}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS IP
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_ip"
                    id="vps_ip"
                    value={this.state.vps_ip}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS Port
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_port"
                    id="vps_port"
                    value={this.state.vps_port}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS Network Usage
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_network_usage"
                    id="vps_network_usage"
                    value={formatBytes(this.state.vps_network_usage)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="telegram-manage-api-mobile">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Last Timestamp
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="last_timestamp"
                    id="last_timestamp"
                    value={this.state.last_timestamp}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="status"
                    id="status"
                    style={{
                      color: this.state.status === "ON" ? "green" : "red",
                      fontWeight: "600",
                    }}
                    value={this.state.status}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS ID
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_id"
                    id="vps_id"
                    value={this.state.vps_id}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_name"
                    id="vps_name"
                    value={this.state.vps_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    CPU Usage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="cpu_usage"
                    id="cpu_usage"
                    value={this.state.cpu_usage + "%"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Memory Usage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="memory_usage"
                    id="memory_usage"
                    value={this.state.memory_usage + "%"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Storage Usage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="storage_usage"
                    id="storage_usage"
                    value={this.state.storage_usage + "%"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS IP
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_ip"
                    id="vps_ip"
                    value={this.state.vps_ip}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    VPS Network Usage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="vps_network_usage"
                    id="vps_network_usage"
                    value={formatBytes(this.state.vps_network_usage)}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="page-header">
            <ButtonUI
              variant="contained"
              style={{
                backgroundColor: "#000",
                float: "right",
              }}
              startIcon={<AddBox />}
              onClick={() => this.SetAddAccount()}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: 10,
                }}
              >
                Add Account
              </Typography>
            </ButtonUI>{" "}
            <Typography
              component="span"
              variant="h2"
              style={{
                fontSize: 16,
                color: "#000",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Account List
            </Typography>
            <span className="dash">&nbsp;&nbsp;</span>
          </div>
          <br></br>
          {this.renderSuccess()}
          <br></br>
          {this.renderTableAccount()}
          <br></br>
        </div>
        {this.renderAddAccountAvailable()}
        {this.renderValidationAlert()}

        {this.renderDialogAddNew()}
        {this.renderRemoveItemInfo()}
      </div>
    );
  }
}
export default VpsDetailPage;
