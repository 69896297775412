import React, { Component } from "react";
import {
  apiAccountListArchived,
  apiAccountDelete,
  apiAccountDeleteAll,
  apiAccountUpdateAlias,
  apiRateOption,
  apiAccountMt4UpdateRate,
} from "../../Service/api";
import dayjs from "dayjs";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  Checkbox,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Refresh,
  Assignment,
  Delete,
  Close,
  Save,
  Cancel,
  Check,
  DeleteOutline,
} from "@mui/icons-material";
import { formatNumber } from "../../../global.js";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class ArchivedPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      alias: "",
      mql5Link: "",
      capital: 0,
      rateOption: [],
      rateSelected: null,
      loginInfo: "",
    };

    this.tableColumns = [
      {
        Header: "Last Timestamp",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_timestamp",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}{" "}
              </Typography>
            </>
          ),
      },
      {
        Header: "Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 150,
        Cell: (e) => {
          const textColor = e.original.status === "OFF" ? "red" : "black";

          return (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: textColor,
                }}
              >
                {e.original.account_number}
              </Typography>
            </>
          );
        },
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {e.original.alias}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {e.original.alias === null ? "-" : e.original.alias}
              </Typography>
            </>
          ),
      },
      {
        Header: "Equity",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "equity",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 180,
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {Number(
                  parseFloat(e.original.equity).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {Number(
                  parseFloat(e.original.equity).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </>
          ),
      },
      {
        Header: "%",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "percentage",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 100,
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {Number(
                  parseFloat(e.original.percentage).toFixed(0)
                ).toLocaleString("en", {
                  minimumFractionDigits: 0,
                })}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {Number(
                  parseFloat(e.original.percentage).toFixed(0)
                ).toLocaleString("en", {
                  minimumFractionDigits: 0,
                })}
              </Typography>
            </>
          ),
      },

      {
        Header: "Rate",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "rate_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => {
          return (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
              }}
            >
              {e.original.rate_name === null ? "-" : e.original.rate_name}
            </Typography>
          );
        },
      },

      {
        Header: "Last VPS",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => {
          const handleClick = () => {
            if (
              e.original.last_vps !== null &&
              e.original.last_vps !== "" &&
              e.original.last_vps !== undefined
            ) {
              this.props.history.push(
                "/panel/vps-detail/" + e.original.code_vps
              );
            }
          };
          return (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              {e.original.vps_name === null ? "-" : e.original.vps_name}
            </Typography>
          );
        },
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
            &nbsp;
            {this.state.loginInfo.logintype === 1 ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#ff0000",
                }}
                startIcon={<Delete />}
                onClick={() => this.doRowDeleteInfo(e.original)}
              >
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Remove
                </Typography>
              </Button>
            ) : null}
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadData();
    this.doLoadOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadOption = () => {
    apiRateOption()
      .then((res) => {
        const data = res.data;
        // console.log(data);
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              rateOption: data.records,
            });
          } else {
            this.setState({
              rateOption: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doApiUpdateRate = (item) => {
    // console.log("item: ", item);

    let params = {
      code_id: item.code_id,
      rate_id: item.rate_id,
    };

    apiAccountMt4UpdateRate(params)
      .then((res) => {
        // const data = res.data;
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadData = () => {
    this.props.doLoading();

    apiAccountListArchived()
      .then((res) => {
        this.props.doLoading();

        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = data.records;
            // console.log("total account : ", temp.length);
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].vpsName = "";
              temp[i].percentage = (temp[i].equity / temp[i].balance) * 100;
              temp[i].strPercentage = Number(
                parseFloat((temp[i].equity / temp[i].balance) * 100).toFixed(0)
              ).toLocaleString("en", {
                minimumFractionDigits: 0,
              });
            }

            const filterAccountVps = temp.filter(
              (item) => item.vps.length === 0
            );

            this.setState({ tableData: filterAccountVps });
          } else {
            this.setState({ tableData: [] });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      code_id: this.state.dataDetail.code_id,
      alias: this.state.alias,
      capital: this.state.capital.replace(/,/g, ""),
      mql5Link: this.state.mql5Link,
    };

    apiAccountUpdateAlias(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doDeleteAccount = (code_id) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiAccountDelete(code_id)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  pressDeleteAll = () => {
    // console.log(this.state.selectRowData);
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    this.setState({ showLoadingDeleteAll: true });
    apiAccountDeleteAll(params)
      .then((res) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Telegram Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete the account?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.LoadingInvite === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove account {item.account_number}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteAccount(item.code_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      dataDetail: row,
      alias: row.alias,
      mql5Link: row.mql5_link,
      capital: Number(parseFloat(row.capital).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      }),
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
      tableData: [],
    });
    this.doLoadData();
  };

  pressSync = () => {};

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      dataDetail: "",
      alias: "",
      mql5Link: "",
      capital: 0,
      left: this.state.available,
    });
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== "" ? value.alias : value.phonenumber
          } -label`;

          return (
            <ListItem
              key={value.manage_id}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== "" ? value.alias : value.phonenumber
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  handleUpdateCapital = (e) => {
    var value = e.target.value;

    this.setState({
      capital: formatNumber(value),
    });
  };

  renderTransferList = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{this.customList("Available", this.state.left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{this.customList("Account List", this.state.right)}</Grid>
      </Grid>
    );
  };

  checkData = () => {
    if (this.state.alias === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty alias?",
        titleBody: "Please enter alias",
      });
    } else {
      this.doSubmit();
    }
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Account"
                : this.state.setOpenEdit === true
                ? "Account Details"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <div className="telegram-manage-api-desktop">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Last Timestamp
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="lasttimestamp"
                    id="lasttimestamp"
                    value={this.state.dataDetail.last_timestamp}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Alias
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="alias"
                    id="alias"
                    value={this.state.alias}
                    onChange={(event) =>
                      this.setState({ alias: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Account Number
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="account_number"
                    id="account_number"
                    value={this.state.dataDetail.account_number}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Server Name
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="server_name"
                    id="server_name"
                    value={this.state.dataDetail.server_name}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Leverage
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="leverage"
                    id="leverage"
                    value={"1:" + this.state.dataDetail.leverage}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Capital
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="capital"
                    id="capital"
                    value={this.state.capital}
                    onChange={this.handleUpdateCapital}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Balance
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="balance"
                    id="balance"
                    value={Number(
                      parseFloat(this.state.dataDetail.balance).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Equity
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="equity"
                    id="equity"
                    value={Number(
                      parseFloat(this.state.dataDetail.equity).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Margin Usage
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="margin_usage"
                    id="margin_usage"
                    value={Number(
                      parseFloat(this.state.dataDetail.margin_used).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Margin %
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="margin_percent"
                    id="margin_percent"
                    value={
                      Number(
                        parseFloat(
                          this.state.dataDetail.margin_percent
                        ).toFixed(2)
                      ).toLocaleString("en", {
                        minimumFractionDigits: 2,
                      }) + "%"
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Sum From Buy
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="jml_open_from_buy"
                    id="jml_open_from_buy"
                    value={this.state.dataDetail.jml_open_from_buy}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Sum From Sell
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="jml_open_from_sell"
                    id="jml_open_from_sell"
                    value={this.state.dataDetail.jml_open_from_sell}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Mql5 Link
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="mql5Link"
                    id="mql5Link"
                    value={this.state.mql5Link}
                    onChange={(event) =>
                      this.setState({ mql5Link: event.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="telegram-manage-api-mobile">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Last Timestamp
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="lasttimestamp"
                    id="lasttimestamp"
                    value={this.state.dataDetail.last_timestamp}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Alias
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="alias"
                    id="alias"
                    value={this.state.alias}
                    onChange={(event) =>
                      this.setState({ alias: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Account Number
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="account_number"
                    id="account_number"
                    value={this.state.dataDetail.account_number}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Server Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="server_name"
                    id="server_name"
                    value={this.state.dataDetail.server_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Leverage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="leverage"
                    id="leverage"
                    value={"1:" + this.state.dataDetail.leverage}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Capital
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="capital"
                    id="capital"
                    value={this.state.capital}
                    onChange={this.handleUpdateCapital}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Balance
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="balance"
                    id="balance"
                    value={Number(
                      parseFloat(this.state.dataDetail.balance).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Equity
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="equity"
                    id="equity"
                    value={Number(
                      parseFloat(this.state.dataDetail.equity).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Margin Usage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="margin_usage"
                    id="margin_usage"
                    value={Number(
                      parseFloat(this.state.dataDetail.margin_used).toFixed(2)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Margin %
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="margin_percent"
                    id="margin_percent"
                    value={
                      Number(
                        parseFloat(
                          this.state.dataDetail.margin_percent
                        ).toFixed(2)
                      ).toLocaleString("en", {
                        minimumFractionDigits: 2,
                      }) + "%"
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Sum From Buy
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="jml_open_from_buy"
                    id="jml_open_from_buy"
                    value={this.state.dataDetail.jml_open_from_buy}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Sum From Sell
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="jml_open_from_sell"
                    id="jml_open_from_sell"
                    value={this.state.dataDetail.jml_open_from_sell}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    http://localhost:3000/panel/dashboard Mql5 Link
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="mql5Link"
                    id="mql5Link"
                    value={this.state.mql5Link}
                    onChange={(event) =>
                      this.setState({ mql5Link: event.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        </DialogContent>
        {this.state.loginInfo.logintype === 1 ? (
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.handleCloseAddNew()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Typography>
            </Button>
            &nbsp;&nbsp;
            <Button
              disabled={this.state.submitLoading}
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.checkData()}
            >
              {this.state.submitLoading === true ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Save
                </Typography>
              )}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Archived Accounts
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                      {this.state.selectRowData.length > 0 ? (
                        <div style={{ marginRight: 0, marginLeft: 16 }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<DeleteOutline />}
                            onClick={() =>
                              this.setState({ openAlertDeleteAll: true })
                            }
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Delete
                            </Typography>
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}

        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default ArchivedPage;
