import React, { Component } from "react";
import DefaultUserImg from "../../../Assets/Images/user-default-image.png";
import { Typography, Badge } from "@mui/material";
import "./Header.style.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Dashboard,
  AccountBox,
  // AccountCircle,
  Settings,
  // ManageAccounts,
  Cloud,
  Telegram,
  // SupervisedUserCircle,
  // RecentActors,
  // GroupAdd,
  // SettingsSystemDaydream,
  Summarize,
  Fitbit,
  Sell,
  StackedLineChart,
  TrendingDown,
  Groups,
  Receipt,
  LocalAtm,
  Archive,
  AutoFixHigh,
  MonitorHeart,
  CandlestickChart,
  Event,
  Toc,
  AddToQueue,
  AutoMode,
  Circle,
  AccessTime,
  CloudSync,
} from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { apiCountJobList } from "../../Service/api";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const drawerWidth = 180;

const badgeStyle = {
  "& .MuiBadge-badge": {
    right: `${-10}px`,
  },
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#1F324B",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#1F324B",
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#1F324B",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#1F324B",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

class Header extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activeLanguage, "header");
    this.state = {
      loginInfo: props.loginInfo,
      communityList: props.communityList,
      community: props.community,
      showCommunityOption: false,
      communityOptionJustOpen: false,
      communityName: "",
      open: false,
      role: 0,
      menus: [],
      currentParent: 0,
      currentChild: -1,
      count: 0,
      countJobList: 0,
    };
  }

  componentDidMount = () => {
    document.addEventListener("click", this.eventDocumentClick);
    this.getRoleData();
    this.loadInitialCount();
    this.countJoblist();
    this.intervalNotif = setInterval(() => {
      this.loadInitialCount();
    }, 3000);
    this.intervalCount = setInterval(() => {
      this.countJoblist();
    }, 60000);
  };
  componentWillUnmount() {
    document.removeEventListener("click", this.eventDocumentClick);
  }

  componentWillReceiveProps(props) {
    this.setState({
      loginInfo: props.loginInfo,
      community: props.community,
      communityList: props.communityList,
    });

    if (props.community !== this.state.community) {
      this.selectRole(props.loginInfo, props.community);
    }

    this.setState({ loginInfo: props.loginInfo, community: props.community });
  }

  loadInitialCount = () => {
    const counts = localStorage.getItem("count");
    const notif = JSON.parse(counts);
    if (notif) {
      this.setState({ countJobList: notif });
    }
  };

  countJoblist = () => {
    apiCountJobList()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          const count = data.count;
          localStorage.setItem("count", JSON.stringify(count));
          this.setState({
            countJobList: count,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
    });
  };

  eventDocumentClick = () => {
    if (this.state.showCommunityOption) {
      if (this.state.communityOptionJustOpen) {
        this.setState({ communityOptionJustOpen: false });
      } else {
        this.setState({ showCommunityOption: !this.state.showCommunityOption });
      }
    }
  };

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    if (
      loginInfo === undefined ||
      loginInfo === null ||
      loginInfo === "" ||
      loginInfo === "undefined" ||
      loginInfo === "null"
    ) {
      alert("Please login again");
      this.props.history.replace("/");
    } else {
      loginInfo = JSON.parse(loginInfo);
    }
  };

  doToogleMenuProfile = () => {
    this.props.toogleMenuProfile();
  };

  doToogleCommunityOption = () => {
    //this.props.toogleCommunityOption();
    this.setState({
      showCommunityOption: !this.state.showCommunityOption,
      communityOptionJustOpen: true,
    });
  };

  doChangeCommunity = (community) => {
    if (community.communityid !== this.state.community.communityid) {
      this.props.changeCommunity(community);
    }
  };

  menuSuperAdmin = (community) => {
    let tmpMenu = [];
    tmpMenu.push(
      {
        id: 0,
        label: "Dashboard",
        to: "/panel/dashboard",
        openChild: false,
        childs: [],
      },
      {
        id: 1,
        label: "Trade Chart",
        to: "/panel/trade-chart",
        openChild: false,
        childs: [],
      },
      // {
      //   id: 18,
      //   label: "Trade Activity",
      //   to: "/panel/trade-activity",
      //   openChild: false,
      //   childs: [],
      // },
      {
        id: 2,
        label: "VPS",
        to: "/panel/vps",
        openChild: false,
        childs: [],
      },
      {
        id: 3,
        label: "Accounts",
        to: "/panel/accounts",
        openChild: false,
        childs: [],
      },
      {
        id: 4,
        label: "Archived",
        to: "/panel/archived",
        openChild: false,
        childs: [],
      },
      {
        id: 5,
        label: "Adjustment",
        to: "/panel/adjustment",
        openChild: false,
        childs: [],
      },
      {
        id: 6,
        label: "Daily",
        to: "",
        openChild: false,
        childs: [
          {
            id: 60,
            label: "Daily Balance",
            to: "/panel/daily-balance",
          },
          {
            id: 61,
            label: "Daily Profit",
            to: "/panel/daily-profit",
          },
          {
            id: 62,
            label: "Daily Withdrawal",
            to: "/panel/daily-withdrawal",
          },
          {
            id: 63,
            label: "Daily Deposit",
            to: "/panel/daily-deposit",
          },
        ],
      },

      {
        id: 7,
        label: "Agent",
        to: "/panel/agent",
        openChild: false,
        childs: [],
      },
      {
        id: 8,
        label: "Performance",
        to: "/panel/performance",
        openChild: false,
        childs: [],
      },
      {
        id: 9,
        label: "Invoice",
        to: "",
        openChild: false,
        childs: [
          {
            id: 91,
            label: "Agent",
            to: "/panel/invoice/agent",
          },
          {
            id: 92,
            label: "Member",
            to: "/panel/invoice/member",
          },
        ],
      },
      {
        id: 10,
        label: "Rate",
        to: "/panel/rate",
        openChild: false,
        childs: [],
      },
      {
        id: 11,
        label: "Master Slave",
        to: "",
        openChild: false,
        childs: [
          {
            id: 111,
            label: "Setting",
            to: "/panel/master-slave-setting",
          },
          {
            id: 112,
            label: "List",
            to: "/panel/master-slave-list",
          },
        ],
      },
      {
        id: 12,
        label: "VPS Migration",
        to: "/panel/vps-migration",
        openChild: false,
        childs: [],
      },
      {
        id: 13,
        label: "Job",
        to: "",
        openChild: false,
        childs: [
          {
            id: 131,
            label: "List",
            to: "/panel/job-list",
          },
          {
            id: 132,
            label: "Log",
            to: "/panel/job-log",
          },
        ],
      },
      {
        id: 14,
        label: "Automation",
        to: "",
        openChild: false,
        childs: [
          {
            id: 141,
            label: "Log",
            to: "/panel/automation-log",
          },
          {
            id: 142,
            label: "Issues",
            to: "/panel/automation-issues",
          },
        ],
      },
      {
        id: 15,
        label: "Clients",
        to: "/panel/clients",
        openChild: false,
        childs: [],
      },
      {
        id: 16,
        label: "Settings",
        to: "",
        openChild: false,
        childs: [
          {
            id: 161,
            label: "User",
            to: "/panel/user",
          },
        ],
      }
    );

    this.setState({
      menus: tmpMenu,
    });
  };

  menuAgent = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: "Dashboard",
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 1,
          label: "Accounts",
          to: "/panel/accounts",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Daily Balance",
          to: "/panel/daily-balance",
          openChild: false,
          childs: [],
        },
        {
          id: 3,
          label: "Daily Profit",
          to: "/panel/daily-profit",
          openChild: false,
          childs: [],
        },
        {
          id: 4,
          label: "Invoice",
          to: "/panel/invoice",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  agentMembers = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: "Dashboard",
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 1,
          label: "Accounts",
          to: "/panel/accounts",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Daily Balance",
          to: "/panel/daily-balance",
          openChild: false,
          childs: [],
        },
        {
          id: 3,
          label: "Daily Profit",
          to: "/panel/daily-profit",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  menuMember = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: "Dashboard",
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 1,
          label: "Accounts",
          to: "/panel/accounts",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Daily Balance",
          to: "/panel/daily-balance",
          openChild: false,
          childs: [],
        },
        {
          id: 3,
          label: "Daily Profit",
          to: "/panel/daily-profit",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  selectRole = (loginInfo, community) => {
    this.setState({ role: loginInfo.logintype });
    this.selectType(loginInfo.logintype, community, loginInfo.agent);
  };

  selectType = (role, community) => {
    if (role === 1) {
      this.menuSuperAdmin(community);
    } else if (role === 2) {
      this.menuAgent(community);
    } else if (role === 3) {
      this.agentMembers(community);
    } else if (role === 4) {
      this.menuMember(community);
    }
  };

  toogleSubMenu = (menu, idx) => {
    let menus = this.state.menus;

    menus[idx].openChild = !menus[idx].openChild;
    this.setState({ menus: menus });
  };

  updateParent = (id) => {
    this.setState({ currentParent: id, currentChild: -1 });
    this.handleDrawerClose();
  };

  updateChild = (id) => {
    this.setState({ currentChild: id, currentParent: -1, open: false });
    this.handleDrawerClose();
  };

  eventDocumentClick = () => {
    if (this.state.showCommunityOption) {
      if (this.state.communityOptionJustOpen) {
        this.setState({ communityOptionJustOpen: false });
      } else {
        this.setState({ showCommunityOption: !this.state.showCommunityOption });
      }
    }
  };

  doToogleMenuProfile = () => {
    this.props.toogleMenuProfile();
  };

  doToogleCommunityOption = () => {
    //this.props.toogleCommunityOption();
    this.setState({
      showCommunityOption: !this.state.showCommunityOption,
      communityOptionJustOpen: true,
    });
  };

  doChangeCommunity = (community) => {
    if (community.communityid !== this.state.community.communityid) {
      this.props.changeCommunity(community);
    }
  };

  renderCommunityOption = () => {
    if (this.state.showCommunityOption) {
      return (
        <div className="select-community-option-container">
          {this.state.communityList.map((comm, i) => (
            <div
              key={comm.communityid}
              className="select-community-option"
              onClick={() => this.doChangeCommunity(comm)}
            >
              {comm.communityname}
            </div>
          ))}
        </div>
      );
    }
  };

  renderProfilePic = () => {
    if (
      this.state.loginInfo.profilepic === "" ||
      this.state.loginInfo.profilepic === undefined
    ) {
      return <img src={DefaultUserImg} alt="defaultpic" />;
    } else
      return <img src={this.state.loginInfo.profilepic} alt="profilepic" />;
  };

  renderforBadge = (submenu) => {
    // console.log(submenu);
    if (submenu.label === "List") {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Badge
            badgeContent={this.state.countJobList}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={badgeStyle}
          >
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {submenu.label}
            </Typography>
          </Badge>
        </div>
      );
    } else {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
        </div>
      );
    }
  };

  _renderSubMenu = (menu, idx) => {
    if (menu.openChild) {
      if (menu.label === "Job" && menu.childs[0].label === "List") {
        return (
          <div className="submenu-container">
            {menu.childs.map((submenu, i) => (
              <Link
                key={submenu.id}
                to={submenu.to}
                onClick={() => this.updateChild(submenu.id)}
                className={`link-custom`}
              >
                {this.renderforBadge(submenu)}
              </Link>
            ))}
          </div>
        );
      }
      return (
        <div className="submenu-container">
          {menu.childs.map((submenu, i) => (
            <Link
              key={submenu.id}
              to={submenu.to}
              onClick={() => this.updateChild(submenu.id)}
              className={`link-custom`}
            >
              <div
                className={`submenu ${
                  this.state.currentChild === submenu.id ? "menu-active" : ""
                } `}
              >
                {this.renderSubMenuIcon(submenu.label)}{" "}
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#fff",
                      fontWeight: "bolder",
                    })
                  }
                >
                  {submenu.label}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  _renderMenu = (menu, i) => {
    if (menu.to === "") {
      if (menu.label === "Job") {
        return (
          <div key={menu.id}>
            <div
              className="menu menu-parent"
              onClick={() => this.toogleSubMenu(menu, i)}
            >
              {this.renderIconMenu(menu.label)}{" "}
              <Badge
                badgeContent={this.state.countJobList}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={badgeStyle}
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#fff",
                      fontWeight: "bolder",
                    })
                  }
                >
                  {menu.label}
                </Typography>
              </Badge>
              <div className="chevron-container">
                {this._renderChevronIcon(menu)}
              </div>
            </div>
            {this._renderSubMenu(menu, i)}
          </div>
        );
      }
      return (
        <div key={menu.id}>
          <div
            className="menu menu-parent"
            onClick={() => this.toogleSubMenu(menu, i)}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>
            <div className="chevron-container">
              {this._renderChevronIcon(menu)}
            </div>
          </div>
          {this._renderSubMenu(menu, i)}
        </div>
      );
    } else {
      return (
        <Link
          key={menu.id}
          to={menu.to}
          onClick={() => this.updateParent(menu.id)}
          className={`link-custom`}
        >
          <div
            className={`menu ${
              this.state.currentParent === menu.id ? "menu-active" : ""
            } `}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>{" "}
            <div className="chevron-container"></div>
          </div>
        </Link>
      );
    }
  };

  _renderChevronIcon = (menu) => {
    if (menu.openChild) {
      return <FontAwesomeIcon icon="chevron-down" />;
    } else {
      return <FontAwesomeIcon icon="chevron-left" />;
    }
  };

  renderIconMenu = (label) => {
    if (label === "Dashboard") {
      return <Dashboard />;
    } else if (label === "Accounts") {
      return <AccountBox />;
    } else if (label === "VPS") {
      return <Cloud />;
    } else if (label === "Settings") {
      return <Settings />;
    } else if (label === "Telegram") {
      return <Telegram />;
    } else if (label === "Daily Balance") {
      return <Summarize />;
    } else if (label === "Daily Profit") {
      return <StackedLineChart />;
    } else if (label === "Daily Withdrawal") {
      return <TrendingDown />;
    } else if (label === "Master Slave") {
      return <Fitbit />;
    } else if (label === "Agent") {
      return <Groups />;
    } else if (label === "Invoice") {
      return <Receipt />;
    } else if (label === "Buy & Sell") {
      return <Sell />;
    } else if (label === "Rate") {
      return <LocalAtm />;
    } else if (label === "Archived") {
      return <Archive />;
    } else if (label === "Adjustment") {
      return <AutoFixHigh />;
    } else if (label === "Performance") {
      return <MonitorHeart />;
    } else if (label === "Trade Chart") {
      return <CandlestickChart />;
    } else if (label === "Daily") {
      return <Event />;
    } else if (label === "Job") {
      return <Toc />;
    } else if (label === "Clients") {
      return <AddToQueue />;
    } else if (label === "Automation") {
      return <AutoMode />;
    } else if (label === "Trade Activity") {
      return <AccessTime />;
    } else if (label === "VPS Migration") {
      return <CloudSync />;
    }
  };

  renderSubMenuIcon = (label) => {
    return <Circle fontSize="small" />;
  };

  render() {
    return (
      <div className="header-container">
        <div className="header-screen-desktop">
          <div className="header-title">
            {" "}
            <img
              style={{
                width: "180px",
                height: "40px",
              }}
              src={require("../../../Assets/Images/3DVAM.png")}
              alt="logo"
            />
          </div>
          <div
            className="header-profilepic"
            onClick={() => this.doToogleMenuProfile()}
          >
            <div className="header-profileimg">{this.renderProfilePic()}</div>
          </div>
          <div className="header-profilename">
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#000",
                  fontWeight: "bolder",
                })
              }
            >
              {this.state.loginInfo.name}
            </Typography>
          </div>
        </div>
        <div className="header-screen-mobile">
          <CssBaseline />
          <AppBar position="fixed" open={this.state.open}>
            <Toolbar
              style={{
                backgroundColor: "white",
                paddingRight: "0px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "space-between",
                alignItems: "center",
                height: "75px",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                sx={{ mr: 0, ...(this.state.open && { display: "none" }) }}
              >
                <img
                  style={{
                    width: 65,
                    height: 35,
                  }}
                  src={require("../../../Assets/Images/3DVAM-mobile.png")}
                  alt="logo"
                />
              </IconButton>
              <div
                className="header-profilepic-mobile"
                onClick={() => this.doToogleMenuProfile()}
              >
                <div className="header-profileimg">
                  {this.renderProfilePic()}
                </div>
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#141101",
                      fontWeight: "bolder",
                      fontSize: 12,
                      marginLeft: 5,
                    })
                  }
                >
                  {this.state.loginInfo.name}
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={this.state.open}
            style={{ backgroundColor: "#1F324B" }}
          >
            <DrawerHeader>
              <IconButton
                style={{
                  backgroundColor: "red",
                  borderRadius: 10,
                  marginRight: 5,
                }}
                onClick={this.handleDrawerClose}
              >
                <ChevronLeftIcon style={{ color: "#fff" }} />
                <Typography
                  style={{ color: "#fff", fontSize: "13px" }}
                  variant="h6"
                  noWrap
                  component="div"
                >
                  Minimize
                </Typography>
              </IconButton>
            </DrawerHeader>
            <Divider />
            <div style={{ marginTop: 5 }}>
              {this.state.menus.map((menu, i) => this._renderMenu(menu, i))}
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}
export default Header;
