import React, { Component } from "react";
import { apiPerformanceProfit } from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class PerformancePage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      total_all_profit: 0,
      total_all_fee: 0,
    };

    this.tableColumns = [
      {
        Header: "Agent",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "agent_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.agent_name}
          </Typography>
        ),
      },
      {
        Header: "Current Month",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "month",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.month}
          </Typography>
        ),
      },
      {
        Header: "Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "total_profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color: "green",
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(e.original.total_profit)}
            </Typography>
          </>
        ),
      },
      {
        Header: "Fee",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "fee_profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color: "#1273DE",
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(e.original.fee_profit)}
            </Typography>
          </>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiPerformanceProfit()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const temp = data.records;

            const total_all_profit = temp.reduce(
              (acc, record) => acc + record.total_profit,
              0
            );
            const total_all_fee = temp.reduce(
              (acc, record) => acc + record.fee_profit,
              0
            );

            this.setState({
              tableData: temp,
              total_all_profit: total_all_profit,
              total_all_fee: total_all_fee,
            });
          } else {
            this.setState({
              tableData: [],
              total_all_profit: 0,
              total_all_fee: 0,
            });
          }
        } else {
          this.setState({
            tableData: [],
            total_all_profit: 0,
            total_all_fee: 0,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Performance Agent
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.doLoadData()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Grid Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "center" }}>
            <div className="dashboard-panel">
              <List>
                <ListItem
                  style={{
                    padding: 0,
                  }}
                  alignItems="flex-start"
                >
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            marginTop: 6,
                            fontSize: 19,
                            color: "green",
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.total_all_profit)}
                        </Typography>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            marginTop: 5,
                            fontSize: 12,
                            color: "#000",
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          Total Profit
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "center" }}>
            <div className="dashboard-panel">
              <List>
                <ListItem
                  style={{
                    padding: 0,
                  }}
                  alignItems="flex-start"
                >
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            marginTop: 6,
                            fontSize: 19,
                            color: "#1273DE",
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.total_all_fee)}
                        </Typography>
                        <Typography
                          component="span"
                          variant="h1"
                          style={{
                            marginTop: 5,
                            fontSize: 12,
                            color: "#000",
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          Total Fee
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
      </Box>
    );
  }
}
export default PerformancePage;
