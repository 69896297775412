import React, { Component } from "react";
import {
  apiDailyBalanceDate,
  apiAccountOption,
  apiDailyBalance,
  apiDailyBalanceByAgent,
  apiDailyBalanceDateByAgent,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Input } from "reactstrap";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import { Storage, Close, Refresh, Cancel, Save } from "@mui/icons-material";
import moment from "moment";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { isNegative, getPreviousDay } from "../../../global.js";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class DailyBalance extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      startDate: moment().locale("id").format("YYYY-MM-DD"),
      endDate: moment().locale("id").format("YYYY-MM-DD"),
      selectGroupAccountNumberId: null,
      DataGroupAccountNumber: [],
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      account_number: "",
      alias: "",
      type_currency: "Cents",
      capital: 0,
      total_profit: 0,
      arrDataDailyBalance: [],
      setOpenFilter: false,
      loginInfo: "",
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_date",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        Header: "Balance",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "balance",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
              }}
            >
              {`${this.state.type_currency === "Standart" ? "$" : ""}${Number(
                parseFloat(e.original.balance).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}${this.state.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
      {
        Header: "Profit/Loss",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.profit) === true
                    ? "red"
                    : e.original.profit === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.profit) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.profit)}`
                      : `${Number(
                          parseFloat(e.original.profit).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.profit === 0
                ? Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
    ];

    this.tableArrDailyBalanceColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_date",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        Header: "Account Number",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },
      {
        Header: "Balance",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "balance",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
              }}
            >
              {`${e.original.type_currency === "Standart" ? "$" : ""}${Number(
                parseFloat(e.original.balance).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
      {
        Header: "Profit/Loss",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.profit) === true
                    ? "red"
                    : e.original.profit === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.profit) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.profit)}`
                      : `${Number(
                          parseFloat(e.original.profit).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.profit === 0
                ? Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
    ];
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    if (loginInfo.logintype === 1 || loginInfo.logintype === 4) {
      this.doLoadDailyBalance();
      this.doLoadAccountOption();
    } else if (loginInfo.logintype === 2 || loginInfo.logintype === 3) {
      this.doLoadDailyBalanceByAgent(loginInfo.agent.id);
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadAccountOption = () => {
    apiAccountOption()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            // console.log("data.records: ", data.records);
            // console.log("infoAccount: ", this.state.loginInfo.infoAccount);
            // console.log("loginInfo: ", this.state.loginInfo);
            if (this.state.loginInfo.logintype === 4) {
              const filterAccount = data.records.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account.toString() === item1.value
                )
              );

              // console.log("filterAccount: ", filterAccount);
              this.setState({
                DataGroupAccountNumber: filterAccount,
              });
            } else {
              this.setState({
                DataGroupAccountNumber: data.records,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyBalance = () => {
    // if (this.state.arrDataDailyBalance.length === 0) {
    this.props.doLoading();
    // }
    apiDailyBalance()
      .then((res) => {
        // if (this.state.arrDataDailyBalance.length === 0) {
        this.props.doLoading();
        // }
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyBalance = [];
            dataDailyBalance = data.records;

            // console.log("dataDailyBalance: ", dataDailyBalance);

            const format1 = "YYYY-MM-DD";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);
            var day1Previous = moment(
              getPreviousDay(new Date(DateTimeNow), 1)
            ).format(format1);
            // var day2Previous = moment(
            //   getPreviousDay(new Date(DateTimeNow), 2)
            // ).format(format1);

            // console.log(DateTimeNow);
            // console.log(day1Previous);
            // console.log(day2Previous);

            // let previousDate = dataDailyBalance[0];
            // let currentDate = dataDailyBalance.slice(-1).pop();

            // console.log("pop: ", currentDate);

            let arrayCurrentDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === DateTimeNow
            );

            let arrayPreviousDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === day1Previous
            );

            // let arrayPreviousTwoDate = dataDailyBalance.filter(
            //   (obj) => obj.last_update_date === day2Previous
            // );

            for (var a = 0; a < arrayCurrentDate.length; a++) {
              for (var b = 0; b < arrayPreviousDate.length; b++) {
                if (
                  arrayCurrentDate[a].account_number ===
                  arrayPreviousDate[b].account_number
                ) {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  } else {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance -
                      arrayPreviousDate[b].balance;
                  }
                } else {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  }
                }
              }
            }

            if (this.state.loginInfo.logintype === 4) {
              // const newArrOptionAccount = arrayCurrentDate.map((obj) => ({
              //   value: obj.account_number,
              //   label: `${obj.account_number} - (${obj.alias})`,
              //   alias: obj.alias,
              //   type_currency: obj.type_currency,
              //   capital: obj.capital,
              // }));

              const filterDataDaily = arrayCurrentDate.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              // const filterAccount = newArrOptionAccount.filter((item1) =>
              //   this.state.loginInfo.infoAccount.some(
              //     (item2) => item2.account === item1.value
              //   )
              // );

              for (var idx = 0; idx < filterDataDaily.length; idx++) {
                filterDataDaily[idx].id = idx + 1;
              }

              this.setState({
                arrDataDailyBalance: filterDataDaily,
                // DataGroupAccountNumber: filterAccount,
              });
            } else {
              const dataArr = arrayCurrentDate;

              for (var idxx = 0; idxx < dataArr.length; idxx++) {
                dataArr[idxx].id = idxx + 1;
              }

              this.setState({
                arrDataDailyBalance: dataArr,
              });
            }
          }
        }
      })
      .catch((err) => {
        // if (this.state.arrDataDailyBalance.length === 0) {
        this.props.doLoading();
        // }
        console.log(err);
      });
  };

  doLoadDailyBalanceByAgent = (agent_id) => {
    // if (this.state.arrDataDailyBalance.length === 0) {
    this.props.doLoading();
    // }
    apiDailyBalanceByAgent(agent_id)
      .then((res) => {
        // if (this.state.arrDataDailyBalance.length === 0) {
        this.props.doLoading();
        // }
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyBalance = [];
            dataDailyBalance = data.records;

            // console.log("dataDailyBalance: ", dataDailyBalance);

            const format1 = "YYYY-MM-DD";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);
            var day1Previous = moment(
              getPreviousDay(new Date(DateTimeNow), 1)
            ).format(format1);
            // var day2Previous = moment(
            //   getPreviousDay(new Date(DateTimeNow), 2)
            // ).format(format1);

            // console.log(DateTimeNow);
            // console.log(day1Previous);
            // console.log(day2Previous);

            // let previousDate = dataDailyBalance[0];
            // let currentDate = dataDailyBalance.slice(-1).pop();

            // console.log("pop: ", currentDate);

            let arrayCurrentDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === DateTimeNow
            );

            let arrayPreviousDate = dataDailyBalance.filter(
              (obj) => obj.last_update_date === day1Previous
            );

            // let arrayPreviousTwoDate = dataDailyBalance.filter(
            //   (obj) => obj.last_update_date === day2Previous
            // );

            // console.log("arrayPreviousDate: ", arrayPreviousDate);
            // console.log("arrayCurrentDate: ", arrayCurrentDate);

            // for (var a = 0; a < arrayCurrentDate.length; a++) {
            //   for (var b = 0; b < arrayPreviousDate.length; b++) {
            //     for (var c = 0; c < arrayPreviousTwoDate.length; c++) {
            //       if (
            //         arrayCurrentDate[a].account_number ===
            //           arrayPreviousDate[b].account_number &&
            //         arrayCurrentDate[a].account_number !==
            //           arrayPreviousTwoDate[c].account_number
            //       ) {
            //         if (arrayCurrentDate[a].first_input === 1) {
            //           // arrayCurrentDate[a].id = a + 1;
            //           arrayCurrentDate[a].profit =
            //             arrayCurrentDate[a].balance -
            //             arrayCurrentDate[a].capital;
            //         } else {
            //           // arrayCurrentDate[a].id = a + 1;
            //           arrayCurrentDate[a].profit =
            //             arrayCurrentDate[a].balance -
            //             arrayPreviousDate[b].balance;
            //         }
            //       } else if (
            //         arrayCurrentDate[a].account_number !==
            //           arrayPreviousDate[b].account_number &&
            //         arrayCurrentDate[a].account_number ===
            //           arrayPreviousTwoDate[c].account_number
            //       ) {
            //         if (arrayCurrentDate[a].first_input === 1) {
            //           // arrayCurrentDate[a].id = a + 1;
            //           arrayCurrentDate[a].profit =
            //             arrayCurrentDate[a].balance -
            //             arrayCurrentDate[a].capital;
            //         } else {
            //           // arrayCurrentDate[a].id = a + 1;
            //           arrayCurrentDate[a].profit =
            //             arrayCurrentDate[a].balance -
            //             arrayPreviousTwoDate[c].balance;
            //         }
            //       } else {
            //         if (arrayCurrentDate[a].first_input === 1) {
            //           // arrayCurrentDate[a].id = a + 1;
            //           arrayCurrentDate[a].profit =
            //             arrayCurrentDate[a].balance -
            //             arrayCurrentDate[a].capital;
            //         }
            //       }
            //     }
            //   }
            // }

            for (var a = 0; a < arrayCurrentDate.length; a++) {
              for (var b = 0; b < arrayPreviousDate.length; b++) {
                if (
                  arrayCurrentDate[a].account_number ===
                  arrayPreviousDate[b].account_number
                ) {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  } else {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance -
                      arrayPreviousDate[b].balance;
                  }
                } else {
                  if (arrayCurrentDate[a].first_input === 1) {
                    // arrayCurrentDate[a].id = a + 1;
                    arrayCurrentDate[a].profit =
                      arrayCurrentDate[a].balance - arrayCurrentDate[a].capital;
                  }
                }
              }
            }

            const dataArr = arrayCurrentDate;

            // console.log("result: ", arrayCurrentDate);
            // console.log("result dataArr: ", dataArr);
            // timer = setTimeout(() => this.doLoadDailyBalance(), 30000);

            const newArrOptionAccount = dataArr.map((obj) => ({
              value: obj.account_number,
              label: `${obj.account_number} - (${obj.alias})`,
              alias: obj.alias,
              type_currency: obj.type_currency,
              capital: obj.capital,
            }));

            if (this.state.loginInfo.logintype === 3) {
              const filterDataDaily = dataArr.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const filterAccount = newArrOptionAccount.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.value
                )
              );

              for (var idx = 0; idx < filterDataDaily.length; idx++) {
                filterDataDaily[idx].id = idx + 1;
              }

              this.setState({
                arrDataDailyBalance: filterDataDaily,
                DataGroupAccountNumber: filterAccount,
              });
            } else {
              for (var idxx = 0; idxx < dataArr.length; idxx++) {
                dataArr[idxx].id = idxx + 1;
              }

              this.setState({
                arrDataDailyBalance: dataArr,
                DataGroupAccountNumber: newArrOptionAccount,
              });
            }

            // console.log(arrayCurrentDate);
          }
        }
      })
      .catch((err) => {
        // if (this.state.arrDataDailyBalance.length === 0) {
        this.props.doLoading();
        // }
        console.log(err);
      });
  };

  doSeacrhBydate = () => {
    const { selectGroupAccountNumberId } = this.state;
    if (selectGroupAccountNumberId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Account Number?",
        titleBody: "Select account number",
      });
    } else if (this.state.startDate > this.state.endDate) {
      this.setState({
        openValidation: true,
        titleValidation: "Invalid Date?",
        titleBody: "End date is to small from start date",
      });
    } else {
      if (
        this.state.loginInfo.logintype === 1 ||
        this.state.loginInfo.logintype === 4
      ) {
        let params = {
          accountNumber: selectGroupAccountNumberId.value,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };

        this.handleCloseFilter();

        // console.log(params);
        // if (this.state.tableData.length === 0) {
        this.props.doLoading();
        // }
        apiDailyBalanceDate(params)
          .then((res) => {
            // if (this.state.tableData.length === 0) {
            this.props.doLoading();
            // }
            let data = res.data;
            if (data.status === "OK") {
              if (data.records.length > 0) {
                var temp = [];
                temp = data.records;

                const result_temp = temp.reduce((acc, _, i) => {
                  const item = { ...temp[i] };
                  if (i > 0) {
                    const prev = acc.length - 1;
                    const profit_value = item.balance - acc[prev].balance;
                    item.profit = profit_value;
                  } else if (i === 0) {
                    item.profit =
                      item.balance -
                      this.state.selectGroupAccountNumberId.capital;
                  }
                  acc.push(item);
                  return acc;
                }, []);

                // console.log(result_temp);

                let sum_total_profit = 0;

                let perviousDate = this.getPreviousDay(
                  new Date(this.state.startDate)
                );
                let yesterday = moment(perviousDate).utc().format("YYYY-MM-DD");
                // console.log(yesterday);

                let filterDailyBalance = result_temp.filter(
                  (obj) => obj.last_update_date !== yesterday
                );

                for (var i = 0; i < filterDailyBalance.length; i++) {
                  filterDailyBalance[i].id = i + 1;
                  sum_total_profit += filterDailyBalance[i].profit;
                }

                // console.log(filterDailyBalance);
                this.setState({
                  tableData: filterDailyBalance,
                  account_number: this.state.selectGroupAccountNumberId.value,
                  alias: this.state.selectGroupAccountNumberId.alias,
                  capital: this.state.selectGroupAccountNumberId.capital,
                  type_currency:
                    this.state.selectGroupAccountNumberId.type_currency,
                  total_profit: sum_total_profit,
                  arrDataDailyBalance: [],
                });
                // timer = setTimeout(() => this.doLoadData(), 30000);
              }
            }
          })
          .catch((err) => {
            // if (this.state.tableData.length === 0) {
            this.props.doLoading();
            // }
            console.log(err);
          });
      } else if (
        this.state.loginInfo.logintype === 2 ||
        this.state.loginInfo.logintype === 3
      ) {
        let params = {
          accountNumber: selectGroupAccountNumberId.value,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          agent_id: this.state.loginInfo.agent.id,
        };

        this.handleCloseFilter();

        // console.log(params);
        // if (this.state.tableData.length === 0) {
        this.props.doLoading();
        // }
        apiDailyBalanceDateByAgent(params)
          .then((res) => {
            // if (this.state.tableData.length === 0) {
            this.props.doLoading();
            // }
            let data = res.data;
            if (data.status === "OK") {
              if (data.records.length > 0) {
                var temp = [];
                temp = data.records;

                const result_temp = temp.reduce((acc, _, i) => {
                  const item = { ...temp[i] };
                  if (i > 0) {
                    const prev = acc.length - 1;
                    const profit_value = item.balance - acc[prev].balance;
                    item.profit = profit_value;
                  } else if (i === 0) {
                    item.profit =
                      item.balance -
                      this.state.selectGroupAccountNumberId.capital;
                  }
                  acc.push(item);
                  return acc;
                }, []);

                // console.log(result_temp);

                let sum_total_profit = 0;

                let perviousDate = this.getPreviousDay(
                  new Date(this.state.startDate)
                );
                let yesterday = moment(perviousDate).utc().format("YYYY-MM-DD");
                // console.log(yesterday);

                let filterDailyBalance = result_temp.filter(
                  (obj) => obj.last_update_date !== yesterday
                );

                for (var i = 0; i < filterDailyBalance.length; i++) {
                  filterDailyBalance[i].id = i + 1;
                  sum_total_profit += filterDailyBalance[i].profit;
                }

                // console.log(filterDailyBalance);
                this.setState({
                  tableData: filterDailyBalance,
                  account_number: this.state.selectGroupAccountNumberId.value,
                  alias: this.state.selectGroupAccountNumberId.alias,
                  capital: this.state.selectGroupAccountNumberId.capital,
                  type_currency:
                    this.state.selectGroupAccountNumberId.type_currency,
                  total_profit: sum_total_profit,
                  arrDataDailyBalance: [],
                });
                // timer = setTimeout(() => this.doLoadData(), 30000);
              }
            }
          })
          .catch((err) => {
            // if (this.state.tableData.length === 0) {
            this.props.doLoading();
            // }
            console.log(err);
          });
      }
    }
  };

  pressReset = () => {
    this.setState({
      tableData: [],
      arrDataDailyBalance: [],
    });

    if (
      this.state.loginInfo.logintype === 1 ||
      this.state.loginInfo.logintype === 4
    ) {
      this.doLoadDailyBalance();
    } else if (
      this.state.loginInfo.logintype === 2 ||
      this.state.loginInfo.logintype === 3
    ) {
      this.doLoadDailyBalanceByAgent(this.state.loginInfo.agent.id);
    }
  };

  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  setStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };
  setEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeAccountNumber = (selectGroupAccountNumberId) => {
    this.setState({
      selectGroupAccountNumberId,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenFilter = () => {
    this.setState({
      setOpenFilter: true,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      setOpenFilter: false,
    });
  };

  renderDialogFilter = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenFilter}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Daily Balance Filter
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseFilter()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Account Number
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a account"
                  value={this.state.selectGroupAccountNumberId}
                  onChange={this.handleChangeAccountNumber}
                  options={this.state.DataGroupAccountNumber}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.startDate}
                  onChange={this.setStartDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.endDate}
                  onChange={this.setEndDate}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseFilter()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.doSeacrhBydate()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Filter
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderSummary = () => {
    if (this.state.tableData.length > 0) {
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    borderLeft: 6,
                    borderColor: "#2f55a2",
                    color: "#FFF",
                    maxHeight: 200,
                    padding: 16,
                    paddingBottom: 24,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    {this.state.startDate === this.state.endDate
                      ? "Summary " + moment(this.state.startDate).format("L")
                      : "Summary " +
                        (moment(this.state.startDate).format("L") +
                          "-" +
                          moment(this.state.endDate).format("L"))}
                  </Typography>
                  <br></br>
                  <br></br>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="account_number"
                          id="account_number"
                          value={this.state.account_number}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Alias
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="alias"
                          id="alias"
                          value={this.state.alias}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Capital
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="capital"
                          id="capital"
                          value={`${
                            this.state.type_currency === "Standart" ? "$" : ""
                          }${Number(
                            parseFloat(this.state.capital).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}${
                            this.state.type_currency === "Cents" ? "¢" : ""
                          }`}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Total Profit/Loss
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="total_profit"
                          id="total_profit"
                          value={
                            isNegative(this.state.total_profit) === true
                              ? `${
                                  this.state.type_currency === "Standart"
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(this.state.total_profit)}`
                                    : `${Number(
                                        parseFloat(
                                          this.state.total_profit
                                        ).toFixed(2)
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })}${
                                        this.state.type_currency === "Cents"
                                          ? "¢"
                                          : ""
                                      }`
                                }  `
                              : this.state.total_profit === 0
                              ? Number(
                                  parseFloat(this.state.total_profit).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : `${
                                  this.state.type_currency === "Standart"
                                    ? "$"
                                    : ""
                                }${Number(
                                  parseFloat(this.state.total_profit).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })}${
                                  this.state.type_currency === "Cents"
                                    ? "¢"
                                    : ""
                                }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <br></br>
        </>
      );
    }
  };

  renderTable = () => {
    if (this.state.tableData.length > 0) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.tableData}
          columns={this.tableColumns}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else if (this.state.arrDataDailyBalance.length > 0) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.arrDataDailyBalance}
          columns={this.tableArrDailyBalanceColumns}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else {
      return (
        <Typography
          sx={{ width: "33%", flexShrink: 0 }}
          style={{ fontWeight: "bold" }}
        >
          No data available
        </Typography>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Daily Balance
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#006b76",
                          }}
                          startIcon={<Storage />}
                          onClick={() => this.handleOpenFilter()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Filter
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        {this.renderSummary()}

        <div className="box-container">{this.renderTable()}</div>
        {this.renderValidationAlert()}
        {this.renderDialogFilter()}
      </Box>
    );
  }
}
export default DailyBalance;
