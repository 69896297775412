import React, { Component } from "react";
import {
  apiDailyProfitDate,
  apiAccountOptionAll,
  apiDailyProfit,
  apiDailyProfitAccount,
  apiDailyProfitAccountType,
  apiDailyProfitByAgent,
  apiDailyProfitDateByAgent,
  apiDailyProfitNoHistory,
  apiAccountMt4_ByAgent,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Input } from "reactstrap";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  Storage,
  Close,
  Refresh,
  Cancel,
  Save,
  Info,
  ArrowBack,
  Source,
} from "@mui/icons-material";
import moment from "moment";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { isNegative } from "../../../global.js";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class DailyProfit extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      tableDataProfitList: [],
      filter: "",
      startDateSelected: moment().locale("id").format("YYYY-MM-DD"),
      startDate: moment().locale("id").format("YYYY-MM-DD"),
      endDate: moment().locale("id").format("YYYY-MM-DD"),
      selectGroupAccountNumberId: null,
      DataGroupAccountNumber: [],
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      account_number: "",
      alias: "",
      balance: 0,
      type_currency: "Cents",
      total_profit: 0,
      total_all_profit: 0,
      arrDataDailyProfit: [],
      setOpenFilter: false,
      setOpenDetailProfit: false,
      loginInfo: "",
      tabIndex: 0,
      dataAccountDailyProfitNoHistory: [],
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        Header: "VPS",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vpsName",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vpsName}
          </Typography>
        ),
      },
      {
        Header: "Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.profit) === true
                    ? "red"
                    : e.original.profit === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.profit) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.profit)}`
                      : `${Number(
                          parseFloat(e.original.profit).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.profit === 0
                ? Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Info />}
              onClick={() => this.doRowView(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.tableArrDailyProfit = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        Header: "VPS",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vpsName",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.vpsName}
          </Typography>
        ),
      },
      {
        Header: "Account Number",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },
      {
        Header: "Lot",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "lots",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.lots}
          </Typography>
        ),
      },
      {
        Header: "Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.profit) === true
                    ? "red"
                    : e.original.profit === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.profit) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.profit)}`
                      : `${Number(
                          parseFloat(e.original.profit).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.profit === 0
                ? Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Info />}
              onClick={() => this.doRowView(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.tableDataProfitList = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("HH:mm:ss")}
          </Typography>
        ),
      },
      {
        Header: "Profit",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "profit",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.profit) === true
                    ? "red"
                    : e.original.profit === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.profit) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.profit)}`
                      : `${Number(
                          parseFloat(e.original.profit).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.profit === 0
                ? Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.profit).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
    ];
  }

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    if (loginInfo.logintype === 1 || loginInfo.logintype === 4) {
      this.doLoadDailyProfit();
      this.doLoadAccountOption();
    } else if (loginInfo.logintype === 2 || loginInfo.logintype === 3) {
      this.doLoadDailyProfitByAgent(loginInfo.agent.id);
      this.doLoadDataByAgent(loginInfo);
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadDataByAgent = (loginInfo) => {
    const agentId = loginInfo.agent.id;

    apiAccountMt4_ByAgent(agentId)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            // console.log("data.records: ", data.records);
            if (loginInfo.logintype === 2) {
              const newArrOptionAccount = data.records.map((obj) => ({
                value: obj.account_number,
                label: `${obj.account_number} - (${obj.alias})`,
                alias: obj.alias,
                balance: obj.balance,
                type_currency: obj.type_currency,
              }));
              this.setState({
                DataGroupAccountNumber: newArrOptionAccount,
              });
            } else if (loginInfo.logintype === 3) {
              const filterAccount = data.records.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              const newArrOptionAccount = filterAccount.map((obj) => ({
                value: obj.account_number,
                label: `${obj.account_number} - (${obj.alias})`,
                alias: obj.alias,
                balance: obj.balance,
                type_currency: obj.type_currency,
              }));
              this.setState({
                DataGroupAccountNumber: newArrOptionAccount,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadAccountOption = () => {
    apiAccountOptionAll()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            // console.log("data.records: ", data.records);
            // console.log("infoAccount: ", this.state.loginInfo.infoAccount);
            // console.log("loginInfo: ", this.state.loginInfo);
            if (this.state.loginInfo.logintype === 4) {
              const filterAccount = data.records.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account.toString() === item1.value
                )
              );

              // console.log("filterAccount: ", filterAccount);
              this.setState({
                DataGroupAccountNumber: filterAccount,
              });
            } else {
              this.setState({
                DataGroupAccountNumber: data.records,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyProfit = () => {
    this.props.doLoading();
    apiDailyProfit()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyProfit = [];
            dataDailyProfit = data.records;

            if (this.state.loginInfo.logintype === 4) {
              const filterDataDaily = dataDailyProfit.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              for (var idx = 0; idx < filterDataDaily.length; idx++) {
                filterDataDaily[idx].id = idx + 1;
              }

              // const filterAccount = newArrOptionAccount.filter((item1) =>
              //   this.state.loginInfo.infoAccount.some(
              //     (item2) => item2.account === item1.value
              //   )
              // );

              this.setState({
                arrDataDailyProfit: filterDataDaily,
                // DataGroupAccountNumber: filterAccount,
                tabIndex: 1,
              });
            } else {
              for (var i = 0; i < dataDailyProfit.length; i++) {
                dataDailyProfit[i].vpsName = dataDailyProfit[i].vps
                  .map((item) => item.vps_name)
                  .join(", ");
                dataDailyProfit[i].id = i + 1;
              }

              this.setState({
                arrDataDailyProfit: dataDailyProfit,
                tabIndex: 1,
              });
            }
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doLoadDailyProfitByAgent = (agent_id) => {
    this.props.doLoading();
    apiDailyProfitByAgent(agent_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyProfit = [];
            dataDailyProfit = data.records;

            // const newArrOptionAccount = dataDailyProfit.map((obj) => ({
            //   value: obj.account_number,
            //   label: `${obj.account_number} - (${obj.alias})`,
            //   alias: obj.alias,
            //   capital: obj.capital,
            // }));

            if (this.state.loginInfo.logintype === 3) {
              const filterDataDaily = dataDailyProfit.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              // const filterAccount = newArrOptionAccount.filter((item1) =>
              //   this.state.loginInfo.infoAccount.some(
              //     (item2) => item2.account === item1.value
              //   )
              // );

              for (var idx = 0; idx < filterDataDaily.length; idx++) {
                filterDataDaily[idx].id = idx + 1;
              }

              this.setState({
                arrDataDailyProfit: filterDataDaily,
                // DataGroupAccountNumber: filterAccount,
                tabIndex: 1,
              });
            } else {
              for (var idxx = 0; idxx < dataDailyProfit.length; idxx++) {
                dataDailyProfit[idxx].id = idxx + 1;
              }
              this.setState({
                arrDataDailyProfit: dataDailyProfit,
                // DataGroupAccountNumber: newArrOptionAccount,
                tabIndex: 1,
              });
            }
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSeacrhBydate = () => {
    const { selectGroupAccountNumberId } = this.state;
    if (this.state.startDate > this.state.endDate) {
      this.setState({
        openValidation: true,
        titleValidation: "Invalid Date?",
        titleBody: "End date is to small from start date",
      });
    } else {
      if (
        this.state.loginInfo.logintype === 1 ||
        this.state.loginInfo.logintype === 4
      ) {
        let params = {
          accountNumber: selectGroupAccountNumberId
            ? selectGroupAccountNumberId.value
            : "",
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };

        this.handleCloseFilter();
        this.props.doLoading();
        // }
        apiDailyProfitDate(params)
          .then((res) => {
            this.props.doLoading();
            // }
            let data = res.data;
            if (data.status === "OK") {
              if (data.records.length > 0) {
                var temp = [];
                temp = data.records;

                let sum_total_profit = 0;

                for (var i = 0; i < temp.length; i++) {
                  temp[i].vpsName = temp[i].vps
                    .map((item) => item.vps_name)
                    .join(", ");
                  temp[i].id = i + 1;
                  sum_total_profit += temp[i].profit;
                }

                this.setState({
                  tableData: temp,
                  account_number: this.state.selectGroupAccountNumberId.value,
                  alias: this.state.selectGroupAccountNumberId.alias,
                  balance: this.state.selectGroupAccountNumberId.balance,
                  type_currency:
                    this.state.selectGroupAccountNumberId.type_currency,
                  // total_profit: sum_total_profit,
                  total_all_profit: sum_total_profit,
                  // arrDataDailyProfit: [],
                  tabIndex: 2,
                });
              }
            }
          })
          .catch((err) => {
            this.props.doLoading();
            console.log(err);
          });
      } else if (
        this.state.loginInfo.logintype === 2 ||
        this.state.loginInfo.logintype === 3
      ) {
        let params = {
          accountNumber: selectGroupAccountNumberId.value,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          agent_id: this.state.loginInfo.agent.id,
        };

        this.handleCloseFilter();
        this.props.doLoading();
        // }
        apiDailyProfitDateByAgent(params)
          .then((res) => {
            this.props.doLoading();
            // }
            let data = res.data;
            if (data.status === "OK") {
              if (data.records.length > 0) {
                var temp = [];
                temp = data.records;

                let sum_total_profit = 0;

                for (var i = 0; i < temp.length; i++) {
                  temp[i].id = i + 1;
                  sum_total_profit += temp[i].profit;
                }

                this.setState({
                  tableData: temp,
                  account_number: this.state.selectGroupAccountNumberId.value,
                  alias: this.state.selectGroupAccountNumberId.alias,
                  balance: this.state.selectGroupAccountNumberId.balance,
                  type_currency:
                    this.state.selectGroupAccountNumberId.type_currency,
                  total_all_profit: sum_total_profit,
                  // arrDataDailyProfit: [],
                  tabIndex: 2,
                });
              }
            }
          })
          .catch((err) => {
            this.props.doLoading();
            console.log(err);
          });
      }
    }
  };

  doApiListProfit = (item) => {
    this.props.doLoading();
    let params = {
      accountNumber: item.account_number,
      dateSelected: item.last_update_at,
    };
    apiDailyProfitAccount(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = [];
            temp = data.records;

            let sum_total_profit = 0;

            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              if (temp[i].type === "Profit") {
                sum_total_profit += temp[i].profit;
              }
            }

            this.setState({
              tableDataProfitList: temp,
              account_number: item.account_number,
              alias: item.alias,
              type_currency: item.type_currency,
              total_profit: sum_total_profit,
              // tableData: [],
              // arrDataDailyProfit: [],
              tabIndex: 3,
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  showAccountNoSendHistory = () => {
    this.props.doLoading();
    apiDailyProfitNoHistory()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataAccountDailyProfitNoHistory = [];
            dataAccountDailyProfitNoHistory = data.records;

            console.log(
              "dataAccountDailyProfitNoHistory: ",
              dataAccountDailyProfitNoHistory
            );

            for (var i = 0; i < dataAccountDailyProfitNoHistory.length; i++) {
              dataAccountDailyProfitNoHistory[i].vpsName =
                dataAccountDailyProfitNoHistory[i].vps
                  .map((item) => item.vps_name)
                  .join(", ");
              dataAccountDailyProfitNoHistory[i].id = i + 1;
            }

            this.setState({
              dataAccountDailyProfitNoHistory: dataAccountDailyProfitNoHistory,
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doApiProfitChangeType = (item) => {
    this.props.doLoading();
    let params = {
      profit_id: item.profit_id,
      type: item.type,
    };
    apiDailyProfitAccountType(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableDataProfitList;

          let sum_total_profit = 0;

          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            if (temp[i].type === "Profit") {
              sum_total_profit += temp[i].profit;
            }
          }

          this.setState({
            tableDataProfitList: temp,
            total_profit: sum_total_profit,
            tabIndex: 3,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    const { tabIndex } = this.state;
    this.setState({
      dataAccountDailyProfitNoHistory: [],
    });
    if (tabIndex === 3) {
      if (this.state.tableData.length > 0) {
        this.setState({
          tabIndex: tabIndex - 1,
        });
      } else {
        this.setState({
          tableData: [],
          arrDataDailyProfit: [],
          tableDataProfitList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
        if (
          this.state.loginInfo.logintype === 1 ||
          this.state.loginInfo.logintype === 4
        ) {
          this.doLoadDailyProfit();
        } else if (
          this.state.loginInfo.logintype === 2 ||
          this.state.loginInfo.logintype === 3
        ) {
          this.doLoadDailyProfitByAgent(this.state.loginInfo.agent.id);
        }
      }
    } else if (tabIndex === 2) {
      if (this.state.tableData.length > 0) {
        this.setState({
          tabIndex: tabIndex - 1,
          tableData: [],
          tableDataProfitList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          tableData: [],
          arrDataDailyProfit: [],
          tableDataProfitList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
        if (
          this.state.loginInfo.logintype === 1 ||
          this.state.loginInfo.logintype === 4
        ) {
          this.doLoadDailyProfit();
        } else if (
          this.state.loginInfo.logintype === 2 ||
          this.state.loginInfo.logintype === 3
        ) {
          this.doLoadDailyProfitByAgent(this.state.loginInfo.agent.id);
        }
      }
    } else if (tabIndex === 1) {
      this.setState({
        tableData: [],
        arrDataDailyProfit: [],
        tableDataProfitList: [],
        startDate: moment().locale("id").format("YYYY-MM-DD"),
        endDate: moment().locale("id").format("YYYY-MM-DD"),
      });
      if (
        this.state.loginInfo.logintype === 1 ||
        this.state.loginInfo.logintype === 4
      ) {
        this.doLoadDailyProfit();
      } else if (
        this.state.loginInfo.logintype === 2 ||
        this.state.loginInfo.logintype === 3
      ) {
        this.doLoadDailyProfitByAgent(this.state.loginInfo.agent.id);
      }
    }
  };

  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  setStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };
  setEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeAccountNumber = (selectGroupAccountNumberId) => {
    this.setState({
      selectGroupAccountNumberId,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenFilter = () => {
    this.setState({
      setOpenFilter: true,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      setOpenFilter: false,
    });
  };

  renderDialogFilter = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenFilter}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Daily Profit Filter
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseFilter()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Account Number
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a account"
                  value={this.state.selectGroupAccountNumberId}
                  onChange={this.handleChangeAccountNumber}
                  options={this.state.DataGroupAccountNumber}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.startDate}
                  onChange={this.setStartDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.endDate}
                  onChange={this.setEndDate}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseFilter()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.doSeacrhBydate()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Filter
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderSummary = () => {
    if (this.state.tabIndex === 2) {
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    borderLeft: 6,
                    borderColor: "#2f55a2",
                    color: "#FFF",
                    maxHeight: 200,
                    padding: 16,
                    paddingBottom: 24,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    {this.state.startDate === this.state.endDate
                      ? moment(this.state.startDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY")
                      : moment(this.state.startDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY") +
                        " - " +
                        moment(this.state.endDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY")}
                  </Typography>
                  <br></br>
                  <br></br>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="account_number"
                          id="account_number"
                          value={this.state.account_number}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Alias
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="alias"
                          id="alias"
                          value={this.state.alias}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Balance
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="balance"
                          id="balance"
                          value={`${
                            this.state.type_currency === "Standart" ? "$" : ""
                          }${Number(
                            parseFloat(this.state.balance).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}${
                            this.state.type_currency === "Cents" ? "¢" : ""
                          }`}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Total Profit
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="total_profit"
                          id="total_profit"
                          value={
                            isNegative(this.state.total_all_profit) === true
                              ? `${
                                  this.state.type_currency === "Standart"
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(this.state.total_all_profit)}`
                                    : `${Number(
                                        parseFloat(
                                          this.state.total_all_profit
                                        ).toFixed(2)
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })}${
                                        this.state.type_currency === "Cents"
                                          ? "¢"
                                          : ""
                                      }`
                                }  `
                              : this.state.total_all_profit === 0
                              ? Number(
                                  parseFloat(
                                    this.state.total_all_profit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : `${
                                  this.state.type_currency === "Standart"
                                    ? "$"
                                    : ""
                                }${Number(
                                  parseFloat(
                                    this.state.total_all_profit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })}${
                                  this.state.type_currency === "Cents"
                                    ? "¢"
                                    : ""
                                }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <br></br>
        </>
      );
    } else if (this.state.tabIndex === 3) {
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    borderLeft: 6,
                    borderColor: "#2f55a2",
                    color: "#FFF",
                    maxHeight: 200,
                    padding: 16,
                    paddingBottom: 24,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    {moment(this.state.startDateSelected)
                      .locale("en")
                      .format("dddd, DD MMMM YYYY")}
                  </Typography>
                  <br></br>
                  <br></br>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="account_number"
                          id="account_number"
                          value={`${this.state.account_number} ${this.state.alias}`}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Total Profit
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="total_profit"
                          id="total_profit"
                          value={
                            isNegative(this.state.total_profit) === true
                              ? `${
                                  this.state.type_currency === "Standart"
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(this.state.total_profit)}`
                                    : `${Number(
                                        parseFloat(
                                          this.state.total_profit
                                        ).toFixed(2)
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })}${
                                        this.state.type_currency === "Cents"
                                          ? "¢"
                                          : ""
                                      }`
                                }  `
                              : this.state.total_profit === 0
                              ? Number(
                                  parseFloat(this.state.total_profit).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : `${
                                  this.state.type_currency === "Standart"
                                    ? "$"
                                    : ""
                                }${Number(
                                  parseFloat(this.state.total_profit).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })}${
                                  this.state.type_currency === "Cents"
                                    ? "¢"
                                    : ""
                                }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <br></br>
        </>
      );
    }
  };

  renderTable = () => {
    if (this.state.tableData.length > 0 && this.state.tabIndex === 2) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.tableData}
          columns={this.tableColumns}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else if (
      this.state.arrDataDailyProfit.length > 0 &&
      this.state.tabIndex === 1
    ) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.arrDataDailyProfit}
          columns={this.tableArrDailyProfit}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else if (
      this.state.tableDataProfitList.length > 0 &&
      this.state.tabIndex === 3
    ) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.tableDataProfitList}
          columns={this.tableDataProfitList}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else {
      return (
        <Typography
          sx={{ width: "33%", flexShrink: 0 }}
          style={{ fontWeight: "bold" }}
        >
          No data available
        </Typography>
      );
    }
  };

  renderTableAccountNoHistory = () => {
    if (this.state.dataAccountDailyProfitNoHistory.length > 0) {
      return (
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={this.state.dataAccountDailyProfitNoHistory}
          columns={[
            {
              Header: "No",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "id",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              width: 80,
              Cell: (e) => (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {e.original.id}
                </Typography>
              ),
            },
            {
              Header: "VPS",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "vpsName",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {e.original.vpsName}
                </Typography>
              ),
            },
            {
              Header: "Account",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "account_number",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {e.original.account_number}
                </Typography>
              ),
            },
            {
              Header: "Alias",
              headerStyle: { fontWeight: "bold", fontSize: 14 },
              accessor: "alias",
              style: {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              Cell: (e) => (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {e.original.alias}
                </Typography>
              ),
            },
          ]}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    }
  };

  doRowView = (item) => {
    // console.log(item);
    this.setState({
      setOpenDetailProfit: true,
      startDateSelected: item.last_update_at,
    });
    this.doApiListProfit(item);
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Daily Profit
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    {this.state.tabIndex === 3 ? (
                      <div className="contentDate">
                        <div style={{ marginRight: 0 }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#1273DE",
                            }}
                            startIcon={<ArrowBack />}
                            onClick={() => this.pressReset()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Back
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="contentDate">
                        {this.state.loginInfo.logintype === 1 &&
                        this.state.tabIndex === 1 ? (
                          <div style={{ marginRight: 16 }}>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                backgroundColor: "#006b76",
                              }}
                              startIcon={<Source />}
                              onClick={() => this.showAccountNoSendHistory()}
                            >
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 14,
                                  color: "#fff",
                                  textTransform: "capitalize",
                                }}
                              >
                                No Order
                              </Typography>
                            </Button>
                          </div>
                        ) : null}

                        <div style={{ marginRight: 16 }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#006b76",
                            }}
                            startIcon={<Storage />}
                            onClick={() => this.handleOpenFilter()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Filter
                            </Typography>
                          </Button>
                        </div>
                        <div style={{ marginRight: 0 }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#1273DE",
                            }}
                            startIcon={<Refresh />}
                            onClick={() => this.pressReset()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Refresh
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        {this.renderSummary()}
        <div className="box-container">
          {this.state.dataAccountDailyProfitNoHistory.length > 0
            ? this.renderTableAccountNoHistory()
            : this.renderTable()}
        </div>
        {this.renderValidationAlert()}
        {this.renderDialogFilter()}
      </Box>
    );
  }
}
export default DailyProfit;
