import React, { createContext, Component } from "react";
import mqtt from "mqtt";

export const MqttContext = createContext();

const MQTT_BROKER_URL = "wss://mqtt.3dvam.info";

const MQTT_OPTIONS = {
  protocolVersion: 5,
  username: "papinaga",
  password: "Cideng87(",
  reconnectPeriod: 1000,
  clean: true,
  clientId: `mqtt_${Math.random().toString(16).slice(3)}`,
};

export class MqttProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      isConnected: false,
      messagesTopicTicket: [],
      messagesTopicNotification: [],
      messagesTradeDataEURUSD: [],
      messagesTradeChartEURUSD: [],
      messagesTradeDataBTCUSDc: [],
      messagesTradeChartBTCUSDc: [],
    };
  }

  componentDidMount() {
    const mqttClient = mqtt.connect(MQTT_BROKER_URL, MQTT_OPTIONS);

    mqttClient.on("connect", () => {
      this.setState({ isConnected: true });
      console.log("Connected to MQTT broker");
    });

    mqttClient.on("message", (topik, message) => {
      const parsedMessage = JSON.parse(message.toString());

      //   console.log("parsedMessage: ", parsedMessage);

      if (topik.includes("ticket")) {
        this.setState({ messagesTopicTicket: parsedMessage });
      } else if (topik.includes("notification")) {
        this.setState({ messagesTopicNotification: parsedMessage });
      } else if (topik.includes("TradechartV2/EURUSD")) {
        this.setState({ messagesTradeChartEURUSD: parsedMessage });
      } else if (topik.includes("TradedataV2/241558524/EURUSD")) {
        this.setState({ messagesTradeDataEURUSD: parsedMessage });
      } else if (topik.includes("TradechartV2/BTCUSDc")) {
        this.setState({ messagesTradeChartBTCUSDc: parsedMessage });
      } else if (topik.includes("TradedataV2/159018075/BTCUSDc")) {
        this.setState({ messagesTradeDataBTCUSDc: parsedMessage });
      }
    });

    mqttClient.on("error", (err) => {
      console.error("Connection error:", err);
      mqttClient.end();
    });

    this.setState({ client: mqttClient });
  }

  componentWillUnmount() {
    const { client } = this.state;
    if (client) {
      client.end();
    }
  }

  subscribeToTopic = (topic) => {
    const { client } = this.state;
    console.log(`Subscribing to topic: ${topic}`);
    client.subscribe(topic, (err) => {
      if (!err) {
        console.log(`Successfully subscribed to ${topic}`);
      } else {
        console.error(`Error subscribing to ${topic}:`, err);
      }
    });
  };

  unsubscribeFromTopic = (topic) => {
    const { client } = this.state;
    console.log(`Unsubscribe to topic: ${topic}`);
    client.unsubscribe(topic, (err) => {
      if (!err) {
        console.log(`Successfully unsubscribe to ${topic}`);
      } else {
        console.error(`Error unsubscribe to ${topic}:`, err);
      }
    });
  };

  publishMessage = (topic, message) => {
    const { client } = this.state;
    if (client) {
      client.publish(topic, message);
    }
  };

  render() {
    const {
      client,
      isConnected,
      messagesTopicNotification,
      messagesTopicTicket,
      messagesTradeDataEURUSD,
      messagesTradeChartEURUSD,
      messagesTradeDataBTCUSDc,
      messagesTradeChartBTCUSDc,
    } = this.state;

    return (
      <MqttContext.Provider
        value={{
          client,
          isConnected,
          messagesTopicNotification,
          messagesTopicTicket,
          messagesTradeDataEURUSD,
          messagesTradeChartEURUSD,
          messagesTradeDataBTCUSDc,
          messagesTradeChartBTCUSDc,
          subscribeToTopic: this.subscribeToTopic,
          publishMessage: this.publishMessage,
          unsubscribeFromTopic: this.unsubscribeFromTopic,
        }}
      >
        {this.props.children}
      </MqttContext.Provider>
    );
  }
}
