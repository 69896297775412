import React, { Component } from "react";
import axios from "axios";
import { serverUrl } from "../../../config.js";
import "./Login.style.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { Button, Typography } from "@mui/material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.globallang = getLanguage(activeLanguage, "global");
    this.language = getLanguage(activeLanguage, "Login");

    this.state = {
      userPhone: "",
      userPassword: "",
      errorMessage: "",
    };
  }

  componentDidMount = () => {};

  doLogin = () => {
    if (this.state.userPhone === "") {
      alert(this.language.alertphoneno);
      return false;
    }
    if (this.state.userPassword === "") {
      alert(this.language.alertpassword);
      return false;
    }

    let param = {
      phone: this.state.userPhone,
      pass: this.state.userPassword,
    };
    let { match } = this.props;
    axios
      .post(serverUrl + "login.php", param, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;
        if (data.status === "ok") {
          localStorage.setItem("loginInfo", JSON.stringify(data.record));

          const type = data.record.logintype;
          console.log("type: ", type);
          if (type === 1) {
            this.props.history.replace({
              pathname: `${match.path}panel/dashboard`,
              state: { loginInfo: data.record },
            });
          } else {
            this.setState({
              errorMessage: "You haven't permission to access",
              userPassword: "",
              userPhone: "",
            });
          }
        } else {
          this.setState({ errorMessage: data.message, userPassword: "" });
        }
      })
      .catch((error) => {
        console.log(error);
        //alert(error);
      });
  };

  renderError = () => {
    if (this.state.errorMessage !== "") {
      return this.state.errorMessage;
    }
  };

  render() {
    return (
      <div className="login-container">
        <div className="login-box-container">
          <div className="logo">
            <img
              width="100"
              src={require("../../../Assets/Images/3DVAM.png")}
              alt="logo"
              style={{ marginLeft: -5 }}
            />
          </div>

          <div className="login-input">
            <input
              type="text"
              placeholder="Phone or Email"
              value={this.state.userPhone}
              onChange={(event) =>
                this.setState({ userPhone: event.target.value })
              }
            />
          </div>
          <div className="login-input">
            <input
              type="password"
              placeholder={this.language["password"]}
              value={this.state.userPassword}
              onChange={(event) =>
                this.setState({ userPassword: event.target.value })
              }
            />
          </div>
          <div className="login-error">{this.renderError()}</div>
          <div className="button-container">
            <Button
              variant="contained"
              style={{ backgroundColor: "#1F324B", width: "100%" }}
              onClick={() => this.doLogin()}
              block
            >
              <Typography
                component="span"
                variant="subtitle2"
                style={
                  (stylesListComent.inline,
                  {
                    color: "#fff",
                    fontWeight: "bolder",
                  })
                }
              >
                {this.language["login"]}
              </Typography>
            </Button>
          </div>
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              fontWeight: "bold",
              color: "#000",
              justifyContent: "center",
              marginTop: 16,
              marginBottom: 20,
            }}
          >
            Version 1.4.75
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
