import React, { Component } from "react";
import {
  apiAccountListActive,
  apiAccountDelete,
  apiAccountDeleteAll,
  apiAccountUpdateAlias,
  apiRateOption,
  apiAccountMt4UpdateRate,
  apiAccountMt4_ByAgent,
  apiAccountNullOption,
  apiVpsOptionAvailable,
  apiVpsAddAccount,
  apiAgentOption,
  apiMasterSlaveOption,
  apiUserOption,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  Checkbox,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Input, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import {
  Refresh,
  Assignment,
  Delete,
  Close,
  Cancel,
  Check,
  DeleteOutline,
  AddBox,
  Save,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { formatNumber } from "../../../global.js";
import dayjs from "dayjs";
import Select from "react-select";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.setAlias = React.createRef();
    this.setPasswordMaster = React.createRef();
    this.setPasswordInvestor = React.createRef();
    this.setLots = React.createRef();
    this.setOpenAddNew = React.createRef();
    this.setOpenAddNew.current = false;
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_account_id: 0,
      group_account_name: "",
      is_available: 0,
      availableShow: [
        { id: 0, text: "Hiden" },
        { id: 1, text: "Show" },
      ],
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      alias: "",
      mql5Link: "",
      capital: 0,
      rateOption: [],
      rateSelected: null,
      loginInfo: "",
      optionVps: [],
      optionAccount: [],
      optionAgent: [],
      optionMasterSlave: [],
      optionUser: [],
      vpsSelected: null,
      accountSelected: null,
      agentSelected: null,
      masterSlaveSelected: null,
      userSelected: null,
      lots: "",
      rate_id: null,
      typeOption: [
        { value: "Cents", label: "Cents" },
        { value: "Standart", label: "Standart" },
      ],
      platformOption: [
        { value: 4, label: "MT4" },
        { value: 5, label: "MT5" },
      ],
      platform: {
        value: 4,
        label: "MT4",
      },
      type_currency: null,
      submitLoading: false,
      showPasswordMaster: false,
      showPasswordInvestor: false,
    };

    this.tableColumns = [
      {
        Header: "Date Insert",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "date_insert",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {dayjs(e.original.date_insert).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {dayjs(e.original.date_insert).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            </>
          ),
      },
      {
        Header: "Last Timestamp",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_timestamp",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {dayjs(e.original.last_timestamp).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            </>
          ),
      },

      {
        Header: "VPS",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "vps",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 120,
        filterMethod: (filter, row) => {
          const searchValue = filter.value.toLowerCase();
          const vpsEntries = row[filter.id];
          return vpsEntries.some((vps) =>
            `${vps.vps_name} (${vps.flag_auto === 1 ? "auto" : "manual"})`
              .toLowerCase()
              .includes(searchValue)
          );
        },
        Cell: (e) => {
          const vpsEntries = e.original.vps;

          // const vpsNames = vpsEntries.map((vps) => vps.vps_name).join(", ");

          const vpsNames = vpsEntries
            .map(
              (vps) =>
                `${vps.vps_name} (${vps.flag_auto === 1 ? "auto" : "manual"})`
            )
            .join(", ");

          const handleClick = () => {
            if (vpsEntries.length <= 1) {
              vpsEntries.forEach((vps) => this.handleVpsClick(vps.code_vps));
            }
          };

          return parseInt(e.original.strPercentage) < 70 ? (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: "red",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              {vpsNames}
            </Typography>
          ) : (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: "inline-block",
                color: e.original.status === "OFF" ? "red" : "black",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              {vpsNames}
            </Typography>
          );
        },
      },

      {
        Header: "Account",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 120,
        Cell: (e) => {
          const textColor = e.original.status === "OFF" ? "red" : "black";
          return (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: textColor,
                }}
              >
                {e.original.account_number}
              </Typography>
            </>
          );
        },
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {e.original.alias}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {e.original.alias}
              </Typography>
            </>
          ),
      },
      {
        Header: "Equity",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "equity",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 120,
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {`${e.original.type_currency === "Standart" ? "$" : ""}${Number(
                  parseFloat(e.original.equity).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {`${e.original.type_currency === "Standart" ? "$" : ""}${Number(
                  parseFloat(e.original.equity).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
              </Typography>
            </>
          ),
      },
      {
        Header: "%",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "percentage",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) =>
          parseInt(e.original.strPercentage) < 70 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: "red",
                }}
              >
                {Number(
                  parseFloat(e.original.percentage).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 0,
                })}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "OFF" ? "red" : "black",
                }}
              >
                {Number(
                  parseFloat(e.original.percentage).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 0,
                })}
              </Typography>
            </>
          ),
      },
      {
        Header: "Leverage",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "leverage",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 150,
        Cell: (e) => {
          return (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  // color: textColor,
                }}
              >
                1:{e.original.leverage}
              </Typography>
            </>
          );
        },
      },

      {
        Header: "Status",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "status",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 100,
        Cell: (e) => {
          return (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline-block",
                  color: e.original.status === "Online" ? "green" : "red",
                  fontWeight: "600",
                }}
              >
                {e.original.status}
              </Typography>
            </>
          );
        },
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
            &nbsp;
            {this.state.loginInfo.logintype === 1 ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#ff0000",
                }}
                startIcon={<Delete />}
                onClick={() => this.doRowDeleteInfo(e.original)}
              >
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Remove
                </Typography>
              </Button>
            ) : null}
          </div>
        ),
      },
    ];
  }

  handleVpsClick = (code_vps) => {
    this.props.history.push("/panel/vps-detail/" + code_vps);
  };

  componentDidMount() {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    if (loginInfo.logintype === 1 || loginInfo.logintype === 4) {
      this.doLoadData();
    } else if (loginInfo.logintype === 2 || loginInfo.logintype === 3) {
      this.doLoadDataByAgent(loginInfo.agent.id);
    }

    this.doLoadOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  doLoadOption = () => {
    apiRateOption()
      .then((res) => {
        const data = res.data;
        // console.log(data);
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              rateOption: data.records,
            });
          } else {
            this.setState({
              rateOption: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    apiVpsOptionAvailable()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionVps: data.records,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    apiAccountNullOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionAccount: data.records,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    apiAgentOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionAgent: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });

    apiMasterSlaveOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionMasterSlave: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });

    apiUserOption()
      .then((res) => {
        const data = res.data;
        if (data.status === "OK") {
          this.setState({
            optionUser: data.records,
          });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  doApiUpdateRate = (item) => {
    // console.log("item: ", item);

    let params = {
      code_id: item.code_id,
      rate_id: item.rate_id,
    };

    apiAccountMt4UpdateRate(params)
      .then((res) => {
        // const data = res.data;
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadData = () => {
    // console.log("asdsad");
    this.props.doLoading();

    apiAccountListActive()
      .then((res) => {
        this.props.doLoading();

        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].vpsName = temp[i].vps
                .map((item) => item.vps_name)
                .join(", ");
              temp[i].percentage = (temp[i].equity / temp[i].balance) * 100;
              temp[i].strPercentage = Number(
                parseFloat((temp[i].equity / temp[i].balance) * 100).toFixed(0)
              ).toLocaleString("en", {
                minimumFractionDigits: 0,
              });
            }

            if (this.state.loginInfo.logintype === 4) {
              const filteredData1 = temp.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              // console.log(filteredData1);

              this.setState({ tableData: filteredData1 });
            } else {
              const filterAccountVps = temp.filter(
                (item) => item.vps.length > 0
              );

              // console.log("filterAccountVps: ", filterAccountVps);
              this.setState({ tableData: filterAccountVps });
            }
          } else {
            this.setState({ tableData: [] });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doLoadDataByAgent = (agent_id) => {
    // console.log("asdsad");
    this.props.doLoading();

    apiAccountMt4_ByAgent(agent_id)
      .then((res) => {
        this.props.doLoading();

        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].vpsName = temp[i].vps
                .map((item) => item.vps_name)
                .join(", ");
              temp[i].percentage = (temp[i].equity / temp[i].balance) * 100;
              temp[i].strPercentage = Number(
                parseFloat((temp[i].equity / temp[i].balance) * 100).toFixed(0)
              ).toLocaleString("en", {
                minimumFractionDigits: 0,
              });
            }

            if (this.state.loginInfo.logintype === 3) {
              const filteredData1 = temp.filter((item1) =>
                this.state.loginInfo.infoAccount.some(
                  (item2) => item2.account === item1.account_number
                )
              );

              // console.log(filteredData1);

              this.setState({ tableData: filteredData1 });
            } else {
              this.setState({ tableData: temp });
            }

            // setTimeout(() => {
            //   this.doLoadData();
            // }, 30000);

            // timer = setTimeout(() => this.doLoadData(), 30000);
          } else {
            this.setState({ tableData: [] });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      code_id: this.state.dataDetail.code_id,
      alias: this.state.alias,
      capital: this.state.capital.replace(/,/g, ""),
      mql5Link: this.state.mql5Link,
    };

    // console.log(params);

    apiAccountUpdateAlias(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseAddNew();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doDeleteAccount = (code_id) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiAccountDelete(code_id)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  pressDeleteAll = () => {
    // console.log(this.state.selectRowData);
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    this.setState({ showLoadingDeleteAll: true });
    apiAccountDeleteAll(params)
      .then((res) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDeleteAll: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Telegram Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete the account?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.LoadingInvite === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove account {item.account_number}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteAccount(item.code_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.props.history.push("/panel/account-detail/" + row.account_number);
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
      tableData: [],
    });
    if (
      this.state.loginInfo.logintype === 1 ||
      this.state.loginInfo.logintype === 4
    ) {
      this.doLoadData();
    } else if (
      this.state.loginInfo.logintype === 2 ||
      this.state.loginInfo.logintype === 3
    ) {
      this.doLoadDataByAgent(this.state.loginInfo.agent.id);
    }
  };

  pressAddNew = () => {
    this.setOpenAddNew.current = true;
    this.forceUpdate();
  };

  handleCloseAddNew = () => {
    this.setOpenAddNew.current = false;
    this.forceUpdate();
    this.setState({
      rateSelected: null,
      type_currency: null,
      accountSelected: null,
      vpsSelected: null,
      platform: {
        value: 4,
        label: "MT4",
      },
    });
  };

  changeAvailable = (is_available) => {
    this.setState({ is_available: is_available });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked_transfer.indexOf(value);
    const newChecked = [...this.state.checked_transfer];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("new checked", newChecked);

    this.setState({
      checked_transfer: newChecked,
    });
  };

  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      let dataCheckedNot = this.not(this.state.checked_transfer, items);
      // console.log(dataCheckedNot);
      this.setState({
        checked_transfer: dataCheckedNot,
      });
    } else {
      let dataUnion = this.union(this.state.checked_transfer, items);
      // console.log(dataUnion);
      this.setState({
        checked_transfer: dataUnion,
      });
    }
  };

  handleCheckedRight = () => {
    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );

    let Right = this.state.right.concat(leftChecked);
    let Left = this.not(this.state.left, leftChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, leftChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      right: Right,
      left: Left,
      checked_transfer: CheckedTransfer,
    });
  };

  handleCheckedLeft = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let Left = this.state.left.concat(rightChecked);
    let Right = this.not(this.state.right, rightChecked);
    let CheckedTransfer = this.not(this.state.checked_transfer, rightChecked);

    // console.log("right:", Right);
    // console.log("left:", Left);
    // console.log("CheckedTransfer:", CheckedTransfer);

    this.setState({
      left: Left,
      right: Right,
      checked_transfer: CheckedTransfer,
    });
  };

  numberOfChecked = (items) =>
    this.intersection(this.state.checked_transfer, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={
              this.numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              this.numberOfChecked(items) !== items.length &&
              this.numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value.alias !== "" ? value.alias : value.phonenumber
          } -label`;

          return (
            <ListItem
              key={value.manage_id}
              role="listitem"
              button
              onClick={this.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked_transfer.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  value.alias !== "" ? value.alias : value.phonenumber
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  handleUpdateCapital = (e) => {
    var value = e.target.value;

    this.setState({
      capital: formatNumber(value),
    });
  };

  renderTransferList = () => {
    let rightChecked = this.intersection(
      this.state.checked_transfer,
      this.state.right
    );

    let leftChecked = this.intersection(
      this.state.checked_transfer,
      this.state.left
    );
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{this.customList("Available", this.state.left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{this.customList("Account List", this.state.right)}</Grid>
      </Grid>
    );
  };

  handleChangeVps = (vps) => {
    this.setState({
      vpsSelected: vps,
    });
  };

  handleChangeAgent = (agent) => {
    this.setState({
      agentSelected: agent,
    });
  };

  handleChangeMasterSlave = (masterSlave) => {
    this.setState({
      masterSlaveSelected: masterSlave,
    });
  };

  handleChangeUser = (user) => {
    this.setState({
      userSelected: user,
    });
  };

  handleLotsChange = (value) => {
    const validValue = value.replace(/[^0-9.]/g, "");
    const parts = validValue.split(".");
    if (parts.length <= 2) {
      this.setState({ lots: validValue });
    }
  };

  handleChangeRate = (rate) => {
    this.setState({
      rateSelected: rate,
    });
  };

  handleChangeAccount = (item) => {
    // console.log("item: ", item);

    const vps = item.vps;
    const agent = item.agent;
    const master = item.master;
    const user = item.user;
    const type_currency = item.type_currency;
    const platform = item.platform;

    const optionVps = this.state.optionVps;
    const optionAgent = this.state.optionAgent;
    const optionMaster = this.state.optionMasterSlave;
    const optionUser = this.state.optionUser;

    const selectedVps =
      vps.length > 0
        ? optionVps.filter((data) => vps.some((v) => v.vps_id === data.value))
        : null;
    const selectedAgent =
      agent.length > 0
        ? optionAgent.filter((data) =>
            agent.some((a) => a.agent_id === data.value)
          )
        : null;

    const selectedMaster =
      master.length > 0
        ? optionMaster.filter((data) =>
            master.some((m) => m.master_id === data.value)
          )
        : null;

    const selectedUser =
      user.length > 0
        ? optionUser.filter((data) =>
            user.some((u) => u.phoneno === data.value)
          )
        : null;

    if (this.setAlias.current) {
      this.setAlias.current.value = item.alias !== null ? item.alias : "";
    }

    if (this.setLots.current) {
      this.setLots.current.value = item.lot !== null ? item.lot : "";
    }

    const typeSelected = this.state.typeOption.filter(
      (obj) => obj.value === type_currency
    );
    const platformSelected = this.state.platformOption.filter(
      (obj) => obj.value === platform
    );

    // console.log("selectedVps: ", selectedVps);
    // console.log("selectedMaster: ", selectedMaster);
    // console.log("selectedAgent: ", selectedAgent);
    // console.log("selectedUser: ", selectedUser);
    // console.log("platformSelected: ", platformSelected);
    // console.log("typeSelected: ", typeSelected);

    this.setState({
      accountSelected: item,
      vpsSelected: selectedVps,
      masterSlaveSelected: selectedMaster,
      agentSelected: selectedAgent,
      userSelected: selectedUser,
      platform: platformSelected.length > 0 ? platformSelected[0] : null,
      type_currency: typeSelected.length > 0 ? typeSelected[0] : null,
    });
  };

  handleChangeCurrency = (currency) => {
    this.setState({
      type_currency: currency,
    });
  };

  handleChangePlatform = (platform) => {
    // console.log("platform: ", platform);
    this.setState({
      platform: platform,
    });
  };

  checkData = () => {
    const alias = this.setAlias.current.value;
    const lots = this.setLots.current.value;
    const password_master = this.setPasswordMaster.current.value;
    const password_investor = this.setPasswordInvestor.current.value;
    const vps = this.state.vpsSelected;

    if (this.state.accountSelected === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty account?",
        titleBody: "Please select a account number",
      });
    } else if (password_master === undefined || password_master === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Password Master?",
        titleBody: "Please set password master",
      });
    } else if (alias === undefined || alias === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty alias?",
        titleBody: "Please set alias",
      });
    } else if (lots === undefined || lots === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty lots?",
        titleBody: "Please set lots",
      });
    } else if (vps === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty VPS?",
        titleBody: "Please set vps",
      });
    } else {
      let params = {
        account_number:
          this.state.accountSelected !== null
            ? this.state.accountSelected.value
            : "",
        code_id:
          this.state.accountSelected !== null
            ? this.state.accountSelected.code_id
            : "",
        alias: alias,
        password_master: password_master,
        password_investor: password_investor,
        lots: lots,
        rate:
          this.state.rateSelected !== null ? this.state.rateSelected.value : "",
        type:
          this.state.type_currency !== null
            ? this.state.type_currency.value
            : 4,
        platform: this.state.platform !== null ? this.state.platform.value : "",
        vps: this.state.vpsSelected !== null ? this.state.vpsSelected : "",
        agent:
          this.state.agentSelected !== null
            ? Array.isArray(this.state.agentSelected)
              ? this.state.agentSelected
              : [this.state.agentSelected]
            : [],
        master:
          this.state.masterSlaveSelected !== null
            ? Array.isArray(this.state.masterSlaveSelected)
              ? this.state.masterSlaveSelected
              : [this.state.masterSlaveSelected]
            : [],
        user:
          this.state.userSelected !== null
            ? Array.isArray(this.state.userSelected)
              ? this.state.userSelected
              : [this.state.userSelected]
            : [],
      };

      console.log("params: ", params);
      this.setState({
        submitLoading: true,
      });
      apiVpsAddAccount(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          const data = res.data;
          if (data.status === "OK") {
            this.setState({
              openSuccess: true,
              openSuccessText: "Add data account successfully",
            });
            this.handleCloseAddNew();
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          this.setState({
            submitLoading: false,
          });
        });
    }
  };

  handleTogglePasswordMaster = () => {
    this.setState((prevState) => ({
      showPasswordMaster: !prevState.showPasswordMaster,
    }));
  };

  handleTogglePasswordInvestor = () => {
    this.setState((prevState) => ({
      showPasswordInvestor: !prevState.showPasswordInvestor,
    }));
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.setOpenAddNew.current}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Add Account
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Account
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a account"
                  value={this.state.accountSelected}
                  onChange={this.handleChangeAccount}
                  options={this.state.optionAccount}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Alias
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="alias"
                  id="alias"
                  innerRef={this.setAlias}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Password Master
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <InputGroup>
                  <Input
                    autoComplete="off"
                    type={this.state.showPasswordMaster ? "text" : "password"}
                    name="alias"
                    id="alias"
                    innerRef={this.setPasswordMaster}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <span onClick={this.handleTogglePasswordMaster}>
                        {this.state.showPasswordMaster ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Password Investor
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <InputGroup>
                  <Input
                    autoComplete="off"
                    type={this.state.showPasswordInvestor ? "text" : "password"}
                    name="alias"
                    id="alias"
                    innerRef={this.setPasswordInvestor}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <span onClick={this.handleTogglePasswordInvestor}>
                        {this.state.showPasswordInvestor ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Lot Size
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="lot_size"
                  id="lot_size"
                  innerRef={this.setLots}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Rate
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a rate"
                  value={this.state.rateSelected}
                  onChange={this.handleChangeRate}
                  options={this.state.rateOption}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Type
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a type"
                  value={this.state.type_currency}
                  onChange={this.handleChangeCurrency}
                  options={this.state.typeOption}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Platform
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a platform"
                  value={this.state.platform}
                  onChange={this.handleChangePlatform}
                  options={this.state.platformOption}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  VPS
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  value={this.state.vpsSelected}
                  onChange={this.handleChangeVps}
                  options={this.state.optionVps}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Agent
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a agent"
                  isClearable={true}
                  value={this.state.agentSelected}
                  onChange={this.handleChangeAgent}
                  options={this.state.optionAgent}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Master
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a master"
                  isClearable={true}
                  value={this.state.masterSlaveSelected}
                  onChange={this.handleChangeMasterSlave}
                  options={this.state.optionMasterSlave}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  User
                </Typography>
              </Grid>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a user"
                  isClearable={true}
                  value={this.state.userSelected}
                  onChange={this.handleChangeUser}
                  options={this.state.optionUser}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Accounts
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Add Account
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                      {this.state.selectRowData.length > 0 ? (
                        <div style={{ marginRight: 0, marginLeft: 16 }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#ff0000",
                            }}
                            startIcon={<DeleteOutline />}
                            onClick={() =>
                              this.setState({ openAlertDeleteAll: true })
                            }
                          >
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Delete
                            </Typography>
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}

export default AccountPage;
