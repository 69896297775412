import React, { Component } from "react";
import {
  apiRateDelete,
  apiRateInsert,
  apiRateList,
  apiRateUpdate,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Refresh,
  Assignment,
  Delete,
  Close,
  Save,
  Cancel,
  AddBox,
  Check,
} from "@mui/icons-material";
// import { uuidRandomId } from "../../../global.js";
// import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class RatePage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      dataTelegramAccountShow: [],
      left: [],
      right: [],
      checked_transfer: [],
      available: [],
      showLoadingDelete: false,
      showLoadingDeleteAll: false,
      showLoadingSubmit: false,
      showLoading: false,
      dataDetail: "",
      submitLoading: false,
      rate_id: 0,
      rate_name: "",
      rate_nominal: "",
    };

    this.tableColumns = [
      {
        Header: "Rate",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "rate_name",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.rate_name}
          </Typography>
        ),
      },
      {
        Header: "Nominal",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "rate_nominal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            Rp ${e.original.rate_nominal.toLocaleString("id-ID")}
          </Typography>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 300,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Assignment />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  doLoadData = () => {
    this.props.doLoading();
    apiRateList()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const temp = data.records;

            this.setState({
              tableData: temp,
            });
          } else {
            this.setState({
              tableData: [],
            });
          }
        } else {
          this.setState({
            tableData: [],
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    if (this.state.setOpenAddNew === true) {
      let params = {
        rate_name: this.state.rate_name,
        rate_nominal: this.state.rate_nominal,
      };
      this.setState({
        submitLoading: true,
      });
      apiRateInsert(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          const data = res.data;
          if (data.status === "OK") {
            this.setState({
              setOpenAddNew: false,
              openSuccess: true,
              openSuccessText: "Data saved successfully",
            });
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    } else {
      let params = {
        rate_id: this.state.rate_id,
        rate_name: this.state.rate_name,
        rate_nominal: this.state.rate_nominal,
      };
      this.setState({
        submitLoading: true,
      });
      apiRateUpdate(params)
        .then((res) => {
          this.setState({
            submitLoading: false,
          });
          const data = res.data;
          if (data.status === "OK") {
            this.setState({
              setOpenEdit: false,
              openSuccess: true,
              openSuccessText: "Edit Data successfully",
            });
          }
        })
        .catch((err) => {
          this.setState({
            submitLoading: false,
          });
          console.log(err);
        });
    }
  };

  doDeleteItem = (rate_id) => {
    this.props.doLoading();
    this.setState({ showLoadingDelete: true });
    apiRateDelete(rate_id)
      .then((res) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data removed successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({ showLoadingDelete: false });
        this.props.doLoading();
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      rate_id: row.rate_id,
      rate_name: row.rate_name,
      rate_nominal: row.rate_nominal,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.doLoadData();
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      rate_id: 0,
      rate_name: "",
      rate_nominal: 0,
    });
  };

  checkData = () => {
    if (this.state.rate_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Rate?",
        titleBody: "Enter Rate",
      });
    } else if (this.state.rate_nominal === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Rate Nominal?",
        titleBody: "Enter rate nominal",
      });
    } else {
      this.doSubmit();
    }
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Rate"
                : this.state.setOpenEdit === true
                ? "Edit Rate"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Rate
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="rate_name"
                  id="rate_name"
                  value={this.state.rate_name}
                  onChange={(event) =>
                    this.setState({ rate_name: event.target.value })
                  }
                />
              </Grid>

              <Grid item lg={2} md={3} sm={3} xs={12}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Nominal
                </Typography>
              </Grid>
              <Grid item lg={10} md={9} sm={9} xs={12}>
                <Input
                  autoComplete="off"
                  type="number"
                  name="rate_nominal"
                  id="rate_nominal"
                  value={this.state.rate_nominal}
                  onChange={(event) =>
                    this.setState({ rate_nominal: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        // onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Rate?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to remove rate {item.rate_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDelete}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={this.state.showLoadingDelete === true ? null : <Check />}
            onClick={() => this.doDeleteItem(item.rate_id)}
          >
            {this.state.showLoadingDelete === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Rate
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="contentDate">
                      <div style={{ marginRight: 16 }}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#008b02",
                          }}
                          startIcon={<AddBox />}
                          onClick={() => this.pressAddNew()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Create Rate
                          </Typography>
                        </Button>
                      </div>
                      <div style={{ marginRight: 0 }}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#1273DE",
                          }}
                          startIcon={<Refresh />}
                          onClick={() => this.pressReset()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Refresh
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default RatePage;
