import React, { Component, createRef } from "react";
import {
  apiDailyWithdrawalDate,
  apiAccountOption,
  apiDailyWithdrawal,
  apiDailyWithdrawalAccount,
  apiDailyWithdrawalAccountType,
} from "../../Service/api";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Input } from "reactstrap";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  Storage,
  Close,
  Refresh,
  Cancel,
  Save,
  Info,
  ArrowBack,
} from "@mui/icons-material";
import moment from "moment";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { isNegative } from "../../../global.js";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let timer = null;

const defaultFilterMethod = (filter, row) => {
  const cellValue = String(row[filter.id]).toLowerCase();
  return cellValue.includes(filter.value.toLowerCase());
};

class DailyWithdrawal extends Component {
  constructor(props) {
    super(props);
    this.reactTableRef = createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      tableDataWithdrawalList: [],
      filter: "",
      startDate: moment().locale("id").format("YYYY-MM-DD"),
      endDate: moment().locale("id").format("YYYY-MM-DD"),
      selectGroupAccountNumberId: null,
      DataGroupAccountNumber: [],
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      account_number: "",
      alias: "",
      balance: 0,
      type_currency: "Cents",
      total_withdrawal: 0,
      total_wd: 0,
      arrDataDailyWithdrawal: [],
      setOpenFilter: false,
      setOpenDetailWithdrawal: false,
      tabIndex: 0,
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "time_timezone",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY HH:mm:ss")}
          </Typography>
        ),
      },

      {
        Header: "Withdrawal",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "withdrawal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.withdrawal) === true
                    ? "red"
                    : e.original.withdrawal === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.withdrawal) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.withdrawal)}`
                      : `${Number(
                          parseFloat(e.original.withdrawal).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.withdrawal === 0
                ? Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Info />}
              onClick={() => this.doRowView(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.tableDataDailyWithdrawal = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "time_timezone",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY HH:mm:ss")}
          </Typography>
        ),
      },
      {
        Header: "Account Number",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "account_number",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.account_number}
          </Typography>
        ),
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "alias",
        style: {
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.alias}
          </Typography>
        ),
      },
      {
        Header: "Withdrawal",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "withdrawal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.withdrawal) === true
                    ? "red"
                    : e.original.withdrawal === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.withdrawal) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.withdrawal)}`
                      : `${Number(
                          parseFloat(e.original.withdrawal).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.withdrawal === 0
                ? Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },

      {
        Header: "Action",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Info />}
              onClick={() => this.doRowView(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Detail
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
    this.tableDataWithdrawalList = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        width: 80,
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.id}
          </Typography>
        ),
      },
      {
        Header: "Order Ticket",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "withdrawal_id",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {e.original.withdrawal_id}
          </Typography>
        ),
      },
      {
        Header: "Date",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "last_update_at",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: ({ value }) => (
          <Typography
            component="span"
            variant="body2"
            style={{
              display: "inline-block",
            }}
          >
            {moment(value).locale("en").format("ddd, DD MMM YYYY HH:mm:ss")}
          </Typography>
        ),
      },

      {
        Header: "Withdrawal",
        headerStyle: { fontWeight: "bold", fontSize: 14 },
        accessor: "withdrawal",
        style: {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        Cell: (e) => (
          <>
            <Typography
              component="span"
              variant="body2"
              style={{
                color:
                  isNegative(e.original.withdrawal) === true
                    ? "red"
                    : e.original.withdrawal === 0
                    ? "#000"
                    : "green",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {isNegative(e.original.withdrawal) === true
                ? `${
                    e.original.type_currency === "Standart"
                      ? `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(e.original.withdrawal)}`
                      : `${Number(
                          parseFloat(e.original.withdrawal).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}${e.original.type_currency === "Cents" ? "¢" : ""}`
                  }  `
                : e.original.withdrawal === 0
                ? Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : `+${
                    e.original.type_currency === "Standart" ? "$" : ""
                  }${Number(
                    parseFloat(e.original.withdrawal).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}${e.original.type_currency === "Cents" ? "¢" : ""}`}
            </Typography>
          </>
        ),
      },
    ];
  }

  componentDidMount() {
    this.doLoadDailyBalance();
    this.doLoadAccountOption();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.someCondition !== this.props.someCondition) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      // Check if the ReactTable instance exists
      if (this.reactTableRef.current) {
        // Assuming you have a method like scrollTableTop, check your actual implementation
        if (this.reactTableRef.current.scrollTableTop) {
          this.reactTableRef.current.scrollTableTop();
        } else {
          console.error(
            "scrollTableTop method not found on ReactTable instance."
          );
        }
      }
    }, 0);
  };

  doLoadAccountOption = () => {
    apiAccountOption()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              DataGroupAccountNumber: data.records,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadDailyBalance = () => {
    this.props.doLoading();
    apiDailyWithdrawal()
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var dataDailyWithdrawal = [];
            dataDailyWithdrawal = data.records;

            for (var idx = 0; idx < dataDailyWithdrawal.length; idx++) {
              dataDailyWithdrawal[idx].id = idx + 1;
            }

            this.setState({
              arrDataDailyWithdrawal: dataDailyWithdrawal,
              tabIndex: 1,
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSeacrhBydate = () => {
    const { selectGroupAccountNumberId } = this.state;
    if (selectGroupAccountNumberId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Account Number?",
        titleBody: "Select account number",
      });
    } else if (this.state.startDate > this.state.endDate) {
      this.setState({
        openValidation: true,
        titleValidation: "Invalid Date?",
        titleBody: "End date is to small from start date",
      });
    } else {
      let params = {
        accountNumber: selectGroupAccountNumberId.value,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };

      this.handleCloseFilter();
      this.props.doLoading();
      // }
      apiDailyWithdrawalDate(params)
        .then((res) => {
          this.props.doLoading();
          // }
          let data = res.data;
          if (data.status === "OK") {
            if (data.records.length > 0) {
              var temp = [];
              temp = data.records;

              let sum_total_withdrawal = 0;

              for (var i = 0; i < temp.length; i++) {
                temp[i].id = i + 1;
                sum_total_withdrawal += temp[i].withdrawal;
              }

              this.setState({
                tableData: temp,
                account_number: this.state.selectGroupAccountNumberId.value,
                alias: this.state.selectGroupAccountNumberId.alias,
                type_currency:
                  this.state.selectGroupAccountNumberId.type_currency,
                balance: this.state.selectGroupAccountNumberId.balance,
                total_withdrawal: sum_total_withdrawal,
                // arrDataDailyWithdrawal: [],
                tabIndex: 2,
              });
            }
          }
        })
        .catch((err) => {
          this.props.doLoading();
          console.log(err);
        });
    }
  };

  doApiListWithdrawal = (item) => {
    this.props.doLoading();
    let params = {
      accountNumber: item.account_number,
      dateSelected: item.last_update_at,
    };
    apiDailyWithdrawalAccount(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = [];
            temp = data.records;

            let sum_total_withdrawal = 0;

            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              if (temp[i].type === "Withdrawal") {
                sum_total_withdrawal += temp[i].withdrawal;
              }
            }

            this.setState({
              tableDataWithdrawalList: temp,
              account_number: item.account_number,
              alias: item.alias,
              type_currency: item.type_currency,
              total_wd: sum_total_withdrawal,
              // tableData: [],
              // arrDataDailyWithdrawal: [],
              tabIndex: 3,
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doApiWithdrawalChangeType = (item) => {
    this.props.doLoading();
    let params = {
      withdrawal_id: item.withdrawal_id,
      type: item.type,
    };
    apiDailyWithdrawalAccountType(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableDataWithdrawalList;

          let sum_total_withdrawal = 0;

          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            if (temp[i].type === "Withdrawal") {
              sum_total_withdrawal += temp[i].withdrawal;
            }
          }

          this.setState({
            tableDataWithdrawalList: temp,
            total_withdrawal: sum_total_withdrawal,
            tabIndex: 3,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 3) {
      if (this.state.tableData.length > 0) {
        this.setState({
          tabIndex: tabIndex - 1,
        });
      } else {
        this.setState({
          tableData: [],
          arrDataDailyWithdrawal: [],
          tableDataWithdrawalList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
        this.doLoadDailyBalance();
      }
    } else if (tabIndex === 2) {
      if (this.state.tableData.length > 0) {
        this.setState({
          tabIndex: tabIndex - 1,
          tableData: [],
          tableDataWithdrawalList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          tableData: [],
          arrDataDailyWithdrawal: [],
          tableDataWithdrawalList: [],
          startDate: moment().locale("id").format("YYYY-MM-DD"),
          endDate: moment().locale("id").format("YYYY-MM-DD"),
        });
        this.doLoadDailyBalance();
      }
    } else if (tabIndex === 1) {
      this.setState({
        tableData: [],
        arrDataDailyWithdrawal: [],
        tableDataWithdrawalList: [],
        startDate: moment().locale("id").format("YYYY-MM-DD"),
        endDate: moment().locale("id").format("YYYY-MM-DD"),
      });
      this.doLoadDailyBalance();
    }
  };

  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  setStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };
  setEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeAccountNumber = (selectGroupAccountNumberId) => {
    this.setState({
      selectGroupAccountNumberId,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenFilter = () => {
    this.setState({
      setOpenFilter: true,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      setOpenFilter: false,
    });
  };

  renderDialogFilter = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenFilter}
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Daily Withdrawal Filter
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseFilter()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Account Number
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Select
                  classNamePrefix="select"
                  placeholder="Select a account"
                  value={this.state.selectGroupAccountNumberId}
                  onChange={this.handleChangeAccountNumber}
                  options={this.state.DataGroupAccountNumber}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement="top"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.startDate}
                  onChange={this.setStartDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="date"
                  id="exampleDate"
                  value={this.state.endDate}
                  onChange={this.setEndDate}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseFilter()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.doSeacrhBydate()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Filter
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderSummary = () => {
    if (this.state.tabIndex === 2) {
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    borderLeft: 6,
                    borderColor: "#2f55a2",
                    color: "#FFF",
                    maxHeight: 200,
                    padding: 16,
                    paddingBottom: 24,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    {this.state.startDate === this.state.endDate
                      ? moment(this.state.startDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY")
                      : moment(this.state.startDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY") +
                        " - " +
                        moment(this.state.endDate)
                          .locale("en")
                          .format("dddd, DD MMMM YYYY")}
                  </Typography>
                  <br></br>
                  <br></br>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="account_number"
                          id="account_number"
                          value={this.state.account_number}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Alias
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="alias"
                          id="alias"
                          value={this.state.alias}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Balance
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="balance"
                          id="balance"
                          value={`${
                            this.state.type_currency === "Standart" ? "$" : ""
                          }${Number(
                            parseFloat(this.state.balance).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}${
                            this.state.type_currency === "Cents" ? "¢" : ""
                          }`}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            // fontSize: 16,
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Total Withdrawal
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="total_withdrawal"
                          id="total_withdrawal"
                          value={
                            isNegative(this.state.total_withdrawal) === true
                              ? `${
                                  this.state.type_currency === "Standart"
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(this.state.total_withdrawal)}`
                                    : `${Number(
                                        parseFloat(
                                          this.state.total_withdrawal
                                        ).toFixed(2)
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })}${
                                        this.state.type_currency === "Cents"
                                          ? "¢"
                                          : ""
                                      }`
                                }  `
                              : this.state.total_withdrawal === 0
                              ? Number(
                                  parseFloat(
                                    this.state.total_withdrawal
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : `${
                                  this.state.type_currency === "Standart"
                                    ? "$"
                                    : ""
                                }${Number(
                                  parseFloat(
                                    this.state.total_withdrawal
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })}${
                                  this.state.type_currency === "Cents"
                                    ? "¢"
                                    : ""
                                }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <br></br>
        </>
      );
    } else if (this.state.tabIndex === 3) {
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    borderLeft: 6,
                    borderColor: "#2f55a2",
                    color: "#FFF",
                    maxHeight: 200,
                    padding: 16,
                    paddingBottom: 24,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    {moment(this.state.startDate)
                      .locale("en")
                      .format("dddd, DD MMMM YYYY")}
                  </Typography>
                  <br></br>
                  <br></br>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Account Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="account_number"
                          id="account_number"
                          value={`${this.state.account_number} ${this.state.alias}`}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            color: "#000",
                            float: "left",
                            marginTop: 6,
                            textTransform: "capitalize",
                          }}
                        >
                          Total Withdrawal
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="total_wd"
                          id="total_wd"
                          value={
                            isNegative(this.state.total_wd) === true
                              ? `${
                                  this.state.type_currency === "Standart"
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(this.state.total_wd)}`
                                    : `${Number(
                                        parseFloat(this.state.total_wd).toFixed(
                                          2
                                        )
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })}${
                                        this.state.type_currency === "Cents"
                                          ? "¢"
                                          : ""
                                      }`
                                }  `
                              : this.state.total_wd === 0
                              ? Number(
                                  parseFloat(this.state.total_wd).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : `${
                                  this.state.type_currency === "Standart"
                                    ? "$"
                                    : ""
                                }${Number(
                                  parseFloat(this.state.total_wd).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })}${
                                  this.state.type_currency === "Cents"
                                    ? "¢"
                                    : ""
                                }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <br></br>
        </>
      );
    }
  };

  renderTable = () => {
    if (this.state.tableData.length > 0 && this.state.tabIndex === 2) {
      return (
        <ReactTable
          ref={this.reactTableRef}
          data={this.state.tableData}
          columns={this.tableColumns}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else if (
      this.state.arrDataDailyWithdrawal.length > 0 &&
      this.state.tabIndex === 1
    ) {
      return (
        <ReactTable
          ref={this.reactTableRef}
          data={this.state.arrDataDailyWithdrawal}
          columns={this.tableDataDailyWithdrawal}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else if (
      this.state.tableDataWithdrawalList.length > 0 &&
      this.state.tabIndex === 3
    ) {
      return (
        <ReactTable
          ref={this.reactTableRef}
          data={this.state.tableDataWithdrawalList}
          columns={this.tableDataWithdrawalList}
          style={{ backgroundColor: "#f2f2f2" }}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          defaultPageSize={500}
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={false}
        />
      );
    } else {
      return (
        <Typography
          sx={{ width: "33%", flexShrink: 0 }}
          style={{ fontWeight: "bold" }}
        >
          No data available
        </Typography>
      );
    }
  };

  doRowView = (item) => {
    console.log(item);
    this.setState({
      setOpenDetailWithdrawal: true,
      startDate: item.last_update_at,
    });
    this.doApiListWithdrawal(item);
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      component="span"
                      variant="h1"
                      style={
                        (stylesListComent.inline,
                        {
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                        })
                      }
                    >
                      Daily Withdrawal
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    {this.state.tableDataWithdrawalList.length > 0 ? (
                      <div className="contentDate">
                        <div style={{ marginRight: 0 }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#1273DE",
                            }}
                            startIcon={<ArrowBack />}
                            onClick={() => this.pressReset()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Back
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="contentDate">
                        <div style={{ marginRight: 16 }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#006b76",
                            }}
                            startIcon={<Storage />}
                            onClick={() => this.handleOpenFilter()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Filter
                            </Typography>
                          </Button>
                        </div>
                        <div style={{ marginRight: 0 }}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#1273DE",
                            }}
                            startIcon={<Refresh />}
                            onClick={() => this.pressReset()}
                          >
                            <Typography
                              variant="button"
                              style={{
                                fontSize: 14,
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              Refresh
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        {this.renderSummary()}
        <div className="box-container">{this.renderTable()}</div>
        {this.renderValidationAlert()}
        {this.renderDialogFilter()}
      </Box>
    );
  }
}
export default DailyWithdrawal;
