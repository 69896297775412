import React, { Component } from "react";
import {
  apiManageApiTelegram,
  apiManageApiTelegramDelete,
  apiAccountInsertUpdate,
  apiManageDeleteAll,
  apiUpdateAccountActive,
} from "../../Service/api";
import { TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import SelectMultiColumn from "../../Components/SelectMultiColumn/SelectMultiColumn";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  Alert,
  Checkbox,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import { Input } from "reactstrap";
import ReactTable from "react-table";
import {
  Close,
  Refresh,
  Edit,
  Delete,
  DeleteOutline,
  Cancel,
  Check,
  Save,
  Upgrade,
} from "@mui/icons-material";
import moment from "moment";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const format1 = "YYYY-MM-DD HH:mm:ss";
// var date = new Date();
// var DateTimeNow = moment(date).format(format1);

class TelegramAccountPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      openSuccess: false,
      openSuccessText: "",
      openAlertDelete: false,
      openAlertDeleteAll: false,
      itemDeleted: {},
      manage_id: 0,
      alias: "",
      api_id: 0,
      api_hash: "",
      phonenumber: "",
      string_token: "",
      proxy_ip: "",
      proxy_port: "",
      status: 0,
      showLoadingRemove: false,
      showLoadingDeleteAll: false,
      setOpenEdit: false,
      statusShow: [
        { statusId: 0, statusName: "Non Active" },
        { statusId: 1, statusName: "Active" },
        { statusId: 2, statusName: "Banned Temporary" },
        { statusId: 3, statusName: "Banned Forever" },
        { statusId: 4, statusName: "Flood" },
        { statusId: 5, statusName: "Update Session" },
      ],
      as_default: 0,
      asDefaultShow: [
        { defaultId: 0, defaultName: "No" },
        { defaultId: 1, defaultName: "Yes" },
      ],
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      showLoadingSubmit: false,
      showLoadingSubmitUpdate: false,
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold" },
        accessor: "id",
        style: { textAlign: "center" },
        width: 100,
      },
      {
        Header: "Alias",
        headerStyle: { fontWeight: "bold" },
        accessor: "alias",
        style: { textAlign: "center" },
      },
      {
        Header: "Phone Number",
        headerStyle: { fontWeight: "bold" },
        accessor: "phonenumber",
        style: { textAlign: "center" },
      },
      {
        Header: "Proxy IP/Hostname",
        headerStyle: { fontWeight: "bold" },
        accessor: "proxy_ip",
        style: { textAlign: "center" },
      },
      {
        Header: "Proxy Port",
        headerStyle: { fontWeight: "bold" },
        accessor: "proxy_port",
        style: { textAlign: "center" },
      },
      {
        Header: "Status",
        headerStyle: { fontWeight: "bold" },
        accessor: "status_text",
        style: { textAlign: "center" },
        Cell: (e) =>
          e.original.status_text === "Non Active" ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "darkorange",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Non Active
              </Typography>
            </>
          ) : e.original.status_text === "Active" ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "blue",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Active
              </Typography>
            </>
          ) : e.original.status_text === "Banned Temporary" ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "red",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Banned Temporary
              </Typography>
            </>
          ) : e.original.status_text === "Update Session" ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "darkorange",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Update Session
              </Typography>
            </>
          ) : e.original.status_text === "Flood" ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "darkorange",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Flood
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "red",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Banned Forever
              </Typography>
            </>
          ),
      },
      {
        Header: "as Default",
        headerStyle: { fontWeight: "bold" },
        accessor: "as_default",
        style: { textAlign: "center" },
        Cell: (e) =>
          e.original.as_default === 0 ? (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "darkorange",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                No
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontSize: 15,
                  color: "blue",
                  display: "inline-block",
                  textAlign: "right",
                  fontWeight: "600",
                  marginTop: 5,
                }}
              >
                Yes
              </Typography>
            </>
          ),
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold" },
        accessor: "",
        style: { textAlign: "center" },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Edit />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Delete
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getManageApi();
  }

  getManageApi = () => {
    this.props.doLoading();
    apiManageApiTelegram(this.state.filter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var temp = this.state.tableData;
            temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].id = i + 1;
              temp[i].status_text =
                temp[i].status === 0
                  ? "Non Active"
                  : temp[i].status === 1
                  ? "Active"
                  : temp[i].status === 2
                  ? "Banned Temporary"
                  : temp[i].status === 3
                  ? "Banned Forever"
                  : temp[i].status === 4
                  ? "Flood"
                  : "Update Session";
            }

            // console.log(temp);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (temp.length > 0) {
              let dataFilterFlood = temp.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              // console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              // console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              // console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              this.setState({ tableData: dataUnFlood });
            }
          } else {
            this.setState({
              tableData: [],
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      manage_id: this.state.manage_id,
      alias: this.state.alias,
      api_id: this.state.api_id,
      api_hash: this.state.api_hash,
      phonenumber: this.state.phonenumber,
      string_token: this.state.string_token,
      status: this.state.status,
      as_default: this.state.as_default,
      proxy_ip: this.state.proxy_ip,
      proxy_port: this.state.proxy_port,
    };
    this.props.doLoading();
    this.setState({
      showLoadingSubmitUpdate: true,
    });
    apiAccountInsertUpdate(params)
      .then((res) => {
        this.setState({
          showLoadingSubmitUpdate: false,
        });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseDetail();
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmitUpdate: false,
        });
        console.log(err);
        this.props.doLoading();
      });
  };

  doDeleteAccount = (manage_id) => {
    this.setState({
      showLoadingRemove: true,
    });
    apiManageApiTelegramDelete(manage_id)
      .then((res) => {
        this.setState({
          showLoadingRemove: false,
        });
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Account data deleted successfully.",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingRemove: false,
        });
        console.log(err);
      });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
    });
  };

  pressDeleteAll = () => {
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    this.setState({
      showLoadingDeleteAll: true,
    });
    apiManageDeleteAll(params)
      .then((res) => {
        this.setState({
          showLoadingDeleteAll: false,
        });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
            selectRowData: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingDeleteAll: false,
        });
        this.props.doLoading();
        console.log(err);
      });
  };

  codeCallback() {
    return new Promise((resolve, reject) => {
      let code = prompt("Please enter the code you received:", "");
      if (code !== null) {
        resolve(code);
      } else {
        reject(Error("Rejected"));
      }
    });
  }

  async GetAuthentication() {
    // console.log("Loading Authentication");
    const session = new StringSession("");
    const client = new TelegramClient(
      session,
      parseInt(this.state.api_id),
      this.state.api_hash,
      {
        connectionRetries: 5,
      }
    );

    this.setState({
      showLoadingSubmit: true,
    });

    await client
      .start({
        phoneNumber: async () => await this.state.phonenumber,
        phoneCode: async () => await this.codeCallback(),
      })
      .then(() => {
        var string_session = client.session.save();
        console.log(client.session.save());
        this.setState({
          StringSession: client.session.save(),
        });
        console.log(
          "%c your string session is " + client.session.save(),
          "color:#B54128"
        );
        if (
          string_session !== null ||
          string_session !== "" ||
          string_session !== undefined
        ) {
          this.setState({
            showLoadingSubmit: false,
          });
          this.doUpgradeAccount(string_session);
        } else {
          this.setState({
            showLoadingSubmit: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          showLoadingSubmit: false,
        });
        console.log(e.toString());
        this.setState({
          openValidation: true,
          titleValidation: "Error Authentication?",
          titleBody: e.toString(),
        });
      });
  }

  doUpgradeAccount = (string_session) => {
    let params = {
      manage_id: this.state.manage_id,
      alias: this.state.alias,
      api_id: this.state.api_id,
      api_hash: this.state.api_hash,
      phonenumber: this.state.phonenumber,
      status: this.state.status,
      as_default: this.state.as_default,
      string_token: string_session,
    };
    this.props.doLoading();
    apiAccountInsertUpdate(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText:
              this.state.setOpenEdit === true
                ? "Edit data successfully updated"
                : "Data saved successfully",
          });
          this.handleCloseDetail();
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Remove Account Telegram?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to remove the account telegram {item.phonenumber}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingRemove}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingRemove}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={
              this.state.showLoadingRemove === true ? <></> : <Check />
            }
            onClick={() => this.doDeleteAccount(item.manage_id)}
          >
            {this.state.showLoadingRemove === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Account Telegram?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete telegram account?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingDeleteAll}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={
              this.state.showLoadingDeleteAll === true ? <></> : <Check />
            }
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingDeleteAll === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      manage_id: row.manage_id,
      alias: row.alias,
      api_id: row.api_id,
      api_hash: row.api_hash,
      phonenumber: row.phonenumber,
      string_token: row.string_token,
      status: row.status,
      proxy_port: row.proxy_port,
      proxy_ip: row.proxy_ip,
    });
  };

  handleCloseDetail = () => {
    this.setState({
      setOpenEdit: false,
      manage_id: 0,
      alias: "",
      api_id: 0,
      api_hash: "",
      phonenumber: "",
      string_token: "",
      status: 0,
      as_default: 0,
      proxy_ip: "",
      proxy_port: "",
    });
  };

  checkData = () => {
    const { api_id, api_hash, phonenumber, alias } = this.state;

    if (api_id === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty API ID ?",
        titleBody: "Please enter API ID",
      });
    } else if (api_hash === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Api Hash?",
        titleBody: "Please enter Api Hash",
      });
    } else if (phonenumber === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Phone number?",
        titleBody: "Please enter phone number",
      });
    } else if (alias === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Alias?",
        titleBody: "Please enter alias",
      });
    } else {
      this.doSubmit();
    }
  };

  changeSelectStatus = (status) => {
    this.setState({ status: status });
  };

  changeSelectDefault = (as_default) => {
    this.setState({ as_default: as_default });
  };

  renderDialogDetail = () => {
    return (
      <Dialog
        onClose={this.handleCloseDetail}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth={true}
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Edit Account Telegram
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseDetail()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <div className="telegram-manage-api-desktop">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Alias
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="alias"
                    id="alias"
                    value={this.state.alias}
                    onChange={(event) =>
                      this.setState({ alias: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="phonenumber"
                    id="phonenumber"
                    value={this.state.phonenumber}
                    onChange={(event) =>
                      this.setState({ phonenumber: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Api ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="api_id"
                    id="api_id"
                    value={this.state.api_id}
                    onChange={(event) =>
                      this.setState({ api_id: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Api Hash
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_hash"
                    id="api_hash"
                    value={this.state.api_hash}
                    onChange={(event) =>
                      this.setState({ api_hash: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    String Session
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="textarea"
                    rows="5"
                    name="string_token"
                    id="string_token"
                    value={this.state.string_token}
                    onChange={(event) =>
                      this.setState({ string_token: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <SelectMultiColumn
                    width={"100%"}
                    value={this.state.status}
                    valueColumn={"statusId"}
                    showColumn={"statusName"}
                    columns={["statusName"]}
                    data={this.state.statusShow}
                    onChange={this.changeSelectStatus}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <div className="page-header">
                    Setting Proxy
                    <span className="dash">&nbsp;&nbsp;</span>{" "}
                    <span className="parent-title"></span>
                  </div>
                </Grid> */}

                {/* <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    IP/Hostname
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="proxy_ip"
                    id="proxy_ip"
                    value={this.state.proxy_ip}
                    onChange={(event) =>
                      this.setState({ proxy_ip: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Port
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="proxy_port"
                    id="proxy_port"
                    value={this.state.proxy_port}
                    onChange={(event) =>
                      this.setState({ proxy_port: event.target.value })
                    }
                  />
                </Grid> */}

                {/* <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    as default
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <SelectMultiColumn
                    width={"100%"}
                    value={this.state.as_default}
                    valueColumn={"defaultId"}
                    showColumn={"defaultName"}
                    columns={["defaultName"]}
                    data={this.state.asDefaultShow}
                    onChange={this.changeSelectDefault}
                  />
                </Grid> */}
              </Grid>
            </div>
            <div className="telegram-manage-api-mobile">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Alias
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="alias"
                    id="alias"
                    value={this.state.alias}
                    onChange={(event) =>
                      this.setState({ alias: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="phonenumber"
                    id="phonenumber"
                    value={this.state.phonenumber}
                    onChange={(event) =>
                      this.setState({ phonenumber: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Api ID
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="api_id"
                    id="api_id"
                    value={this.state.api_id}
                    onChange={(event) =>
                      this.setState({ api_id: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Api Hash
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="api_hash"
                    id="api_hash"
                    value={this.state.api_hash}
                    onChange={(event) =>
                      this.setState({ api_hash: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    String Session
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="off"
                    type="textarea"
                    rows="5"
                    name="string_token"
                    id="string_token"
                    value={this.state.string_token}
                    onChange={(event) =>
                      this.setState({ string_token: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectMultiColumn
                    width={"100%"}
                    value={this.state.status}
                    valueColumn={"statusId"}
                    showColumn={"statusName"}
                    columns={["statusName"]}
                    data={this.state.statusShow}
                    onChange={this.changeSelectStatus}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#4caf50",
            }}
            startIcon={
              this.state.showLoadingSubmit === true ? null : <Upgrade />
            }
            onClick={() => this.GetAuthentication()}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <>
                <div className="telegram-manage-api-desktop">
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Upgrade String Session
                  </Typography>
                </div>
                <div className="telegram-manage-api-mobile">
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: 11,
                    }}
                  >
                    Upgrade Session
                  </Typography>
                </div>
              </>
            )}
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.showLoadingSubmitUpdate}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={
              this.state.showLoadingSubmitUpdate === true ? null : <Save />
            }
            onClick={() => this.checkData()}
          >
            {this.state.showLoadingSubmitUpdate === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <>
                <div className="telegram-manage-api-desktop">
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Save Changes
                  </Typography>
                </div>
                <div className="telegram-manage-api-mobile">
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: 11,
                    }}
                  >
                    Save Changes
                  </Typography>
                </div>
              </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      checked: [],
    });
    this.getManageApi();
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Account Telegram
                </Typography>
                <br></br>
                <div className="contentDate">
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>
                  {this.state.selectRowData.length > 0 ? (
                    <div style={{ marginRight: 0, marginLeft: 16 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff0000",
                        }}
                        startIcon={<DeleteOutline />}
                        onClick={() =>
                          this.setState({ openAlertDeleteAll: true })
                        }
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Delete {this.state.selectRowData.length}
                          {this.state.selectRowData.length === 1
                            ? "item"
                            : "items"}
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        {this.renderSuccess()}
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    onChange={(e) => this.toggleSelectAll(e)}
                    checked={this.state.selectAll}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                Cell: (row) => (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={(e) => this.handleCheck(e, row.index)}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                headerStyle: { fontWeight: "bold" },
                sortable: false,
                filterable: true,
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "No",
                headerStyle: { fontWeight: "bold" },
                accessor: "id",
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "Alias",
                headerStyle: { fontWeight: "bold" },
                accessor: "alias",
                style: { textAlign: "center" },
              },
              {
                Header: "Phone Number",
                headerStyle: { fontWeight: "bold" },
                accessor: "phonenumber",
                style: { textAlign: "center" },
              },
              {
                Header: "API ID",
                headerStyle: { fontWeight: "bold" },
                accessor: "api_id",
                style: { textAlign: "center" },
              },
              {
                Header: "API HASH",
                headerStyle: { fontWeight: "bold" },
                accessor: "api_hash",
                style: { textAlign: "center" },
              },
              {
                Header: "Status",
                headerStyle: { fontWeight: "bold" },
                accessor: "status_text",
                style: { textAlign: "center" },
                Cell: (e) =>
                  e.original.status_text === "Non Active" ? (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "darkorange",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Non Active
                      </Typography>
                    </>
                  ) : e.original.status_text === "Active" ? (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "blue",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Active
                      </Typography>
                    </>
                  ) : e.original.status_text === "Banned Temporary" ? (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "red",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Banned Temporary
                      </Typography>
                    </>
                  ) : e.original.status_text === "Update Session" ? (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "darkorange",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Update Session
                      </Typography>
                    </>
                  ) : e.original.status_text === "Flood" ? (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "darkorange",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Flood {moment(e.original.flood).format("LTS")}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{
                          fontSize: 15,
                          color: "red",
                          display: "inline-block",
                          textAlign: "right",
                          fontWeight: "600",
                          marginTop: 5,
                        }}
                      >
                        Banned Forever
                      </Typography>
                    </>
                  ),
              },
              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: { textAlign: "center" },
                width: 200,
                Cell: (e) => (
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#3f51b5",
                      }}
                      startIcon={<Edit />}
                      onClick={() => this.doRowEdit(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Edit
                      </Typography>
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#ff0000",
                      }}
                      startIcon={<Delete />}
                      onClick={() => this.doRowDeleteInfo(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Remove
                      </Typography>
                    </Button>
                  </div>
                ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={500}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
          />
        </div>
        {this.renderValidationAlert()}

        {this.renderRemoveItemInfo()}
        {this.renderRemoveItemAll()}
        {this.renderDialogDetail()}
      </Box>
    );
  }
}
export default TelegramAccountPage;
