import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Input } from "reactstrap";
import moment from "moment";

const parseAndFormatDate = (dateString) => {
  const date = new Date(dateString);
  return moment(date).locale("en").format("MMMM YYYY");
};

const InvoicePDF = ({ data }) => {
  const getMonthText = (numericMonth) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[numericMonth - 1] || "";
  };

  const generatePDF = () => {
    const pdf = new jsPDF();

    const fontSize = 10;
    // const lineSpacing = 5;

    // const currentDate = new Date();
    // const formattedDate = `${currentDate.toLocaleDateString(
    //   "en-GB"
    // )} ${currentDate.toLocaleTimeString("en-US", { hour12: true })}`;

    const created_invoice = moment(data.invoice_created)
      .locale("en")
      .format("DD MMMM YYYY HH:mm:ss");

    pdf.setFontSize(fontSize);

    pdf.text(`Invoice Created: ${created_invoice}`, 16, 20);
    pdf.text(`Invoice Number: ${data.invoice_number}`, 16, 30);
    if (data.custom === 1) {
      pdf.text(
        `Period: ${moment(data.from_date).locale("en").format("MMMM YYYY")} `,
        16,
        40
      );
    } else {
      pdf.text(`Period: ${getMonthText(data.periode)} ${data.year} `, 16, 40);
    }
    pdf.text(
      `Date: ${moment(data.from_date)
        .locale("en")
        .format("DD MMMM YYYY")} - ${moment(data.to_date)
        .locale("en")
        .format("DD MMMM YYYY")}`,
      16,
      50
    );
    pdf.text(`Agent: ${data.agent_name}`, 16, 60);

    pdf.text("", 20, 70);

    const itemHeaders = [
      "No",
      "Account Number",
      "Total Profit ($)",
      "Rate (Rp)",
      "Total Profit (Rp)",
    ];
    const itemRows = data.invoice_items.map((item, index) => [
      index + 1,
      `${item.account_number} (${item.alias})`,

      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(item.final_profit_usd),
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(item.nominal_rate),
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(item.final_profit_usd_x_rate_rp),
    ]);

    pdf.autoTable({
      head: [itemHeaders],
      body: itemRows,
      startY: 70,
      styles: {
        fontSize: 8,
      },
    });

    const spaceBetweenTables = 10;
    const startYForSummary = pdf.autoTable.previous.finalY + spaceBetweenTables;

    const summaryHeaders = ["Summary", ""];
    const summaryRows = [
      [
        "Sub Total Profit($)",

        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.sub_total_all_profit),
      ],
      [
        "Sub Total Profit(Rp)",

        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(data.sub_total_all_profit_rp),
      ],
      [
        `Fee (${data.shared_profit}%)`,
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(data.fee_final_rp),
      ],
    ];

    const summaryCustomRows = [
      [
        "Sub Total Profit($)",

        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.sub_total_all_profit),
      ],
      [
        "Sub Total Profit(Rp)",

        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(data.sub_total_all_profit_rp),
      ],
    ];

    pdf.autoTable({
      head: [summaryHeaders],
      body: data.custom === 1 ? summaryCustomRows : summaryRows,
      startY: startYForSummary,
      styles: {
        fontSize: 8,
      },
    });

    if (data.custom === 0) {
      const paymentMessage =
        "Please transfer to BCA account under the name of Darwin: 5310375791";
      pdf.text(
        paymentMessage,
        20,
        pdf.autoTable.previous.finalY + spaceBetweenTables + 10
      );
    }

    pdf.text("", 20, pdf.autoTable.previous.finalY + spaceBetweenTables + 60);

    const filename = `${data.invoice_number}_${data.agent_name}`.replace(
      /\s+/g,
      "_"
    );

    pdf.save(`${filename}.pdf`);
  };

  return (
    <div>
      <Button variant="contained" onClick={generatePDF}>
        Generate PDF
      </Button>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                float: "left",
                marginTop: 6,
                textTransform: "capitalize",
              }}
            >
              Invoice Number
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Input
              autoComplete="off"
              type="text"
              name="invoice_number"
              id="invoice_number"
              value={data.invoice_number}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                float: "left",
                marginTop: 6,
                textTransform: "capitalize",
              }}
            >
              Created Invoice
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Input
              autoComplete="off"
              type="text"
              name="invoice_created"
              id="invoice_created"
              value={new Date(data.invoice_created).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            />
          </Grid>

          {data.custom === 0 ? (
            <>
              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Period
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_created"
                  id="invoice_created"
                  value={parseAndFormatDate(data.to_date)}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={3}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Period
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="invoice_created"
                  id="invoice_created"
                  value={parseAndFormatDate(data.from_date)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={3}>
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                float: "left",
                marginTop: 6,
                textTransform: "capitalize",
              }}
            >
              Date
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Input
              autoComplete="off"
              type="text"
              name="invoice_created"
              id="invoice_created"
              value={`${moment(data.from_date)
                .locale("en")
                .format("DD MMMM YYYY")} - ${moment(data.to_date)
                .locale("en")
                .format("DD MMMM YYYY")}`}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                float: "left",
                marginTop: 6,
                textTransform: "capitalize",
              }}
            >
              Agent
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Input
              autoComplete="off"
              type="text"
              name="invoice_number"
              id="invoice_number"
              value={data.agent_name}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Summary
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Sub Total Profit ($)</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(data.sub_total_all_profit)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sub Total Profit (Rp)</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(data.sub_total_all_profit_rp)}
                    </TableCell>
                  </TableRow>
                  {data.custom === 0 ? (
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold", color: "red" }}>
                        Fee ({data.shared_profit}%)
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", color: "red" }}>
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(data.fee_final_rp)}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Invoice Items
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Account Number</TableCell>
                    <TableCell>Total Profit ($)</TableCell>
                    <TableCell>Rate (Rp)</TableCell>
                    <TableCell>Total Profit (Rp)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.invoice_items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{`${item.account_number} (${item.alias})`}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(item.final_profit_usd)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(item.nominal_rate)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(item.final_profit_usd_x_rate_rp)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default InvoicePDF;
